import "../node_modules/bootstrap/scss/bootstrap.scss";
import { React, useEffect, useState } from 'react';
import logo from './logo.svg';
import { Counter } from './features/counter/Counter';
import { Login } from './app/login/Login';
import { Home } from './app/home/Home';
import api from "./app/utils/api";
import './App.css';
import { useSelector, useDispatch } from 'react-redux';
import {
  getDataLoaded,
  isLoggedIn,
  login,
  logout,
  setLocalData,
  setNewCountryList,
  setTimeSlots,
  setNewSurfaceList,
  setNewPitchList,
} from './app/slices/auth.slice';
import * as Headless from "./App.headless";

function App() {
  const loggedIn = useSelector(isLoggedIn);
  const dataLoaded = useSelector(getDataLoaded);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const init = async () => {
    if (dataLoaded) {
      setLoading(false);
    } else {
      try {
        let resp = await Headless.loadData();
        // console.log("INIT DATA IS",resp)
        dispatch(setLocalData({
          countries: resp.data.countries,
          positions: resp.data.positions,
          levels: resp.data.levels,
          targets:resp.data.targets,
          intensity:resp.data.intensityLevel

          // clubs: resp.data.clubs,
          // pitchtypes: resp.data.pitchtypes.types,
        }))
        setLoading(false);
      } catch (err) {
        alert(err);
      }
    }
  }

  // const loadTimeSlots = async () => {
  //   try {
  //     let resp = await Headless.loadTimeSlots();
  //     dispatch(setTimeSlots(resp.data))
  //   } catch (err) {
  //     console.log('time slots loading err', err);
  //   }
  // }
  const loadCountries = async () => {
    try {
      let resp = await Headless.loadCountries();
      dispatch(setNewCountryList(resp.data.countries))
    } catch (err) {
      console.log('country list loading err', err);
    }
  }
  // const loadNewSurfaceList = async () => {
  //   try {
  //     let resp = await Headless.getSurfaceList(1);
  //     dispatch(setNewSurfaceList(resp.data.data))
  //   } catch (err) {
  //     console.log('surface list loading err', err);
  //   }
  // }
  // const loadNewPitchList = async () => {
  //   try {
  //     let resp = await Headless.getNewPitchList(1);
  //     if(resp.data && resp.data.data) {
  //       dispatch(setNewPitchList(resp.data.data))
  //     }
  //   } catch (err) {
  //     console.log('pitch list loading', err);
  //   }
  // }

  useEffect(() => {
    if(loggedIn) {
      // loadTimeSlots();
      loadCountries();
      // loadNewSurfaceList();
      // loadNewPitchList();
    }
  },[loggedIn])

  useEffect(() => {
    async function checkLogin() {
      let resp = await Headless.checkLogin();
      if (resp.loggedIn === true) {
        dispatch(login({
          isValidAuth: true,
          menus: resp.menus,
          user: resp.user,
        }));
      } else {
        dispatch(logout())
      }
    }

    init();
    if (loggedIn === false)
      checkLogin();
  }, []);

  const renderLogin = () => {
    if (loggedIn == false) {
      return <Login loading={loading} />
    } else {
      return <Home />
    }
  }
  return (
    <>
      {/* // <div className="App"> */}
      {/* <header className="App-header"> 
  //  <img src={logo} className="App-logo" alt="logo" /> */}
      {renderLogin()}
      {/* // </header> */}
      {/* // </div> */}
    </>
  );
}

export default App;
