import {send, setHeader} from "../utils/api";

export async function getFeedbacks(limit, page, search, filter) {
    const token = localStorage.getItem('token');
    setHeader('Authorization', `Bearer ${token}`);
    let url = `feedbacks/list?size=${limit}&page=${page}`;
    if (search) {
        url += `&search=${search}`;
    }
    // if (sort) {
    //     url += `&sort=${sort}`;
    // }
    let data= await send(url,"post",filter);
    return data;
}

export async function getFeedback(id) {
    const token = localStorage.getItem('token');
    setHeader('Authorization', `Bearer ${token}`);
    let url = `feedbacks/${id}`;
    let data= await send(url,"get");
    return data;
}