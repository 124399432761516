import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Loader from "react-loader-spinner";
import './Login.scss';
import {
    login
} from '../slices/auth.slice';
import * as Headless from "./Login.headless";
import { Col } from 'react-bootstrap';
import background from '../../assets/img/auth_bg.png';
import logo from '../../assets/img/logo.png';
import { faL } from '@fortawesome/free-solid-svg-icons';
import AlertDisplay from '../components/alertDisplay';


export function Login({ loading }) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [rePassword, setRePassword] = useState('');
    const [submit, setSubmit] = useState(false);
    const [resetPass, setResetPass] = useState(false);
    const [forgotPass, setForgotPass] = useState(false);
    const [tempOwner, setTemmpOwner] = useState(null);
    const [email, setEmail] = useState('')
    const [alertMessage,setAlertMessage]=useState(null)
    const [show, setShow] = useState(true);
    const dispatch = useDispatch();

    const showAlert=(message,type)=>{
        setAlertMessage({
            message,type
        })
        setTimeout(() => {
            setAlertMessage(null)
        }, 5000);
        setShow(true)
    }
    useEffect(() => {
      console.log("temp owner is",tempOwner)
    }, [tempOwner])
    
    const onClick = async () => {
        try {
            setSubmit(true)
            let loginResp = await Headless.login(username, password);
            console.log("Login resp ",loginResp)
            if (loginResp.user.firstLogin) {
                setResetPass(true)
                setTemmpOwner(loginResp.user)
                setUsername('')
                setPassword('')
                setSubmit(false)
                
                showAlert("Enter new password for your account","success")
            } else {
                if (loginResp.loggedIn) {
                    setSubmit(false)
                    dispatch(login({ isValidAuth: true, menus: loginResp.menus, user: loginResp.user }))
                } else {
                    setSubmit(false)
                    // alert("Login failed! Please check username and password and try again");
                    showAlert("Login failed! Please check username and password and try again","danger")
                }
            }

        } catch (err) {
            setSubmit(false)
            if (err.response) {
                if (err.response.status == '423') {
                    alert("Your access is disabled. Please contact FromFit admin to enable your account.");
                    showAlert("Your access is disabled. Please contact the FromFit admin to enable your account.","danger")
                } else if (err.response.status == '403') {
                    // alert("Login failed! Please check username and password and try again");
                    showAlert("Login failed! Please check username and password and try again","danger")
                } else {
                    // alert("Login failed! Please try again!");
                    showAlert("Login failed! Please try again!","danger")
                }
            } else {
                // alert("Login failed! Please try again!");
                showAlert("Login failed! Please try again!","danger")
            }
        }
    };

    const onForgotPass = async () => {
        try {
            setSubmit(true)
            await Headless.forgotPassword(email);
            setForgotPass(false);
            setSubmit(false)
            // alert("Please check your mail and create new password"); 
            showAlert("Please check your mail for new password","success")
        } catch (err) {
            setSubmit(false)
            showAlert("This Email Does not exits","warning")
        }
    };

    const onClickReset = async () => {
        var regularExpression = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,26}$/;
        if (regularExpression.test(newPassword)) {
            try {
                if (newPassword == rePassword) {
                    setSubmit(true)
                    console.log("11")
                   
                    await Headless.resetPassword(tempOwner.userId, newPassword, tempOwner.token);
                    console.log("11")
                    setResetPass(false)
                    setSubmit(false)
                    showAlert("Password changed successfully... please login","success")
                } else {
                    // alert("Password Missmatch");
                    showAlert("Password Mismatch","danger")
                }
            } catch (err) {
                setSubmit(false)
                // alert("Password reset failed!");
                showAlert("Password reset failed","danger")
            } 
        } else {
            // alert("Password must have atleast one letter, digit and special character");
            showAlert("Password must have atleast one letter, digit and special character","waarning")
        }

    };
    const forgotPasswordTrue=()=>{
        setForgotPass(true)   
        // showAlert("forgot password section true","success")
    }
    const forgotPasswordFalse=()=>{
        // showAlert("welcome to 11Now page","success") 
        setForgotPass(false)
    }
   
    return (
        <div className="container-fluid page-body-wrapper full-page-wrapper">
            <div className="content-wrapper d-flex align-items-stretch auth auth-img-bg">
                <div className="row flex-grow" style={{ margin: 0 }}>
                    <div className="col-lg-8 col-md-12 login-bg d-flex flex-row d-xl-block d-lg-block">
                        <img alt="" src={logo} height={30} width={100} className="img-responsive logo" />
                    </div>
                    <div className="col-lg-4 d-flex align-items-center justify-content-center">
                        <div className="auth-form-transparent text-left p-3">
                            {!loading ?
                                <>
                                {resetPass ?
                                <AlertDisplay alert={alertMessage} show={show} setShow={setShow}/>
                                : forgotPass ?
                                 <AlertDisplay alert={alertMessage} show={show} setShow={setShow}/>
                                : <AlertDisplay alert={alertMessage} show={show} setShow={setShow}/>}
                                    <h4 className="h3 mb-3 fw-normal">Welcome to FROMFIT 👋🏻</h4>
                                    <h6 className="font-weight-light">
                                        {resetPass ? "Please Create new Password" : forgotPass ? "Please Reset your Password"
                                            : "Please sign-in to your account for accessing dashboard"}
                                    </h6>
                                    {resetPass ?
                                        <form key="new" className="pt-3">
                                            <div className="form-group">
                                                <label htmlFor="newpass" className="visually-hidden mt-4">New Password</label>
                                                <input type="password" id="newpass" className="form-control" placeholder="New Password" required="" qutofocus="" onChange={(e) => setNewPassword(e.target.value)} />
                                            </div>
                                            <div className="form-group">
                                                <div className="d-flex justify-content-between mt-4">
                                                    <label htmlFor="repassword" className="visually-hidden">Re-Enter Password</label>
                                                </div>
                                                <input style={{ marginBottom: '10px' }} type="password" id="repassword" className="form-control" placeholder="Re-Enter Password" required="" onChange={(e) => setRePassword(e.target.value)} />
                                                <a style={{ color: '#0d6efd' }} onClick={() => setResetPass(false)}>&larr; Back to Login</a>
                                            </div>
                                            <div className="my-3">
                                                <button className="w-100 btn btn-lg btn-primary" type="button" onClick={onClickReset}>
                                                    {!submit ? 'Submit' :
                                                        <Loader
                                                            type="TailSpin"
                                                            color="#fff"
                                                            height={14}
                                                            width={14}
                                                        />}
                                                </button>
                                            </div>
                                        </form>
                                        : forgotPass ? (
                                            <form key={'forgotpass'} className="pt-3">
                                                <div className="form-group">
                                                    <label htmlFor="inputemail" className="visually-hidden mt-4">Email</label>
                                                    <input style={{ marginBottom: '10px' }} type="email" id="inputemail" className="form-control" placeholder="Email" required="" qutofocus="" onChange={(e) => setEmail(e.target.value)} />
                                                    <a style={{ color: '#0d6efd' }} onClick={forgotPasswordFalse}>&larr; Back to Login</a>
                                                </div>
                                                <div className="my-3">
                                                    <button className="w-100 btn btn-lg btn-primary" type="button" onClick={onForgotPass}>
                                                        {!submit ? 'Submit' :
                                                            <Loader
                                                                type="TailSpin"
                                                                color="#fff"
                                                                height={14}
                                                                width={14}
                                                            />}
                                                    </button>
                                                </div>
                                            </form>
                                        ) :
                                            <form className="pt-3">
                                                <div className="form-group">
                                                    <label htmlFor="inputemail" className="visually-hidden mt-4">Username</label>
                                                    <input type="email" id="inputemail" className="form-control" placeholder="Username" required="" qutofocus="" onChange={(e) => setUsername(e.target.value)} />
                                                </div>
                                                <div className="form-group">
                                                    <div className="d-flex justify-content-between mt-4">
                                                        <label htmlFor="inputpassword" className="visually-hidden">Password</label>
                                                    </div>
                                                    <input style={{ marginBottom: '10px' }} type="password" id="Inputpassword" className="form-control" placeholder="Password" required="" onChange={(e) => setPassword(e.target.value)} />
                                                    <a style={{ color: '#0d6efd' }} className='forgotPass' onClick={forgotPasswordTrue}>Forgot Password?</a>
                                                </div>
                                                <div className="my-3">
                                                    <button className="w-100 btn btn-lg btn-primary" type="button" onClick={onClick}>
                                                        {!submit ? 'Login' :
                                                            <div class="d-flex justify-content-center">
                                                                <Loader
                                                                    type="TailSpin"
                                                                    color="#fff"
                                                                    height={14}
                                                                    width={14}
                                                                    className='login-loader'
                                                                />
                                                            </div>
                                                        }
                                                    </button>
                                                </div>
                                            </form>
                                    }
                                </> :
                                <div className="text-center">
                                    <div className='d-flex justify-content-center'>
                                        <Loader
                                            type="TailSpin"
                                            color="#000"
                                            height={30}
                                            width={30}
                                        />
                                    </div>
                                    <p className="text-info mt-3">Initializing...</p>
                                </div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
