import {send, setHeader} from "../utils/api";

export async function getTypes(){
    let data= await send("api/config/settings","get","");
    return data;
}

export async function getUsers(limit, page, search, filter) {
    const token = localStorage.getItem('token');
    setHeader('Authorization', `Bearer ${token}`);
    let url = `users/list?size=${limit}&page=${page}`;
    if (search) {
        url += `&search=${search}`;
    }
    // if (sort) {
    //     url += `&sort=${sort}`;
    // }
    let data= await send(url,"post", filter);
    return data;
}

export async function getUser(id) {
    const token = localStorage.getItem('token');
    setHeader('Authorization', `Bearer ${token}`);
    let url = `users/${id}`;
    let data= await send(url,"get");
    return data;
}
export async function getAllJoinee(body) {
   
    const token = localStorage.getItem('token');
  
    setHeader('Authorization', `Bearer ${token}`);
    let url = `getReferredUsers`;
    let data= await send(url,"post",body);
  
    return data;
}

export async function getGameTracker(id, limit = 30) {
    const token = localStorage.getItem('token');
    setHeader('Authorization', `Bearer ${token}`);
    let url = `users/game-tracker/${id}?limit=${limit}`;
    let data= await send(url,"get");
    return data;
}

// module.exports={getTypes}
//export default getTypes;
