import React, {useState} from 'react';
import * as Yup from 'yup';
import { Formik, Field, Form } from 'formik';
import CountryDropdown from '../../components/countryDropdown';
import RadioButton from '../../components/radioButton';
import CustomInput from '../../components/customInput';

const taxSchema = Yup.object().shape({
  country: Yup.string().trim().required('Country required'),
  name: Yup.string().trim().required('Tax name required'),
  type: Yup.string().trim().required('Please choose tax type'),
  value: Yup.string().trim().required('Please enter tax value'),
});

export default function CreateTax({ createTax, data, fromEdit, selectedCountry,onCancel }) {
  const [country, setCountry] = useState({name: selectedCountry})

  const onSubmit = (values) => {
    if (fromEdit) {
      let d = {};
      if (values.country !== data.country) {
        d.country = values.country
      }
      if (values.name !== data.name) {
        d.name = values.name
      }
      if (values.type !== data.type) {
        d.type = values.type
      }
      if (values.value !== data.value) {
        d.value = values.value
      }
      createTax(d)
    } else {
      createTax(values)
    }
  }

  return (
    <div>
      <div className='card-body'>
        <Formik
          initialValues={data}
          validationSchema={taxSchema}
          onSubmit={onSubmit}
          validateOnBlur={false}
          validateOnChange={false}
          onReset={onCancel}
        >
          {({ errors, touched, isSubmitting, values, setFieldValue, handleSubmit }) => (
            <Form className="form">

              <div className="mb-1 mt-1">
                <label
                  className="form-label"
                  htmlFor="first-name-column"
                >
                  Country
                </label>
                <CountryDropdown onCountrySelect={(val) => { setFieldValue('country', val.value); setCountry(val) }} selectedCountry={country} disabled={fromEdit} />
                {errors.country && touched.country && (
                  <span class="error text-danger">{errors.country}</span>
                )}
              </div>
              <div className="mb-1 mt-1">
                <label
                  className="form-label"
                  htmlFor="first-name-column"
                >
                  Title
                </label>
                <Field
                  type="text"
                  id="first-name-column"
                  className="form-control"
                  placeholder="Enter tax name"
                  name="name"
                />
                {errors.name && touched.name && (
                  <span class="error text-danger">{errors.name}</span>
                )}
              </div>
                <div className="row align-items-center">
                  <div className="col-md-6 col-12">
                    <div role="group" aria-labelledby="checkbox-group" name="type" className="radio-group">
                      <RadioButton value="1" setFieldValue={() => { setFieldValue('type', 1) }} label='Percentage' checked={values.type == 1 ? true : false} name="type" />
                      <RadioButton value="2" setFieldValue={() => { setFieldValue('type', 2) }} label='Fixed Rate' checked={values.type == 2 ? true : false} name="type" />
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <CustomInput label='Enter tax value' placeholder='Enter tax value' onChange={(val) => { setFieldValue('value', val) }} value={values.value} />
                    {errors.value && touched.value && (
                      <span className="error text-danger">{errors.value}</span>
                    )}
                  </div>
                </div>
              <div className="row mt-3">
                <div className="col-12">
                  <button
                    type="submit"
                    className="btn filter-btn mr-3 ml-0 waves-effect waves-float waves-light"
                    disabled={isSubmitting}
                  >
                    Submit
                  </button>
                  <button
                    type="reset"
                    className="btn btn-outline-secondary waves-effect"
                    disabled={isSubmitting}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}