export const customTableStyles = {
    rows: {
        style: {
            minHeight: '70px', // override the row height
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
        },
    },
    headRow: {
        style: {
            borderTopWidth: '1px',
            borderTopStyle: 'solid',
            borderTopColor: '#D3D3D3',
            borderBottomWidth: '2px',
            borderBottomStyle: 'solid',
            borderBottomColor: '#D3D3D3',
        },
    }
}