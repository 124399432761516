import * as React from "react"

function ProfileTeamAdmin(props) {
  return (
    <svg
      width={48}
      height={48}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={48} height={48} rx={24} fill="#28C76F" fillOpacity={0.12} />
      <path
        d="M34.191 23.617h-2.545c-.346 0-.681.047-1 .134a3.812 3.812 0 00-3.397-2.088h-4.498c-1.482 0-2.767.85-3.396 2.088a3.795 3.795 0 00-1.001-.134h-2.545A3.813 3.813 0 0012 27.426v4.068a2.288 2.288 0 002.285 2.285h21.43A2.288 2.288 0 0038 31.494v-4.068c0-2.1-1.709-3.809-3.809-3.809zm-15.249 1.855v6.784h-4.657a.763.763 0 01-.762-.762v-4.068a2.288 2.288 0 012.286-2.285h2.545c.206 0 .407.028.597.08-.005.083-.009.166-.009.251zm10.593 6.784h-9.07v-6.784a2.288 2.288 0 012.286-2.285h4.498a2.288 2.288 0 012.286 2.285v6.784zm6.942-.762c0 .42-.342.762-.762.762h-4.657v-6.784a3.81 3.81 0 00-.01-.252c.191-.051.392-.08.598-.08h2.545a2.288 2.288 0 012.286 2.286v4.068z"
        fill="#28C76F"
      />
      <path
        d="M17.081 16.424a3.388 3.388 0 00-3.384 3.384 3.388 3.388 0 003.384 3.385 3.388 3.388 0 003.384-3.385 3.388 3.388 0 00-3.384-3.384zm0 5.245a1.863 1.863 0 01-1.86-1.86c0-1.027.834-1.861 1.86-1.861 1.026 0 1.861.834 1.861 1.86a1.863 1.863 0 01-1.86 1.861zM25 12.22a4.527 4.527 0 00-4.521 4.522A4.527 4.527 0 0025 21.263a4.527 4.527 0 004.521-4.521A4.527 4.527 0 0025 12.22zm0 7.52a3.001 3.001 0 01-2.998-2.998A3.001 3.001 0 0125 13.744a3.001 3.001 0 012.998 2.998A3.001 3.001 0 0125 19.74zM32.919 16.424a3.388 3.388 0 00-3.384 3.384 3.388 3.388 0 003.384 3.385 3.388 3.388 0 003.384-3.385 3.388 3.388 0 00-3.384-3.384zm0 5.245a1.863 1.863 0 01-1.861-1.86c0-1.027.835-1.861 1.86-1.861 1.027 0 1.862.834 1.862 1.86a1.863 1.863 0 01-1.861 1.861z"
        fill="#28C76F"
      />
    </svg>
  )
}

export default ProfileTeamAdmin
