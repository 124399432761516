import * as React from "react"

function EllipseIcon(props) {
    return (
        <svg width={16}
            height={16}
            viewBox="0 0 16 16"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <circle cx="50%" cy="50%" r="4" fill="white" stroke="black"/>
        </svg>
    )
}

export default EllipseIcon