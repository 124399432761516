import React from 'react';
import { Field } from 'formik';
const CustomInput = ({ label, placeholder, value, onChange }) => {
  return (
    <>
      <label
        className="form-label"
        htmlFor="first-name-column"
      >
        {label}
      </label>
      <Field
        type="text"
        id="first-name-column"
        className="form-control"
        placeholder={placeholder}
        value={value}
        onChange={(e)=>onChange(e.target.value)}
      />
    </>
  );
};

export default CustomInput;