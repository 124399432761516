import React from 'react';
import './createOwner.scss';
import * as Yup from 'yup';
import { Formik, Field, Form } from 'formik';
import 'react-phone-number-input/style.css'
import PhoneInputField from '../../phoneInput';


const ownerSchema = Yup.object().shape({
  name: Yup.string().trim().required('Name Required'),
  email: Yup.string().trim().email('Invalid email').required('Email Required'),
  mobile: Yup.string().trim().min(7).max(15).required('Mobile Number Required'),
  location: Yup.string().trim().required('Location Required'),
  ownerName: Yup.string().trim().required('Owner Name Required'),
  ownerEmail: Yup.string().trim().email('Invalid email').required('Owner Email Required'),
});

export default function CreateOwner({ createUser, data, fromEdit,onCancel }) {

  const onSubmit = (values) => {
    if(fromEdit) {
      let d = {};
      if(values.name !== data.name) {
        d.name = values.name
      }
      if(values.email !== data.email) {
        d.email = values.email
      }
      if(values.mobile !== data.mobile) {
        d.mobile = values.mobile
      }
      if(values.location !== data.location) {
        d.location = values.location
      }
      if(values.status !== data.status) {
        d.status = values.status
      }
      if(values.ownerName !== data.ownerName) {
        d.ownerName = values.ownerName
      }
      if(values.ownerEmail !== data.ownerEmail) {
        d.ownerEmail = values.ownerEmail
      }
      createUser(d)
    } else {
      createUser(values)
    }
  }
  
  return (
    <div>
      <div className='card-body'>
        <Formik
          initialValues={{
            name: data.name,
            email: data.email,
            mobile: data.mobile,
            location: data.location,
            status: data.status,
            ownerName: data.ownerName,
            ownerEmail: data.ownerEmail
          }}
          validationSchema={ownerSchema}
          onSubmit={onSubmit}
          validateOnBlur={false}
          validateOnChange={false}
          onReset={onCancel}
        >
          {({ errors, touched, isSubmitting, values, setFieldValue, handleSubmit }) => (
            <Form className="form">

              <div className="mb-1 mt-1">
                <label
                  className="form-label"
                  htmlFor="first-name-column"
                >
                  Manager Name
                </label>
                <Field
                  type="text"
                  id="first-name-column"
                  className="form-control"
                  placeholder="Enter manager name"
                  name="name"
                />
                {errors.name && touched.name && (
                  <span class="error text-danger">{errors.name}</span>
                )}
              </div>
              <div className="mb-1 mt-1">
                <label
                  className="form-label"
                  htmlFor="first-name-column"
                >
                  Email
                </label>
                <Field
                  type="text"
                  id="first-name-column"
                  className="form-control"
                  placeholder="Ente mail id"
                  name="email"
                />
                {errors.email && touched.email && (
                  <span class="error text-danger">{errors.email}</span>
                )}
              </div>
              <div className="mb-1 mt-1">
                <label
                  className="form-label"
                  htmlFor="first-name-column"
                >
                  Mobile Number(with country code)
                </label>
                <Field
                  // type='tel'
                  name="mobile"
                  onChange={(val) => { setFieldValue('mobile', val); }}
                  component={PhoneInputField}
                />
                {errors.mobile && touched.mobile && (
                  <span class="error text-danger">{errors.mobile}</span>
                )}
              </div>
              <div className="mb-1 mt-1">
                <label
                  className="form-label"
                  htmlFor="first-name-column"
                >
                  Location
                </label>
                <Field
                  type="text"
                  id="first-name-column"
                  className="form-control"
                  placeholder="Enter location"
                  name="location"
                />
                {errors.location && touched.location && (
                  <span class="error text-danger">{errors.location}</span>
                )}
              </div>
              <div className="mb-1 mt-1">
                <label
                  className="form-label"
                  htmlFor="first-name-column"
                >
                  Owner Name
                </label>
                <Field
                  type="text"
                  id="first-name-column"
                  className="form-control"
                  placeholder="Enter owner name"
                  name="ownerName"
                />
                {errors.ownerName && touched.ownerName && (
                  <span class="error text-danger">{errors.ownerName}</span>
                )}
              </div>
              <div className="mb-1 mt-1">
                <label
                  className="form-label"
                  htmlFor="first-name-column"
                >
                  Owner Email
                </label>
                <Field
                  type="text"
                  id="first-name-column"
                  className="form-control"
                  placeholder="Ente owner mail id"
                  name="ownerEmail"
                />
                {errors.ownerEmail && touched.ownerEmail && (
                  <span class="error text-danger">{errors.ownerEmail}</span>
                )}
              </div>
              <div className="mb-1 mt-1">
                <label>
                  <Field type="checkbox" name="status" value={values.status} checked={values.status === 0 ? false : true} onChange={() => { values.status === 0 ? setFieldValue('status', 1) : setFieldValue('status', 0) }} />
                  <span style={{marginLeft: 5}}>Enable this manager</span>
                </label>
              </div>
              <div className="row mt-3">
                <div className="col-12">
                  <button
                    type="submit"
                    className="btn filter-btn mr-3 ml-0 waves-effect waves-float waves-light"
                    disabled={isSubmitting}
                  >
                    Submit
                  </button>
                  <button
                    type="reset"
                    className="btn btn-outline-secondary waves-effect"
                    disabled={isSubmitting}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}