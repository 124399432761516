import React, { useState, useEffect } from 'react';
import './popupBanner.scss';
import * as Headless from './popupBanner.headless';
import {useNavigate} from 'react-router-dom';
import {Dropdown,Modal,Button} from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Loader from "react-loader-spinner";
import _ from 'lodash';
import Sidebar from "react-sidebar";
import DataTable from 'react-data-table-component';
import Pagination from '../../components/Pagination';
import { customTableStyles } from '../../utils/styles'
import actionIcon from '../../../assets/svg/actionIcon.svg';
import CustomToast from '../../components/CustomToast';
import ConfirmPopup from '../../components/confirmPopup';
import CreatePopupBanner from './createPopupBanner';
import {getNewCountryName} from '../../utils/functions';

export function PopupBanner () {
  const navigate = useNavigate();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [openFilter, setOpenFilter] = useState(false)
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState({ variant: 'success', message: '' });
  const [chosenId, setChosenId] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [popupBannerData, setPopupBannerData] = useState({
    name: '',
    image: '',
    url:'',
    startDate:'',
    endDate:'',
    location:'',
    tabName: '',
    screenName: '',
    id: '',
    urlType: '0',
    country: '',
    chosenValue: '',
    durations:''
  });
  useEffect(() => {
  }, [selectedCountry])
  
  const [editPressed, setEditPressed] = useState(false);
  const columns = [
    {
      name: <th>#</th>,
      selector: (row, index) => index + (limit * (page - 1)) + 1,
      cell: (row, index) =>
        <td className="d-flex">{index + (limit * (page - 1)) + 1}</td>,
      grow: 0.05,
      width: '60px'
    },
    {
      name: <th>NAME</th>,
      selector: (row, index) => row.title.toLowerCase(),
      cell: (row, index) =>
        <td style={{ maxWidth: 150 }}>
          {row.name}
        </td>,
      sortable: true,
    },
    {
      name: <th>DURATION</th>,
      selector: (row, index) => row.duration.toLowerCase(),
      cell: (row, index) =>
        <td style={{ maxWidth: 150 }}>
          {row.duration}
        </td>,
      sortable: true,
    },
    {
      name: <th>IMAGE</th>,
      cell: (row, index) =>
        <td style={{ maxWidth: 150 }}>
          {row.image?
            <img src={row.image} width={30} height={30} />
            : 'NA'
          }
        </td>,
      sortable: false,
    },
    {
      name: <th>Date Range</th>,
      selector: (row, index) => row.date && row.date.startDate ? row.date.startDate : '',
      cell: (row, index) =>
        <td style={{ width: 150 }}>
          {row.date && row.date.startDate && row.date.endDate ? `${row.date.startDate} - ${row.date.endDate}` : 'NA'}
        </td>,
      sortable: true,
    },
    {
      name: <th>URL TYPE</th>,
      selector: (row, index) => row.title.toLowerCase(),
      cell: (row, index) =>
        <td style={{ maxWidth: 150 }}>
          {row.urlType=="0"?(
            <p className='pubicP'>Public</p>
          ):(
            <p className='inAppP'>In App</p>
          )}
        </td>,
      sortable: true,
    },
    {
      name: <th>URL/PATH</th>,
      selector: (row, index) => row.url.toLowerCase(),
      cell: (row, index) =>
        <td style={{ maxWidth: 150 }}>
          {/* {row.description} */}
          {row.url && row.url.trim() != '' ?
            <OverlayTrigger trigger="hover" placement="bottom" overlay={<Popover id="popover-basic">
              <Popover.Content>
                {row.url}
              </Popover.Content>
            </Popover>}>
              <a href={row.url} target="_blank">
              <Button className="popOverButton btn btn-outline-primary publicBtn">{row.url.slice(0,row.url.indexOf("."))}</Button>
              </a>
            </OverlayTrigger>
            : 
            <OverlayTrigger trigger="hover" placement="bottom" overlay={<Popover id="popover-basic">
            <Popover.Content>
            {row.screenName&&row.chosenValue?.trim()!=""?(
              row.screenName+" / "+row.chosenValue
            ):row.screenName}
            </Popover.Content>
          </Popover>}>
            <Button className="popOverButton btn btn-outline-danger inAppBtn">{row.screenName}</Button>
          </OverlayTrigger>
          }

        </td>,
      sortable: true,
    },
    // {
    //   name: <th>COUNTRY</th>,
    //   selector: (row, index) => row.country && row.country.country ? row.country.country : '',
    //   cell: (row, index) =>
    //     <td style={{ maxWidth: 150 }}>
    //       {row.country && row.country.country ? row.country.country : 'NA'}
    //     </td>,
    //   sortable: true,
    // },
    // {
    //   name: <th>LOCATION</th>,
    //   selector: row => row.location && row.location.name ? row.location.name : 'N/a',
    //   cell: (row) =>
    //     <td style={{ maxWidth: 150 }}>
    //       {row.location && row.location.name ? row.location.name : 'N/a'}
    //     </td>,
    //   sortable: true,
    //   grow: 1.5
    // },
    {
      name: <th>ACTIONS</th>,
      selector: (row, index) => 'actions',
      cell: (row, index) =>
        <td className='table-action-column'>
          {actionButton(row)}
        </td>,
    },
  ]
  const actionButton = (row) => {
    return (
      <Dropdown drop="up">
        <Dropdown.Toggle variant="default" id="dropdown-basic" className='actionDropdown'>
          <img alt="" src={actionIcon} width={30} height={30} />
        </Dropdown.Toggle>
        <Dropdown.Menu className='custom-dropdown'>
          <Dropdown.Item href="#" onClick={(e) => { setChosenId(row._id); onButtonPress('edit', row) }}>Edit</Dropdown.Item>
          <Dropdown.Item href="#" onClick={(e) => { setChosenId(row._id); setShow(!show) }}>Delete</Dropdown.Item>
          {/* <Dropdown.Item href="#" onClick={() => { onChangeHighlightStatus(row.status, row._id) }}>{row.status ? 'Disable' : 'Enable'}</Dropdown.Item> */}
        </Dropdown.Menu>
      </Dropdown>
    )
  }
  const getPopupBanner = async (l, p, s, filter) => {
    setLoading(true);
    try {
      const response = await Headless.getPopupBannerList(l, p, s, filter);
      if (response && response.data && response.data.pages) {
        setPages(response.data.pages);
      }
      if (response && response.data && response.data.total) {
        setTotal(response.data.total);
      }
      if (response && response.data && response.data.data) {
        setData(response.data.data);
      }
      setLoading(false);
    } catch (err) {
      setPages(1);
      setTotal(0);
      setData([]);
      setLoading(false);
    }
  }

  const onSearch = _.debounce(setSearch, 500);

  useEffect(() => {
    getPopupBanner(limit, page, search, filters);
  }, [page]);
  useEffect(() => {
    getPopupBanner(limit, 1, search, filters);
  }, [limit, search, filters])

  const onToggleLimit = (e, value) => {
    e.preventDefault();
    setPage(1)
    setLimit(value);
  };
  const onPressFilter = () => {
    let filter = {}
    setFilters(filter)
    setOpenFilter(false)
  }

  const onPressClear = () => {
    setFilters({});
  }
  const onCreatePopupBanner = (values) => {
    setShowModal(false);
    Headless.createPopupBanner(values).then(() => {
      setToastMessage({
        variant: 'success',
        message: 'Banner added successfully!'
      })
      setShowToast(true);
      getPopupBanner(limit, page, search, filters);
    })
      .catch(err => {
        setToastMessage({
          variant: 'danger',
          message: err.response && err.response.data && err.response.data.status ? err.response.data.status : 'Adding new banner failed!'
        })
        setShowToast(true);
      })
  }
  const onEditPopupBanner = (values) => {
    setShowModal(false);
    Headless.onEditPopupBanner(chosenId, values).then(() => {
      setToastMessage({
        variant: 'success',
        message: 'Banner details edited successfully!'
      })
      setShowToast(true);
      getPopupBanner(limit, page, search, filters);
      setChosenId('');
      setEditPressed(false);
    })
      .catch(err => {
        setToastMessage({
          variant: 'danger',
          message: 'Editing Popup Banner failed!'
        })
        setShowToast(true);
        setChosenId('');
        setEditPressed(false);
      })
  }
  const onDeleteConfirm = async () => {
    try {
      const response = await Headless.deletePopupBanner(chosenId);
      setChosenId('');
      setToastMessage({
        variant: 'success',
        message: 'Popup Banner deleted successfully!'
      })
      setShowToast(true);
      getPopupBanner(limit, page, search, filters)
    } catch (err) {
      setToastMessage({
        variant: 'danger',
        message: 'Deleting banner failed!'
      })
      setShowToast(true);
      setChosenId('');
    }
  }

  const onButtonPress = async (from, row) => {
    if (from === 'edit') {
      setEditPressed(true);
      setPopupBannerData({
        name: row.name,
        startDate: row.date.startDate,
        endDate:row.date.endDate,
        image: row.image?row.image: '',
        url: row.url,
        // country:row.location&&row.location.country?row.location.country:"",
        screenName: row.screenName,
        tabName: row.tabName,
        id: row.id,
        urlType: row.urlType,
        country: row.country,
        chosenValue: row.chosenValue ? row.chosenValue : '',
        duration:row.duration
      })
      const country  = getNewCountryName(row.country);
      setSelectedCountry(country);
    } else {
      setEditPressed(false);
      setPopupBannerData({
        name:"" ,
        startDate: "",
        endDate:"",
        image: "",
        location:"",
        url: "",
        screenName: "",
        tabName: "",
        id: "",
        urlType: '0',
        country: '',
        chosenValue: '',
        duration:''
      })
      setSelectedCountry('');
    }
    setShowModal(!showModal);
  }
  return (
    <>
     <div className="card h-100">
        <Sidebar
          pullRight
          // sidebar={filter()}
          open={openFilter}
          styles={{
            sidebar:
            {
              background: "white",
              overflow: "visible",
              zIndex: 20
            },
            overlay:
            {
              backgroundColor: "transparent"
            }
          }}
        >
          <div className="card-body">
            <div className="d-flex align-items-center table-header-menu justify-content-between">
              <div className="d-flex align-items-center" />
              <div className="d-flex align-items-center">
                <div className="d-flex" onClick={() => { onButtonPress('create') }}>
                  <span className="btn filter-btn">
                    Add Banner
                  </span>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center table-header-menu justify-content-between">
              <div className="d-flex align-items-center">
                <p className="mr-3">Show</p>
                <p className="mr-3">{limit}</p>
                <Dropdown>
                  <Dropdown.Toggle variant="default" id="dropdown-basic">
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item href="#" onClick={(e) => onToggleLimit(e, 10)}>10</Dropdown.Item>
                    <Dropdown.Item href="#" onClick={(e) => onToggleLimit(e, 25)}>25</Dropdown.Item>
                    <Dropdown.Item href="#" onClick={(e) => onToggleLimit(e, 50)}>50</Dropdown.Item>
                    <Dropdown.Item href="#" onClick={(e) => onToggleLimit(e, 100)}>100</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="d-flex align-items-center">
                <p className="mr-3">Search</p>
                <input className="form-control" placeholder="Search" onChange={(e) => onSearch(e.target.value.trim())} />
                {/* <span className="btn filter-btn" onClick={() => setOpenFilter(true)}>
                  Filter
                  {Object.keys(filters).length > 0 ?
                    <span>
                      &#9733;
                    </span>
                    : null}
                </span> */}
              </div>
            </div>
            {!loading && data.length > 0 ?
              <div className="dataHeader">
                <p className="mr-3">
                  Showing {page > 1 ? (page - 1) * limit : 1} to {page * limit < total ? page * limit : total} of {total} entries {Object.keys(filters).length > 0 ? `(filtered)` : ''}
                </p>
              </div>
              : null}
            <div className="table-responsive">
              {loading ? <div className="d-flex justify-content-center">
                <Loader
                  type="TailSpin"
                  color="#000"
                  height={30}
                  width={30}
                />
              </div> :
                <DataTable
                  columns={columns}
                  data={data}
                  customStyles={customTableStyles}
                />
              }
            </div>
          </div>
          {!loading && data.length > 0 ?
            <div className="d-flex card-footer align-items-center justify-content-between">
              <p className="page-data-count">
                Showing {page > 1 ? (page - 1) * limit : 1} to {page * limit < total ? page * limit : total} of {total} entries
              </p>
              <div>
                <Pagination pages={pages} current={page} onChange={setPage} />
              </div>
            </div>
            : null}
        </Sidebar>
        <ConfirmPopup show={show} message="Are you sure to delete this banner?" submitButtonText='Confirm' cancelButtonText='Close' handleConfirm={() => { setShow(false); onDeleteConfirm() }} handleDismiss={() => { setShow(false) }} />
        <Modal size="xl" show={showModal} onHide={() => {
          setShowModal(false); if (editPressed) {
            setEditPressed(false);
            setChosenId('');
          }
        }} centered>
          <Modal.Header closeButton>
            <Modal.Title>{editPressed ? 'Edit Banner' : 'Add Banner'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <CreatePopupBanner selectedCountry={selectedCountry} setSelectedCountry={setSelectedCountry} onCancel={()=>{
              setEditPressed(false);
              setChosenId('');
              setShowModal(false)
            }} createPopupBanner={(values) => { editPressed ? onEditPopupBanner(values) : onCreatePopupBanner(values) }} data={popupBannerData} fromEdit={editPressed ? true : false}/>
          </Modal.Body>
        </Modal>
        <CustomToast  variant={toastMessage.variant} message={toastMessage.message} show={showToast} onClose={() => { setShowToast(false) }} />
      </div>
    </>
  )
}