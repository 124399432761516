import React from 'react';
import { Field } from 'formik';

const RadioButton = ({ checked, value, setFieldValue, label, name }) => {

  return (
    <div class="form-check form-check-inline">
      <label>
        <Field type="radio" value={value} checked={checked} onClick={() => { setFieldValue('type', value) }} />
        <span style={{ marginLeft: 5 }}>{label}</span>
      </label>
    </div>
  );
};

export default RadioButton;