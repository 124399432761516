const axios = require('axios');
axios.defaults.withCredentials = true;
const baseUrl =
  process.env.REACT_APP_ENV === 'dev' ?
    process.env.REACT_APP_BASE_URL_DEV
    : process.env.REACT_APP_ENV === 'prod'
      ? process.env.REACT_APP_BASE_URL_PROD
      : process.env.REACT_APP_BASE_URL_STAG;

let headers = {};
export function setHeader(key, value) {
  headers[key] = value;
}
export function removeHeader(key) {
  delete headers[key];
}
export async function send(path, type, data) {
 
  // const token = localStorage.getItem('token');
  // let headers = {};
  // if (token) {
  //   headers['Authorization'] = `Bearer ${token}`;
  // }
  let response = axios({
    method: type,
    data: data,
    headers: headers,
    url: baseUrl + "/" + path
  });
  console.log("response",response)
  return response;
}

export async function sendServer(path, type, data) {
  let response = axios({
    method: type,
    data: data,
    headers: headers,
    url: path
  });
  return response;
}

