import React from 'react'
import PropTypes from 'prop-types';
import ChevronLeft from '../../assets/svg/ChevronLeft';

function Pagination({pages, current, onChange}) {

    const onClick = (e, num) => {
        e.preventDefault();
        onChange(num);
    }

    const renderLinks = () => {
        let links = [];
        let nums = pages;
        let start = 1;
        if (pages > 6) {
            nums = current + 5 > 6 ? current + 2 > pages ? pages : current + 2 : 6;
            start = current - 3 > 0 ? current - 2 : 1;
            if (nums - start < 6) {
                start = nums - 6 > 0 ? nums - 6 : current > 4 ? current - 2 : 1;
                nums = nums - 6 < 0 ? nums + 1 : nums;
            }
        }
        if (nums > 6 && start !== 1) {
            links.push(
                <li key="pagination-link-first" className={1 === current ? "page-item active" : "page-item"}>
                    <a className="page-link" href="#" onClick={(e) => onClick(e, 1)}>1</a>
                </li>
            );
        }
        for (let i = start; i <= nums; i++) {
            links.push(
                <li key={`pagination-link-${i}`} className={i === current ? "page-item active" : "page-item"}>
                    <a className="page-link" href="#" onClick={(e) => onClick(e, i)}>{i}</a>
                </li>
            );
        }
        if (nums !== pages) {
            links.push(
                <li key="pagination-link-last" className={pages === current ? "page-item active" : "page-item"}>
                    <a className="page-link" href="#" onClick={(e) => onClick(e, pages)}>{pages}</a>
                </li>
            );
        }
        return links;
    }

    return (
        <ul className="pagination mb-0 pagination-rounded">
            <li className={current === 1 ? "page-item disabled" : "page-item"}>
                <a className="page-link" href="#" onClick={(e) => onClick(e, current - 1)}>
                    <ChevronLeft fill="#A44B7B" />
                </a>
            </li>
            <ul className="pagination inner-links">
                {renderLinks()}
            </ul>
            <li className={current === pages ? "page-item disabled" : "page-item"}>
                <a className="page-link" href="#" onClick={(e) => onClick(e, current + 1)}>
                    <ChevronLeft transform="rotate(180)" fill="#A44B7B" />
                </a>
            </li>
        </ul>
    )
}

Pagination.propTypes = {
    pages: PropTypes.number.isRequired,
    current: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
}

export default Pagination

