const dayjs = require('dayjs');
const utc = require('dayjs/plugin/utc');
const advancedFormat = require('dayjs/plugin/advancedFormat');
const isSameOrAfter = require('dayjs/plugin/isSameOrAfter');
const isSameOrBefore = require('dayjs/plugin/isSameOrBefore');
dayjs.extend(utc);
dayjs.extend(advancedFormat);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
const offset = new Date().getTimezoneOffset();

const now = () => {
  return dayjs();
};

const getLocalTime = (utcDate) => {
  return dayjs(utcDate).local();
};

const formatToLocalTime = (utcDate, format) => {
  return dayjs(utcDate).local().format(format);
};

const getUTCTime = (date) => {
  return dayjs(date).utc();
};

const formatToUTCTime = (utcDate, format) => {
  return dayjs(utcDate).utc().format(format);
};
const formatToUTC24Time = (utcDate) => {
  return dayjs(utcDate).format("HH:mm");
};
const format = (date, format) => {
  return dayjs(date).format(format);
};

const addOffsetTime = (date) => {
  return dayjs(date).add(-1 * offset, 'm');
};

const minusOffsetTime = (date) => {
  return dayjs(date).minute(offset);
};

const getStartEndDays = (date) => {
  const start = dayjs(date).startOf('month');
  const end = dayjs(date).endOf('month');
  return {start, end};
};

const isSame = (date1, date2, operator) => {
  return dayjs(date1).isSame(date2, operator);
};

const isBetween = (start, end, date) => {
  return dayjs(date).isAfter(start) && dayjs(date).isBefore(end);
};

const isBefore = (date1, date2) => {
  return dayjs(date1).isBefore(date2);
};

const isAfter = (date1, date2) => {
  return dayjs(date1).isAfter(date2);
};

const add = (date, value, operator) => {
  return dayjs(date).add(value, operator);
};

const sub = (date, value, operator) => {
  return dayjs(date).subtract(value, operator);
};

const sameOrAfter = (date1, date2, unit) => {
  return dayjs(date1, unit).isSameOrAfter(date2, unit);
};

const isSameOrBetween = (start, end, date) => {
  return dayjs(date).isSameOrAfter(start) && dayjs(date).isBefore(end);
};

//get dates beteween 2 dates
const getDates = (startDate, endDate) => {
  const dates = [];
  let currentDate = startDate;

  const addDay = function (day) {
    const date = add(day, 1, 'd');
    return date;
  };

  while (currentDate <= endDate) {
    dates.push(currentDate);
    currentDate = addDay(currentDate);
  }
  return dates;
};

const convert24To12 = (time) => {
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];
  if (time.length > 1) {
    time = time.slice(1);
    time[5] = +time[0] < 12 ? ' AM' : ' PM'; 
    time[0] = +time[0] % 12 || 12; 
  }
  return time.join('');
};

module.exports = {
  getLocalTime,
  formatToLocalTime,
  getUTCTime,
  formatToUTCTime,
  addOffsetTime,
  minusOffsetTime,
  getStartEndDays,
  isSame,
  isBetween,
  isBefore,
  isAfter,
  add,
  sub,
  getDates,
  now,
  format,
  convert24To12,
  isSameOrBetween,
  sameOrAfter,
  formatToUTC24Time
};
