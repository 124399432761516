import { createSlice } from '@reduxjs/toolkit'

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    loggedIn:false,
    dataLoaded: false,
    menus: [],
    countries: [],
    positions: [],
    levels: [],
    intensity:[],
    targets:[],
    clubs: [],
    user: null,
    pitchtypes: [],
    timeSlots: [],
    newCountryList: [],
    newSurfaceList: [],
    newPitchTypes: [],
  },
  reducers: {
    login:(state, action)=> {
      if(action.payload && action.payload.isValidAuth){
        state.loggedIn=true;
        state.menus=action.payload.menus;
        state.user = action.payload.user;
      }
        
    },
    logout:(state, action)=> {
        state.loggedIn=false;
        state.menus = [];
        state.user = null;
    },
    setLocalData: (state, action) => {
      state.countries = action.payload.countries;
      state.positions = action.payload.positions;
      state.levels = action.payload.levels;
      state.targets = action.payload.targets;
      state.intensity = action.payload.intensity;
      // state.clubs = action.payload.clubs;
      // state.pitchtypes = action.payload.pitchtypes
      state.dataLoaded = true;
    },
    setTimeSlots: (state, action) => {
      state.timeSlots = action.payload;
    },
    setNewCountryList: (state, action) => {
      state.newCountryList = action.payload;
    },
    setNewSurfaceList: (state, action) => {
      state.newSurfaceList = action.payload;
    },
    setNewPitchList: (state, action) => {
      state.newPitchTypes = action.payload;
    },
  }
})

export const { login, logout, setLocalData, setTimeSlots, setNewCountryList, setNewSurfaceList, setNewPitchList } = authSlice.actions;
export const isLoggedIn = state => state.auth.loggedIn;
export const menus = state => state.auth.menus;
export const user = state => state.auth.user;
export const getCountries = state => state.auth.countries;
export const getPositions = state => state.auth.positions;
export const getLevels = state => state.auth.levels;
export const getTargets = state => state.auth.targets;
export const getIntensity = state => state.auth.intensity;
export const getClubs = state => state.auth.clubs;
export const getDataLoaded = state => state.auth.dataLoaded;
export const getTimeSlots = state => state.auth.timeSlots;
export const getNewCountryList = state => state.auth.newCountryList;
export const getNewSurfaceList = state => state.auth.newSurfaceList;
export const getNewPitchList = state => state.auth.newPitchTypes;

export default authSlice.reducer;