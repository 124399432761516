import { send, setHeader } from "../../utils/api";

export async function getAdminsList(limit, page, search, filter) {
    let body = {
        filter: filter
    }
    try {
        const token = localStorage.getItem('token');
        setHeader('Authorization', `Bearer ${token}`);
        let url = `studioList?size=${limit}&page=${page}`;
        if (search) {
            url += `&search=${search}`;
        }
        let data = await send(url, "post", body);
        return data;
    } catch (error) {
        throw error
    }
  
}

export async function createAdmin(body) {
    try {
        const token = localStorage.getItem('token');
        setHeader('Authorization', `Bearer ${token}`);
        let url = `createStudioList`;
        let data = await send(url, "post", body);
        return data;
    }
    catch (err) {
        throw err
    }
}

export async function editAdmin(id, body) {
    try {
        const token = localStorage.getItem('token');
        setHeader('Authorization', `Bearer ${token}`);
        let url = `updateStudioList/${id}`;
        let data = await send(url, "post", body);
        return data;
    }
    catch (err) {
        throw err
    }
}

export async function getAdminDetails(id) {
    const token = localStorage.getItem('token');
    setHeader('Authorization', `Bearer ${token}`);
    let url = `adminDetails/${id}`;
    let data= await send(url,"get");
    return data;
}

export async function changeUserStatus(id, body) {
    const token = localStorage.getItem('token');
    setHeader('Authorization', `Bearer ${token}`);
    let url = `updateAdminStatus/${id}`
    let data= await send(url,"post",body);
    return data;
}

export async function deleteAdmin(id) {
    const token = localStorage.getItem('token');
    setHeader('Authorization', `Bearer ${token}`);
    let url = `deleteStudioList/${id}`
    let data= await send(url,"delete");
    return data;
}

export async function uploadAdminImage(file) {
    const token = localStorage.getItem('token');
    setHeader('Authorization', `Bearer ${token}`);
    let url = `uploadAdminImage`;
    var formdata = new FormData();
    formdata.append("file", file);
    try {
        let data = await send(url, "post", formdata);
        return data;
    } catch (err) {
        throw err;
    }
};



