import React, { useState, useEffect } from 'react';
import './studios.scss';
import * as Headless from "./studio.headless";
import { useSelector } from 'react-redux';
import { getOwnersList } from '../owners/Owners.headless'
import { useNavigate,useParams } from "react-router-dom";
import Geocode from "react-geocode";
import { Autocomplete } from '@material-ui/lab'
import { TextField } from "@material-ui/core";
import GoogleMapReact from 'google-map-react';
import { Typography } from '@material-ui/core';
import { Breadcrumbs } from '@material-ui/core';
import { Link } from '@material-ui/core';
import * as Yup from 'yup';
import { Formik, Field, Form } from 'formik';
import Multiselect from 'multiselect-react-dropdown';
import Loader from "react-loader-spinner";
import { ImageUploader } from '../../components/pitchBooking/venues/imageUploader';
import { getAddress, parseCountryList } from '../../utils/functions'
import CustomToast from '../../components/CustomToast';
import { TitleAndContent } from '../../components/titleAndContent';
import SearchableInput from '../../components/searchableInput';
import { MultiSelect } from "react-multi-select-component";
import backIcon from '../../../assets/img/backicon.png';
import { getNewCountryList } from '../../slices/auth.slice';
import RadioButton from '../../components/radioButton';
import { ImageEditor } from '../../components/studioManagement/studios/ImageEditor';

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_KEY);

const venueSchema = Yup.object().shape({
  studioName: Yup.string().trim().required('Venue Name Required'),
  email: Yup.string().trim().email('Invalid email').required('Email Required'),
  phone: Yup.string().trim().min(7).max(15).required('Phone number Required'),
  description: Yup.string().trim().min(20).required('Description Required'),
  location: Yup.object().shape({
    name: Yup.string().required('Choose Location')
  }).required('Choose Location'),
  owner: Yup.string().trim().required('Manager name Required'),
  countryId: Yup.string().trim().required('Country Required'),
  highlightIds: Yup.array().min(1, 'Choose atleast one highlight').required('Choose atleast one highlight'),
  images: Yup.array().min(1, 'Atleast one image required').required('Atleast one image required'),
  taxes: Yup.array(),
});

export function EditStudio() {
  let { id } = useParams();
  const navigate = useNavigate();
  const countryRaw = useSelector(getNewCountryList);
  const [draggable, setDraggable] = useState(false)
  const [location, setLocation] = useState(null)
  const [defaultImageIndex, setDefaultImageIndex] = useState(0);
  const [searchLocations, setSearchLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [remoteUrls, setRemoteUrls] = useState([]);
  const [defaultImage, setDefaultImage] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState({ variant: 'success', message: '' });
  const [selectedCountry, setSelectedCountry] = useState('');
  const [searchOwners, setSearchOwners] = useState([]);
  const [placeName, setPlaceName] = useState(null);
  const [highlights, setHighlights] = useState([]);
  const [selectedHighlights, setSelectedHighlights] = useState([]);
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [taxes, setTaxes] = useState([]);
  const [selectedTaxes, setSelectedTaxes] = useState([]);
  const [isLocation,setIsLocation]=useState(false)
  const [initialData, setInitialData] = useState(null);
  const [data, setData] = useState(null);
  const [ownerName, setOwnerName] = useState({_id:'', name:''});
  const [reset, setReset] = useState(false);

  const getStudioDetails = async () => {
    setLoading(true);
    try {
      const response = await Headless.getStudioDetails(id);
      console.log("data is",response.data.data.data);
      setData(response.data.data.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }

  useEffect(() => {
    getStudioDetails();
  }, []);

  useEffect(() => {
    if (data !== null) {
      let countries = parseCountryList(countryRaw);
      setCountries(countries);
      let id = data.country && data.country._id && data.country._id._id ? data.country._id._id : ''
      let index = countries.findIndex((elem) => elem.value === id);
      if (index !== -1) {
        setSelectedCountry({ ...countries[index] });
      }
    }
  }, [countryRaw, data])
  useEffect(() => {
    parseData('load');
  }, [data])
  const parseData = (from) => {
    if (data !== null) {
      console.log("Initial data are", data)
      let d = {
        studioName: data.studioName,
        studioArea: data.studioArea,
        email: data.contacts.email,
        phone: data.contacts.phone,
        owner: data.owner && data.owner._id ? data.owner._id : '',
        description: data.description,
        location: data.location,
        countryId: data.country && data.country._id && data.country._id._id ? data.country._id._id : '',
        images: [],
        highlightIds: [],
        taxIds: [],
        whatsapp: data.contacts.whatsapp ? data.contacts.whatsapp : "",
        taxType: data.taxType ? data.taxType : 1,
        website:data?.contacts?.website?data?.contacts?.website:""
      };

      console.log("DDDDDAAATTAA", d)
      if (data.location) {
        setLocation({
          lat: data.location.coordinates.lattitude,
          lng: data.location.coordinates.longitude,
        });
        let pl = { ...placeName }
        pl.placeName = data.location.city + ',' + data.location.name;
        setPlaceName({ ...pl })
        setSelectedLocation(data.location);
      }
      if (data.highlights) {
        let h = [];
        let ids = [];
        data.highlights.forEach((elem, index) => {
          let item = elem._id;
          h.push({ label: item.title, value: item._id });
          ids.push({ _id: item._id });

        })
        setSelectedHighlights([...h]);
        d.highlightIds = ids;
      }
      if (data.taxes) {
        let h = [];
        let ids = [];
        data.taxes.forEach((elem, index) => {
          let item = elem._id;
          h.push({ label: `${item.taxName}(${item.taxValue}${getTaxType(item.type)})`, value: item._id });
          ids.push({ _id: item._id });

        })
        setSelectedTaxes([...h]);
        d.taxIds = ids;
        Headless.getTaxList(d.countryId).then((out) => {
          const t = [];
          out.data.data.data.forEach(item => {
            t.push({ label: `${item.taxName}(${item.taxValue}${getTaxType(item.type)})`, value: item._id })
          })
          setTaxes([...t]);
        })
          .catch(err => {
            setTaxes([]);
          })
      }
    
      if (data.images) {
        let r = from !== 'reset' ? [...remoteUrls] : [];
        data.images.forEach((item, index) => {
          if (item.isDefault == true) {
            setDefaultImageIndex(index);
          }
          r.push({ id: index, url: item.uri })
        })
        d.images = [...r];
        setRemoteUrls([...r]);
      }
      if (from !== 'reset')
        setInitialData({ ...d });
    }
  }


  useEffect(() => {
    Headless.getHighlights().then((out) => {
      let h = []
      out.data.data.forEach(item => {
        h.push({ label: item.title, value: item._id })
      })
      console.log("highlights",h);
      setHighlights([...h]);
    })
      .catch(err => {
        setHighlights([]);
      })
  }, [])


  // useEffect(() => {
  //   setCountries(parseCountryList(countryRaw));
  // }, [countryRaw])

  const setLocationItem = async (value, setFieldValue, values) => {
    let lat = value.lat ? value.lat : value.coordinates.lattitude ? value.coordinates.lattitude : 9.960120371862358;
    let lng = value.lng ? value.lng : value.coordinates.longitude ? value.coordinates.longitude : 76.2489919289233
    let address = await getAddress(lat, lng);
    const loc = {
      coordinates: address.coordinates,
      name: address.address,
      countryCode: address.countryCode,
      city: address.city,
      state: address.state,
      country: address.country,
      type: "Point"
    };
    onCountryChange(loc.country, loc.countryCode, setFieldValue, values)
    setLocation({
      lat: lat,
      lng: lng,
    });
    let pl = { ...placeName }
    pl.placeName = address.address;
    setPlaceName({ ...pl })
    setSelectedLocation(loc);
    setFieldValue('location', loc);
    setIsLocation(true);
  }
  const onCountryChange = (countryName, countryCode, setFieldValue, values) => {
    const country = countries.find((item) => item.shortcode === countryCode);
    if (country) {
      if (values.countryId !== country.value) {
        setFieldValue('countryId', country.value);
        setSelectedCountry(country.name);
        callTaxApi(country.value, setFieldValue)
      }
    } else {
      alert('Could not get country')
    }
  }

  const callTaxApi = (countryId, setFieldValue) => {
    Headless.getTaxList(countryId).then((out) => {
      const t = [];
      if (out.data && out.data.data && out.data.data.data) {
        out.data.data.data.forEach(item => {
          t.push({ label: `${item.taxName}(${item.taxValue}${getTaxType(item.type)})`, value: item._id })
        })
        setTaxes([...t]);
        setSelectedTaxes([]);
        setFieldValue('taxes', [])
      }
    })
      .catch(err => {
        setTaxes([]);
        setSelectedTaxes([]);
        setFieldValue('taxes', []);
      })
  }

  const onSubmit = async (values) => {
    console.log("on submit values are",values);
    if (values.taxType == 1) {
      values.taxes = [];
      values.isPriceIncludeTax = true;
    }else{
      values.isPriceIncludeTax = false;
    }
    let val = { ...values };
    if (remoteUrls.length > 0) {
      setLoading(true);
      let defaultImg;
      if (defaultImage && defaultImage?.file) {
        defaultImg = await handleUpload(defaultImage, true);
      } else if (typeof defaultImage === "undefined") {
        defaultImg = remoteUrls[0]
      } else {
        defaultImg = defaultImage
      }
      let images = [];
      for (const item of remoteUrls) {
        if (item.id === defaultImg.id) {
          images.push({ uri: item.url, isDefault: true });
        } else {
          images.push({ uri: item.url, isDefault: false });
        }
      }
      val.images = images;
      val.defaultImage = { uri: defaultImg.url };
     console.log("values are",val);
     Headless.editStudio(val, id).then(() => {
      setLoading(false);
      setToastMessage({
        variant: 'success',
        message: 'Venue edited successfully!'
      })
      setShowToast(true);
      setTimeout(() => {
        navigate(-1);
      }, 1000)

    })
      .catch(err => {
        setLoading(false);
        setToastMessage({
          variant: 'success',
          message: 'Editing venue failed!'
        })
        setShowToast(true);
      })
    }
  }

  const CustomInputComponent = (props) => (
    <textarea
      className="form-control"
      id="first-name-column"
      type="text"
      rows={6}
      placeholder="Write here"
      {...props}
    ></textarea>
  );
  const onChangeLocationText = async (text) => {
    let resp = await Headless.sarchLocation(text)
    setSearchLocations(resp.data)
  }
  // useEffect(() => {
  //   setIsLocation(true)
  // }, [searchLocations])
  

  const getLocDetails = async (id) => {
    let out = await Headless.getLocationDetails(id);
    out = out.data.data;
    if (out) {
      const loc = {
        coordinates: {
          longitude: out.place.coordinates.lng,
          lattitude: out.place.coordinates.lat
        },
        name: out.place.address,
        countryCode: out.place.countryCode,
        city: out.place.city,
        state: out.place.state,
        country: out.place.country,
        type: "Point"
      };
      return loc;
    }
  };


  const onImageUpload = async (images, isDefaultImageUpload, setFieldValue) => {
    setReset(false);
    let r = [...remoteUrls];
    setLoading(true);
    for (let i = 0; i < images.length; i++) {
      let resp = await handleUpload(images[i], isDefaultImageUpload, setFieldValue);
      r.push(resp);
    }
    setRemoteUrls(r);
    setFieldValue('images', [...r]);
    setLoading(false);
  }

  const handleUpload = async (item, isDefaultImageUpload, setFieldValue) => {
    if (!isDefaultImageUpload) {
      try {
        const resp = await Headless.uploadStudioImages(item.file);
        if (resp && resp.data && resp.data.data && resp.data.data.length > 0) {
          let img = resp.data.data[0];
          return { 'url': img.location, 'id': item.id }
        }
      }
      catch (err) {
        setShowToast(true);
        setToastMessage(
          { variant: 'danger', message: 'Image uploading failed!' }
        )
        return {}
      }
    }
    else {
      try {
        const resp = await Headless.uploadDefaultImage(item.file);
        if (resp && resp.data && resp.data.data) {
          let img = resp.data.data;
          return { ...defaultImage, 'url': img.location, 'id': item.id }
        }
      }
      catch (err) {
        setShowToast(true);
        setToastMessage(
          { variant: 'danger', message: 'Image uploading failed!' }
        )
        return {}
      }
    }
  }

  const onRemoveImage = (id, setFieldValue) => {
    const index = remoteUrls.findIndex((elem) => elem.id === parseInt(id, 10));
    if (index !== -1) {
      let r = [...remoteUrls];
      r.splice(index, 1);
      setRemoteUrls([...r]);
      setFieldValue('images', [...r]);
    }
  }

  const onChangeOwnerText = async (text) => {
    try {
      let resp = await getOwnersList(10, 1, text, {});
      setSearchOwners(resp.data.data.data)
    }
    catch (err) {
      setSearchOwners([]);
    }
  }
  const getTaxType = (type) => {
    switch (type) {
      case 1: return '%'
      case 2: return ''
      default: return ''
    }
  }
  
  const onHighlightSelect = (values, setFieldValue, val) => {
    let h = []
    values.forEach(item => {
      h.push({ _id: item.value });
    })
    setFieldValue('highlightIds', h);
    setSelectedHighlights(values);
  }

  const onTaxSelect = (values, setFieldValue, val) => {
    const t = []
    values.forEach(item => {
      t.push({ _id: item.value });
    })
    setFieldValue('taxes', t);
    setSelectedTaxes(values);
  }


  const onReset = (setFieldValue) => {
    setReset(true);
    parseData('reset');
  }
  if (data === null || initialData === null) {
    return null;
  }
  return (
    <>
      {loading &&
        <div>
          <Loader
            type="TailSpin"
            color="#000"
            height={30}
            width={30}
            className="loader-parent"
          />
        </div>
      }
      <div role="presentation" className='card p-3 my-2 custom-breadcrumbs'>
        <div className='row align-items-center'>
          <div className='p-2' onClick={() => { navigate(-1) }}>
            <img src={backIcon} width={30} height={30} />
          </div>
          <div className='nav-container p-2'>
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="red" onClick={() => { navigate('../../') }} className='custom-breadcrumb-link'>
                Home
              </Link>
              <Link color="inherit" onClick={() => { navigate('../../studios/registeredStudios') }}>
                Registered Studios
              </Link>
              <Typography color="text.primary" className='custom-breadcrumb-p'>Edit Studio</Typography>
            </Breadcrumbs>
            <div className='mt-1'>
              Edit Studio
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 mb-3">
          <div className="card">
            <div className="card-body">
              <div className="text-center mb-3">
                <h5 className="mb-3">Edit Studio</h5>
              </div>

              <div className="row">
                <div className="col-12">
                  <CustomToast variant={toastMessage.variant} message={toastMessage.message} show={showToast} onClose={() => { setShowToast(false) }} />
                  <Formik
                    initialValues={initialData}
                    validationSchema={venueSchema}
                    onSubmit={onSubmit}
                    onReset={onReset}
                  >
                    {({ errors, touched, isSubmitting, values, setFieldValue }) => {
                      // console.log("valuesss>>",values);
                      return (
                        <Form className="form">
                          <div className='d-flex'>
                          <div className="col-md-6 col-12 px-0">
                            <div className="mb-3">
                              <label
                                className="form-label"
                                htmlFor="first-name-column"
                              >
                                Studio Name
                              </label>
                              <Field
                                type="text"
                                id="first-name-column"
                                className="form-control"
                                placeholder="Venue Name"
                                name="studioName"
                              />
                              {errors.studioName && touched.studioName && (
                                <span className="error text-danger">{errors.studioName}</span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6 col-12 px-0">
                            <div className="mb-3">
                              <label
                                className="form-label"
                                htmlFor="first-name-column"
                              >
                                Studio Area
                              </label>
                              <Field
                                type="text"
                                id="first-name-column"
                                className="form-control"
                                placeholder="Enter Venue Area"
                                name="studioArea"
                              />
                              
                            </div>
                          </div>
                          </div>
                         
                          {/* start here */}
                          <div className="col-md-12 col-12">
                            <div className='row'>
                              <div className="col-md-10 col-10 pl-0">
                                <div className="mb-3">
                                <label
                                className="form-label"
                                htmlFor="first-name-column"
                              >
                                Location
                              </label>
                              <Autocomplete
                                className="searchable-input"
                                name="Location"
                                options={searchLocations}
                                getOptionLabel={option => option.placeName}
                                value={placeName}
                                onChange={async (e, value) => {
                                  if (value != null) {
                                    let details = await getLocDetails(value.placeId);
                                    setLocationItem(details, setFieldValue, values);
                                  }
                                }}
                                noOptionsText='Search here'
                                renderInput={params => (
                                  <TextField
                                    {...params}
                                    className="form-control"
                                    name="city"
                                    label="City"
                                    variant="outlined"
                                    fullWidth
                                    onChange={(val) => onChangeLocationText(val.target.value)}
                                  />
                                )}
                              />
                              {touched.location && errors.location && errors.location.name ? (
                                <span className="error text-danger">{errors.location.name}</span>
                              )
                                : errors.countryId && touched.countryId && (
                                  <span class="error text-danger">{errors.countryId}</span>
                                )
                              }
                                </div>
                              </div>
                              <div className="col-md-2 col-2 pr-0">
                              <label
                                className="form-label"
                                htmlFor="first-name-column"
                              >
                                Select from map
                              </label>
                                <div className="mb-3">
                                
                                <button
                                type="button"
                                className="btn filter-btn mr-3 ml-0 waves-effect waves-float waves-light"
                                // disabled={isSubmitting}
                                onClick={()=>setIsLocation(true)}
                              >
                                Location

                              </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* end here */}
                          {/* <div className='col-md-12 col-12 px-0'>
                            <div className="mb-3">
                              <label
                                className="form-label"
                                htmlFor="first-name-column"
                              >
                                Location
                              </label>
                              <Autocomplete
                                className="searchable-input"
                                name="Location"
                                options={searchLocations}
                                getOptionLabel={option => option.placeName}
                                value={placeName}
                                onChange={async (e, value) => {
                                  if (value != null) {
                                    let details = await getLocDetails(value.placeId);
                                    setLocationItem(details, setFieldValue, values);
                                  }
                                }}
                                noOptionsText='Search here'
                                renderInput={params => (
                                  <TextField
                                    {...params}
                                    className="form-control"
                                    name="city"
                                    label="City"
                                    variant="outlined"
                                    fullWidth
                                    onChange={(val) => onChangeLocationText(val.target.value)}
                                  />
                                )}
                              />
                              {touched.location && errors.location && errors.location.name ? (
                                <span className="error text-danger">{errors.location.name}</span>
                              )
                                : errors.countryId && touched.countryId && (
                                  <span class="error text-danger">{errors.countryId}</span>
                                )
                              }
                            </div>
                          </div> */}
                        {isLocation && 
                          <div style={{ height: 400, width: '100%', borderRadius: '5px', overflow: 'hidden' }}>
                              
                            <GoogleMapReact
                              bootstrapURLKeys={{ key: "AIzaSyAnGkqtqlqppCUpIse5BXOW-1CUDr5NpAs" }}
                              defaultCenter={{
                                lat: selectedLocation ? selectedLocation.coordinates.lattitude : 9.960120371862358,
                                lng: selectedLocation ? selectedLocation.coordinates.longitude : 76.2489919289233,
                              }}
                              onClick={(ev)=>{
                                setDraggable(true)
                                setLocationItem({ lat: ev.lat, lng: ev.lng }, setFieldValue,values)}}
                              center={{
                                lat: location ? location.lat : selectedLocation ? selectedLocation.coordinates.lattitude : 9.960120371862358,
                                lng: location ? location.lng : selectedLocation ? selectedLocation.coordinates.longitude : 76.2489919289233,
                              }}
                              defaultZoom={16}
                              draggable={draggable}
                              onChildMouseDown={() => { setDraggable(false) }}
                              onChildMouseUp={() => { setDraggable(true) }}
                              onChildMouseMove={(key, childProps, mouse) => {
                                if (!draggable) {
                                  setLocationItem({ lat: mouse.lat, lng: mouse.lng }, setFieldValue, values)
                                }
                              }}
                            >
                              <div
                                // onClick={() => { setDraggable(false) }}
                                className='selectedMarker'
                                lat={location ? location.lat : selectedLocation ? selectedLocation.coordinates.lattitude : 9.960120371862358}
                                lng={location ? location.lng : selectedLocation ? selectedLocation.coordinates.longitude : 76.2489919289233}
                              />
                            </GoogleMapReact>
                          </div>}
                          <div className='col-md-12 col-12 px-0'>

                          <ImageEditor uploadImage={(images) => { onImageUpload(images, false, setFieldValue) }}
                              removeImage={(id) => { onRemoveImage(id, setFieldValue) }} changeDefaultImage={(d) => {
                                setReset(false);
                                setDefaultImage(d)
                              }} images={remoteUrls} defaultImageIndex={defaultImageIndex} sizeLimit={1048576} reset={reset} />
                            {errors.images && touched.images && (
                              <span className="error text-danger">{errors.images}</span>
                            )}

                          </div>
                          <div className="col-md-12 col-12">
                            <div className='row'>
                              <div className="col-md-6 col-12 pl-0">
                                <div className="mb-3">
                                  <label
                                    className="form-label"
                                    htmlFor="first-name-column"
                                  >
                                    Manager
                                  </label>
                                  <SearchableInput serachResults={searchOwners} placeholder='Search Manager'
                                    setFieldValue={(val) => { setFieldValue('owner', val._id); }} onChange={(val) => { onChangeOwnerText(val) }} value={{ name: data.owner && data.owner.name ? data.owner.name : '' }}  />
                                  {errors.owner && touched.owner && (
                                    <span className="error text-danger">{errors.owner}</span>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-6 col-12">
                                <div className="mb-3">
                                  <label
                                    className="form-label"
                                    htmlFor="first-name-column"
                                  >
                                    About Studio
                                  </label>
                                  <Field
                                    type="text"
                                    className="form-control"
                                    name="description"
                                    as={CustomInputComponent}
                                  />
                                  {errors.description && touched.description && (
                                    <span className="error text-danger">{errors.description}</span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12 col-12">
                            <div className='row'>
                              <div className="col-md-3 col-12 pl-0">
                                <div className="mb-3">
                                  <label
                                    className="form-label"
                                    htmlFor="first-name-column"
                                  >
                                    Email
                                  </label>
                                  <Field
                                    type="text"
                                    id="first-name-column"
                                    className="form-control"
                                    placeholder="Email"
                                    name="email"
                                  />
                                  {errors.email && touched.email && (
                                    <span className="error text-danger">{errors.email}</span>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-3 col-12 pr-0">
                                <div className="mb-3">
                                  <label
                                    className="form-label"
                                    htmlFor="first-name-column"
                                  >
                                    Phone Number
                                  </label>
                                  <Field
                                    type="text"
                                    id="first-name-column"
                                    className="form-control"
                                    placeholder="Phone Number"
                                    name="phone"
                                  />
                                  {errors.phone && touched.phone && (
                                    <span className="error text-danger">{errors.phone}</span>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-3 col-12 pr-0">
                                <div className="mb-3">
                                  <label
                                    className="form-label"
                                    htmlFor="first-name-column"
                                  >
                                    Whatsapp
                                  </label>
                                  <Field
                                    type="text"
                                    id="first-name-column"
                                    className="form-control"
                                    placeholder="Phone Number"
                                    name="whatsapp"
                                  />
                                </div>
                              </div>
                              <div className="col-md-3 col-12 pr-0">
                                <div className="mb-3">
                                  <label
                                    className="form-label"
                                    htmlFor="first-name-column"
                                  >
                                    Website
                                  </label>
                                  <Field
                                    type="text"
                                    id="first-name-column"
                                    className="form-control"
                                    placeholder="Phone Number"
                                    name="website"
                                  />
                                  {errors.phone && touched.phone && (
                                    <span className="error text-danger">{errors.phone}</span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12 col-12">
                            <div className='row'>
                              <div className="col-md-6 col-12 pl-0">
                                <div className="mb-3">
                                  <label
                                    className="form-label"
                                    htmlFor="first-name-column"
                                  >
                                    Highlights
                                  </label>
                                  <div className="selct-min-height-days">
                                    <MultiSelect
                                      options={highlights}
                                      value={selectedHighlights}
                                      onChange={(val) => { onHighlightSelect(val, setFieldValue, values) }}
                                      ClearSelectedIcon={null}
                                      overrideStrings={{
                                        "allItemsAreSelected": "All",
                                        "search": "Search",
                                        "selectAll": "All",
                                        "selectAllFiltered": "All",
                                        "selectSomeItems": "Choose Highlights(s)",
                                      }}
                                      closeOnChangedValue={false}
                                      disableSearch={false}
                                    />
                                  </div>
                                  {errors.highlightIds && touched.highlightIds && (
                                    <span className="error text-danger">{errors.highlightIds}</span>
                                  )}
                                </div>
                              </div>
                              <div className='col-md-6 col-sm-6 col-xs-12 d-flex'>
                              <div className="col-md-6 col-sm-6 col-xs-12">
                              <label
                                className="form-label"
                                htmlFor="first-name-column"
                              >
                                Price including tax ?
                              </label>
                              <div role="group" aria-labelledby="checkbox-group" name="type" className="radio-group">
                                <RadioButton value="1" setFieldValue={() => { setFieldValue('taxType', 1) }} label='Yes' checked={values.taxType === 1 ? true : false} name="taxType" />
                                <RadioButton value="2" setFieldValue={() => { setFieldValue('taxType', 2) }} label='No' checked={values.taxType === 2 ? true : false} name="taxType" />
                              </div>
                            </div>

                            <div className="col-md-6 col-sm-6 col-xs-12">
                              {values.taxType == "2" &&
                               <div className="mb-3">
                               <label
                                 className="form-label"
                                 htmlFor="first-name-column"
                               >
                                 Taxes <b>(if applicable)</b>
                               </label>
                               <div className="selct-min-height-days">
                                 <MultiSelect
                                   options={taxes}
                                   value={selectedTaxes}
                                   onChange={(val) => { onTaxSelect(val, setFieldValue, values) }}
                                   ClearSelectedIcon={null}
                                   overrideStrings={{
                                     "allItemsAreSelected": "All",
                                     "search": "Search",
                                     "selectAll": "All",
                                     "selectAllFiltered": "All",
                                     "selectSomeItems": "Choose Tax(s)",
                                   }}
                                   closeOnChangedValue={false}
                                   disableSearch={false}
                                 />
                               </div>
                               {errors.taxes && touched.taxes &&
                                 (
                                   <span className="error text-danger">Please choose tax</span>
                                 )}
                             </div>
                              
                              }
                             
                            </div>
                              </div>
                              
                            </div>
                          </div>
                          
                          <div className="row mt-5">
                            <div className="col-12">
                              <button
                                type="submit"
                                className="btn filter-btn mr-3 ml-0 waves-effect waves-float waves-light"
                                disabled={isSubmitting}
                              >
                                Submit
                              </button>
                              <button
                                type="reset"
                                className="btn btn-outline-secondary waves-effect"
                                disabled={isSubmitting}
                              >
                                Reset
                              </button>
                            </div>
                          </div>
                        </Form>
                      )
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );


}