import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './Feedbacks.scss';
import * as Headless from "./Feedbacks.headless.js";
import {
  NavLink,
  useParams
} from "react-router-dom";
import { Dropdown, Image } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import Loader from "react-loader-spinner";
import Avatar from 'react-avatar';
import _ from 'lodash';
import dayjs from 'dayjs';
import Sidebar from "react-sidebar";
import { MultiSelect } from "react-multi-select-component";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Pagination from '../components/Pagination';
import { getClubName, getCountryName, getLevelName, getPositionName } from '../utils/functions';
import { customTableStyles } from '../utils/styles'

export function Feedbacks(props) {
  let { id } = useParams();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [total, setTotal] = useState(0);
  const [feedbacks, setFeedbacks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [openFilter, setOpenFilter] = useState(false)
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [email, setEmail] = useState('');
  const [feedback, setFeedback] = useState('');
  const [device, setDEvice] = useState('');
  const [os, setOs] = useState('');
  const [version, setVersion] = useState('');
  const [filters, setFilters] = useState({})

  const columns = [
    {
      name: <th>#</th>,
      selector: (row, index) => index + (limit * (page - 1)) + 1,
      cell: (row, index) =>
        <td>{index + (limit * (page - 1)) + 1}</td>,
      grow: 0.05,
      width: '60px'
    },
    {
      name: <th>FEEDBACK</th>,
      selector: row => row.feedback,
      sortable: true,
      cell: (row) =>

        <NavLink to={`${row._id}`} className="d-flex active-link" style={{ paddingTop: 10, paddingBottom: 10 }}>
          {row.feedback.length > 30 ?
            <span>{row.feedback.substring(0, 30)}... </span> :
            <span>{row.feedback}</span>
          }
        </NavLink>
      ,
      grow: 1.5,
    },
    {
      name: <th>FEEDBACK BY</th>,
      selector: row => row.userId ? row.userId.name : 'N/a',
      sortable: true,
      cell: (row) =>
        <td className="d-flex">
          {row.userId ?
            <NavLink to={`../users/${row.userId?._id}`} className="d-flex" target="_blank">
              {row.userId?.profileImage ?
                <Image src={row.userId.profileImage} width={40} height={40} roundedCircle />
                : <Avatar name={'TEST'} round size={40} />}
              <div className="username d-flex align-items-center">
                <p>{row.userId.name}</p>
              </div>
            </NavLink> :
            <td>{'N/a'}</td>
          }
        </td>,
      grow: 1.5,
    },
    {
      name: <th>EMAIL</th>,
      selector: row => row.email,
      sortable: true,
      cell: (row) =>
        <td>
          <NavLink to={`${row._id}`} className="d-flex" style={{ paddingTop: 10, paddingBottom: 10 }}>
            <td>{row.email}</td>
          </NavLink>
        </td>,
    },
    {
      name: <th>CREATED ON</th>,
      selector: row => row.createdAt,
      sortable: true,
      cell: (row) =>
        <td>{dayjs(row.createdAt).format('DD MMM YYYY hh:mm A')}</td>,
    },
    {
      name: <th>INFO</th>,
      cell: (row) =>
        <td style={{ flexDirection: 'column' }}>
          <tr>App : {row.version ? row.version : 'N/a'}</tr>
          <tr>OS : {row.os ? row.os : 'N/a'}</tr>
          <tr>Device : {row.device ? row.device : 'N/a'}</tr>
        </td>,
    }
  ];

  const getFeedbacks = async (l, p, s, filter) => {
    setLoading(true);
    try {
      const response = await Headless.getFeedbacks(l, p, s, filter);
      setPages(response.data.data.pages);
      setTotal(response.data.data.total);
      setFeedbacks(response.data.data.feedbacks);
      setLoading(false);
    } catch (err) {
      setPages(1);
      setTotal(0);
      setFeedbacks([]);
      setLoading(false);
    }
  }

  const onSearch = _.debounce(setSearch, 500);

  useEffect(() => {
    getFeedbacks(limit, page, search, filters);
  }, [page]);

  useEffect(() => {
    getFeedbacks(limit, 1, search, filters);
  }, [limit, search, filters])

  const onToggleLimit = (e, value) => {
    e.preventDefault();
    setLimit(value);
    setPage(1)
  };

  const onPressFilter = () => {
    let filter = {}
    if (startDate != '' && endDate != '') {
      filter['startDate'] = startDate.toUTCString()
      filter['endDate'] = endDate.toUTCString()
    }

    if (email != '') {
      filter['email'] = email;
    }

    if (feedback != '') {
      filter['content'] = feedback;
    }

    if (device != '') {
      filter['device'] = device;
    }

    if (os != ''){
      filter['os'] = os;
    }

    if (version != '') {
      filter['version'] = version;
    }

    setFilters(filter)
    setOpenFilter(false)
  }

  const onPressClear = () => {
    setStartDate('');
    setEndDate('');
    setEmail('');
    setFeedback('');
    setDEvice('');
    setOs('');
    setVersion('');
    setFilters({});
  }

  function filter() {
    return (
      <div style={{ width: 260, height: '100%' }}>
        {openFilter &&
          <div
            className="filter-close"
            onClick={() => setOpenFilter(false)}
          />
        }
        <div className="ml-3 slider-container">
          <p style={{ fontSize: 20 }} className="mb-0 mt-3">Filter</p>
          <div style={{ width: 100, height: 3, overflow: 'hidden', backgroundColor: 'gray' }}></div>
          <p className="mb-0 mt-3">Created on</p>
          <div className='row ml-0'>
            <div style={{ width: '45%', overflow: 'hidden' }}>
              <DatePicker
                selected={startDate != '' ? startDate: new Date().setMonth(new Date().getMonth() - 1)}
                onChange={(date) => setStartDate(date)}
                dateFormat="d-MMM-yyyy"
                className="date-picker"
                maxDate={endDate ? endDate : new Date()}
              />
            </div>
            <div style={{ width: '45%', overflow: 'hidden', marginLeft: 10 }}>
              <DatePicker
                selected={endDate != '' ? endDate: new Date()}
                onChange={(date) => setEndDate(date)}
                dateFormat="d-MMM-yyyy"
                className="date-picker"
                minDate={startDate ? startDate : new Date().setMonth(new Date().getMonth() - 1)}
              />
            </div>
          </div>
        
          <p className="mb-0 mt-3">Email</p>
          <input
            className="form-control"
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />

          <p className="mb-0 mt-3">Feedback Content</p>
          <input
            className="form-control"
            placeholder="Feedback Content"
            onChange={(e) => setFeedback(e.target.value)}
            value={feedback}
          />

          <p className="mb-0 mt-3">Device</p>
          <input
            className="form-control"
            placeholder="Device"
            onChange={(e) => setDEvice(e.target.value)}
            value={device}
          />

          <p className="mb-0 mt-3">Operating System</p>
          <input
            className="form-control"
            placeholder="Operating System"
            onChange={(e) => setOs(e.target.value)}
            value={os}
          />

          <p className="mb-0 mt-3">App Version</p>
          <input
            className="form-control"
            placeholder="App Version"
            onChange={(e) => setVersion(e.target.value)}
            value={version}
          />  
          
        
          <div className=" ml-1 row">
            <div
              className="filter-submit mt-3"
              onClick={onPressFilter}
            >
              <p className="ml-3 mr-3 mt-3 mb-3">Filter</p>
            </div>
            <div
              className="filter-clear mt-3"
              onClick={onPressClear}
            >
              <p className="ml-3 mr-3 mt-3 mb-3">Clear</p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="card h-100">
      <Sidebar
        pullRight
        sidebar={filter()}
        open={openFilter}
        styles={{
          sidebar:
          {
            background: "white",
            overflow: "visible",
            zIndex: 20
          },
          overlay:
          {
            backgroundColor: "transparent"
          }
        }}
      >
        <div className="card-body">
          <div className="d-flex align-items-center table-header-menu justify-content-between">
            <div className="d-flex align-items-center">
              <p className="mr-3">Show</p>
              <p className="mr-3">{limit}</p>
              <Dropdown>
                <Dropdown.Toggle variant="default" id="dropdown-basic">
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href="#" onClick={(e) => onToggleLimit(e, 10)}>10</Dropdown.Item>
                  <Dropdown.Item href="#" onClick={(e) => onToggleLimit(e, 25)}>25</Dropdown.Item>
                  <Dropdown.Item href="#" onClick={(e) => onToggleLimit(e, 50)}>50</Dropdown.Item>
                  <Dropdown.Item href="#" onClick={(e) => onToggleLimit(e, 100)}>100</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="d-flex align-items-center">
              <p className="mr-3">Search</p>
              <input className="form-control" placeholder="Search" onChange={(e) => onSearch(e.target.value.trim())} />
              <span className="btn filter-btn" onClick={() => setOpenFilter(true)}>
                Filter
                {Object.keys(filters).length > 0 ? 
                  <span>
                    &#9733;
                  </span>
                : null}
              </span>
            </div>
          </div>
          {!loading && feedbacks.length > 0?
              <div className="dataHeader">
                  <p className="mr-3">
                    Showing {page > 1 ? (page - 1) * limit : 1} to {page * limit < total ? page * limit : total} of {total} entries { Object.keys(filters).length > 0 ? `(filtered)`: ''}
                  </p>
              </div>
            :null}
          <div className="table-responsive">
            {loading ?
              <div className="d-flex justify-content-center">
                <Loader
                  type="TailSpin"
                  color="#000"
                  height={30}
                  width={30}
                />
              </div> :
              <DataTable
                columns={columns}
                data={feedbacks}
                customStyles={customTableStyles}
              />
            }
          </div>
        </div>
        {!loading && feedbacks.length > 0?
          <div className="d-flex card-footer align-items-center justify-content-between">
            <p className="page-data-count">
              Showing {page > 1 ? (page - 1) * limit : 1} to {page * limit < total ? page * limit : total} of {total} entries
            </p>
            <div>
              <Pagination pages={pages} current={page} onChange={setPage} />
            </div>
          </div> : null}
      </Sidebar>
    </div>
  );

}