import React from 'react';
import SearchableInput from './searchableInput';
import {getNewCountryList} from '../utils/functions';

const CountryDropdown = ({ onCountrySelect, selectedCountry, disabled, options }) => {
  const countries = getNewCountryList();
  return (
    <SearchableInput serachResults={options ? options : countries} placeholder='Choose Country'
    setFieldValue={(val) => {onCountrySelect(val)}} onChange={(val) => { }} value={selectedCountry} disabled={disabled}/>
  );
};

export default CountryDropdown;