import Geocode from "react-geocode";
const store = require('../store');
const dayjs = require('dayjs');
Geocode.setApiKey("AIzaSyAnGkqtqlqppCUpIse5BXOW-1CUDr5NpAs");

function getPositionName(key) {
  const state = store.default.getState();
  const positions = state.auth.positions;
  const elem = positions.find((it) => it.id === parseInt(key, 10))
  return elem ? elem['en'] : 'N/a';
}

function getPositions() {
  const state = store.default.getState();
  const positions = state.auth.positions;
  let newPositions = positions.map((item) => {
    return {
      label: item.en,
      value: item.id
    }
  })
  return newPositions;
}

function getLevelName(key) {
  const state = store.default.getState();
  const levels = state.auth.levels;
  const elem = levels.find((it) => it.id === parseInt(key, 10))
  return elem ? elem['en'] : 'N/a';
}

function getLevels() {
  const state = store.default.getState();
  const levels = state.auth.levels;
  let newlevels = levels.map((item) => {
    return {
      label: item.en,
      value: item.id
    }
  })
  return newlevels;
}

function getCountryName(key) {
  const state = store.default.getState();
  const countries = state.auth.countries;
  const elem = countries.find((it) => it.id === parseInt(key, 10))
  return elem ? elem['en'] : 'N/a';
}

function getCountryNameByDialcode(key) {
  const state = store.default.getState();
  const countries = state.auth.countries;
  const elem = countries.find((it) => it.mobile_dialcode === key)
  return elem ? elem['en'] : 'N/a';
}

function getCountries() {
  const state = store.default.getState();
  const countries = state.auth.countries;
  let newcountries = countries.map((item) => {
    return {
      label: item.en,
      value: item.id
    }
  })
  return newcountries;
}
function getNewCountryList() {
  const state = store.default.getState();
  const countries = state.auth.newCountryList;
  let newcountries = countries.map((item) => {
    return {
      label: item.en,
      name: item.en,
      value: item._id
    }
  })
  return newcountries;
}

function parseCountryList(countries) {
  let newcountries = countries.map((item) => {
    return {
      label: item.en,
      name: item.en,
      value: item._id,
      shortcode: item.shortcode
    }
  })
  return newcountries;
}

const getNewCountryName = (key) => {
  const state = store.default.getState();
  const countries = state.auth.newCountryList;
  const elem = countries.find((it) => it._id == key)
  return elem ? elem['en'] : 'N/a';
}

function getDialcodes() {
  const state = store.default.getState();
  const countries = state.auth.countries;
  let newcountries = countries.map((item) => {
    return {
      label: item.en,
      value: item.mobile_dialcode
    }
  })
  return newcountries;
}

function getPitch(id) {
  const state = store.default.getState();
  const pitches = state.auth.pitchtypes;
  
  const temp = pitches.find((elem) => elem.id ==id);

  // const temp = pitches.find((elem) => elem.id === parseInt(id, 10));
  return temp ? temp['en'] : 'N/a';
};

function getPitchTypes() {
  const state = store.default.getState();
  const pitches = state.auth.pitchtypes;
  let pitchTypes = pitches.map((item) => {
    return {
      label: item.en,
      value: `${item.id}`
    }
  })
  return pitchTypes;
};

function getClubName(key, fallback) {
  const state = store.default.getState();
  const clubs = state.auth.clubs;
  const elem = clubs.find((it) => it.id === parseInt(key, 10))
  return elem ? elem['en'] : fallback ?? 'N/a';
}
function getClubs() {
  const state = store.default.getState();
  const clubs = state.auth.clubs;
  let newClubs = clubs.map((item) => {
    return {
      name: item.en,
      id: item.id
    }
  })
  return newClubs;
}

function validateNumber(num) {
  const re = /^[0-9\b]+$/;

  if (num === '' || re.test(num)) {
    return true
  } else {
    return false
  }
}

function getBookingStatus(status) {
  switch (status) {
    case 1: return 'Initialized';
    case 2: return 'Pending';
    case 3: return 'Success';
    case 4: return 'Fail';
    case 5: return 'Cancelled';
    case 6: return 'Aborted';
  }
}

function getBookingStatusColor(status) {
  switch (status) {
    case 1: return 'blue';
    case 2: return 'gray';
    case 3: return 'green';
    case 4: return '#FFC133';
    case 5: return 'red';
    case 6: return '#FF7A33';
  }
}

async function getDateAndTime(bookingItem) {
  let bookingDate = bookingItem[0].date;
  let bookingEndDate = bookingItem[0].date;
  let startTime = [];
  let endTime = [];
  if (
    bookingItem.length > 1 &&
    dayjs(bookingItem[0].date) > dayjs(bookingItem[1].date)
  ) {
    bookingDate = bookingItem[1].date;
    bookingEndDate = bookingItem[0].date;
    let array1 = [...bookingItem[1].timeId];
    let array2 = [...bookingItem[0].timeId];
    startTime = await array1.sort((a, b) => {
      return a - b;
    });
    endTime = await array2.sort((a, b) => {
      return a - b;
    });
  } else {
    let array1 = [...bookingItem[0].timeId];
    startTime = await array1.sort((a, b) => {
      return a - b;
    });
    if (bookingItem.length > 1) {
      let array2 = [...bookingItem[1].timeId];
      endTime = await array2.sort((a, b) => {
        return a - b;
      });
      bookingEndDate = bookingItem[1].date;
    } else {
      endTime = startTime;
    }
  }
  return { bookingDate, startTime, endTime, bookingEndDate };
};

async function getAddress(lat, lng) {
  let coordinates = {
    longitude: lng,
    lattitude: lat,
  }
  let address;
  let city, state, country, countryCode;
  let response = await Geocode.fromLatLng(lat, lng)
  address = response.results[0].formatted_address;


  for (let i = 0; i < response.results[0].address_components.length; i++) {
    for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
      switch (response.results[0].address_components[i].types[j]) {
        case "locality":
          city = response.results[0].address_components[i].long_name;
          break;
        case "administrative_area_level_1":
          state = response.results[0].address_components[i].long_name;
          break;
        case "country":
          country = response.results[0].address_components[i].long_name;
          countryCode = response.results[0].address_components[i].short_name;
          break;
      }
    }
  }
  return {
    address: address,
    city: city,
    state: state,
    country: country,
    countryCode: countryCode,
    coordinates: coordinates
  }
}
const getDataForReports = () => {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const last12Months = [];
  const last30Days = [];
  const last30DaysWithMonth = [];
  const last5Year = [];
  const lastMonth = new Date();
  const lastYear = new Date();
  const lastDay = new Date();
  last12Months.push(months[lastMonth.getMonth()])
  last5Year.push(lastYear.getFullYear())
  last30DaysWithMonth.push(months[lastDay.getMonth()] + ' ' + lastDay.getDate());
  last30Days.push(lastDay.getDate());
  for (let i = 1; i < 12; i++) {
    lastMonth.setMonth(lastMonth.getMonth() - 1);
    last12Months.push(months[lastMonth.getMonth()])
  }
  for (let i = 1; i < 5; i++) {
    last5Year.push(lastYear.getFullYear() - i)
  }
  for (let i = 1; i < 30; i++) {
    lastDay.setDate(lastDay.getDate() - 1)
    last30DaysWithMonth.push(months[lastDay.getMonth()] + ' ' + lastDay.getDate())
    last30Days.push(lastDay.getDate())
  }
  return {last12Months, last30Days, last30DaysWithMonth, last5Year}
}
function getTransactionStatus(status) {
  switch (status) {
    case 1: return 'Initialized';
    case 2: return 'Processing';
    case 3: return 'Success';
    case 4: return 'Failed';
    case 5: return 'Canceled';
    case '5': return 'Canceled';
    default: return 'Unknown';
  }
}

function getTransactionStatusColor(status) {
  switch (status) {
    case 1: return 'blue';
    case 2: return '#FF7A33';
    case 3: return 'green';
    case 4: return 'red';
    case 5: return 'grey';
    case '5': return 'grey';
    default: return null;
  }
}

const getRefundStatus = (mode) => {
switch (mode) {
  case 1: return 'Initiated'
  case 2: return 'Failed'
  case 3: return 'Success'
}
}

function getRefundStatusColor(status) {
switch (status) {
  case 1: return '#FF7A33';
  case 3: return 'green';
  case 2: return 'red';
  default: return null;
}
}
export {
  getPositionName,
  getLevelName,
  getCountryName,
  getClubName,
  getPitch,
  getPositions,
  getLevels,
  getCountries,
  getClubs,
  getDialcodes,
  validateNumber,
  getPitchTypes,
  getCountryNameByDialcode,
  getBookingStatus,
  getBookingStatusColor,
  getDateAndTime,
  getAddress,
  getNewCountryList,
  parseCountryList,
  getDataForReports,
  getTransactionStatus,
  getTransactionStatusColor,
  getRefundStatus,
  getRefundStatusColor,
  getNewCountryName,
}