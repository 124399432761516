import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import * as Headless from "./popupBanner.headless";
import { Formik, Field, Form } from "formik";
import Loader from "react-loader-spinner";
import { SingleImageUploader } from "../../components/workout/addWorkout/singleImageUploader";
import { uploadPopupBannerImage } from "./popupBanner.headless";
import CustomDateRangePicker from "../../components/dateRangePicker";
import { useSelector } from "react-redux";
import { format } from "../../utils/dateFunctions";
import GoogleMapReact from "google-map-react";
import MultiSelectDropdown from "../../components/multiSelect";
import CountryDropdown from "../../components/countryDropdown";
import { Autocomplete } from "@material-ui/lab";
import { getAddress, parseCountryList } from "../../utils/functions";
import { getNewCountryList } from "../../slices/auth.slice";
import { TextField } from "@material-ui/core";
import RadioButton from "../../components/radioButton";
import SingleSelect from "../../components/singleSelect";
import SearchableInput from "../../components/searchableInput";
import { getVenueList } from "../../pitchBooking/venue/venue.headless";
const popupBannerSchema = Yup.object().shape({
  name: Yup.string().trim().required("Name Required"),
  duration:  Yup.number()
  .min(1, 'Duration must be greater than zero')
  .required('Duration is required'),
  startDate: Yup.string().trim().required("Start date is required"),
  endDate: Yup.string().trim().required("End date is required"),
  image: Yup.string().required("Image Required"),
  location: Yup.object()
    .shape({
      name: Yup.string(),
    }),
  url: Yup.string().when('urlType', {
    is: (type) => type == "0",
    then: Yup.string().trim().required('Enter Url'),
    otherwise: Yup.string().trim(),
  }),
  urlType: Yup.string().trim().required("Select Url type"),
  tabName: Yup.string().trim(),
  screenName: Yup.string().when('urlType', {
    is: (type) => type == "1",
    then: Yup.string().trim().required('Select screen'),
    otherwise: Yup.string().trim(),
  }),
  id: Yup.string().when('screenName', {
    is: (screenName) => screenName === "PitchDetails" || screenName === "PitchOfferDetails",
    then: Yup.string().required('Select a value'),
    otherwise: Yup.string(),
  }),
});

const CreatePopupBanner = ({
  createPopupBanner,
  data,
  fromEdit,
  onCancel,
  selectedCountry,
  setSelectedCountry,
}) => {
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(fromEdit ? "" : "");
  const [endDate, setEndDate] = useState(fromEdit ? "" : "");
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [countries, setCountries] = useState([]);
  const [searchLocations, setSearchLocations] = useState([]);
  const [placeName, setPlaceName] = useState(null);
  const countryRaw = useSelector(getNewCountryList);

  const [location, setLocation] = useState(null);
  const [draggable, setDraggable] = useState(false);
  const [selectedScreen, setSelectedScreen] = useState([]);
  const screenList = [{ id: 0, name: 'My Teams' }, { id: 1, name: 'My Games' }, { id: 2, name: 'Book a Pitch' }, { id: 3, name: 'Offers' }, { id: 4, name: 'Venue List' }, { id: 5, name: 'Venue Details' }, { id: 6, name: 'Venue Offers' }, { id: 7, name: 'Venue Offer Details' }]
  const [searchVenues, setSearchVenues] = useState([]);
  const [selectedVenue, setSelectedVenue] = useState('');
  const [country, setCountry] = useState({ name: selectedCountry });
  const [imageUploadError, setImageUploadError] = useState(false);
  const [offers, setOffers] = useState([]);
  const [selectedOffer, setSelectedOffer] = useState([]);
  useEffect(() => {
    if (fromEdit) {
      let split1 = data.startDate.split("-");
      let split2 = data.endDate.split("-");
      setStartDate(
        new Date(
          parseInt(split1[0]),
          parseInt(split1[1]) - 1,
          parseInt(split1[2])
        )
      );
      setEndDate(
        new Date(
          parseInt(split2[0]),
          parseInt(split2[1]) - 1,
          parseInt(split2[2])
        )
      );
      const screenIndex = getScreenIndex();
      setSelectedScreen([screenList[screenIndex]]);
      setSelectedVenue({ name: data.chosenValue })
      setSelectedOffer([{name:data.chosenValue}])
    }
  }, [fromEdit]);
  useEffect(() => {
    Headless.getOfferDetails().then(res=>setOffers(res.data))
  }, [])
  const getScreenIndex = () => {
    switch (data.screenName) {
      case 'Home': return 0;
      case 'Workouts': return 1;
      case 'Profile': return 2;
      case 'Events': return 3;
      case 'Offers': return 4;
      case 'Studios': return 5;
      case 'StudioOfferDetails': return 6;
      case 'StudioDetails': return 7;
      case 'EventDetails' : return 8;
      case 'OfferDetails' : return 9;
      case 'WorkoutDetails' : return 10;
      default: return 0;
    }
  }

  const onSubmit = (values) => {
    if (fromEdit) {
      let d = {};
      if (values.name !== data.name) {
        d.name = values.name;
      }
      if (values.duration !== data.duration) {
        d.duration = values.duration;
      }
      if (values.startDate !== data.startDate) {
        d.startDate = values.startDate;
      }
      if (values.endDate !== data.endDate) {
        d.endDate = values.endDate;
      }
      if (values.image !== data.image) {
        d.image = values.image;
      }
      if (values.country !== data.country) {
        d.country = values.country
      }
      if (values.image !== data.image) {
        d.image = values.image;
      }
      if (values.url !== data.url) {
        d.url = values.url;
      }
      if (values.urlType !== data.urlType) {
        d.urlType = values.urlType;
      }
      if (values.id !== data.id) {
        d.id = values.id;
      }
      if (values.tabName !== data.tabName) {
        d.tabName = values.tabName;
      }
      if (values.screenName !== data.screenName) {
        d.screenName = values.screenName;
      }
      if (values.chosenValue !== data.chosenValue) {
        d.chosenValue = values.chosenValue;
      }
      createPopupBanner(d);
    } else {
      let val = { ...values };
      createPopupBanner(val);
    }
  };
  const onCountryChange = (countryName, countryCode, setFieldValue, values) => {
    const country = countries.find((item) => item.shortcode === countryCode);
    if (country) {
      if (values.countryId !== country.value) {
        setFieldValue("country", country.value);
        setSelectedCountry(country.name);
      }
    } else {
      setFieldValue("country", "");
      alert("Could not get country");
    }
  };
  const getLocDetails = async (id) => {
    let out = await Headless.getLocationDetails(id);
    out = out.data.data;
    if (out) {
      const loc = {
        coordinates: {
          longitude: out.place.coordinates.lng,
          lattitude: out.place.coordinates.lat,
        },
        name: out.place.address,
        countryCode: out.place.countryCode,
        city: out.place.city,
        state: out.place.state,
        country: out.place.country,
        type: "Point",
      };
      return loc;
    }
  };
  useEffect(() => {
    setCountries(parseCountryList(countryRaw));
  }, [countryRaw]);

  const setLocationItem = async (value, setFieldValue, values) => {
    let lat = value.lat
      ? value.lat
      : value.coordinates.lattitude
        ? value.coordinates.lattitude
        : 9.960120371862358;
    let lng = value.lng
      ? value.lng
      : value.coordinates.longitude
        ? value.coordinates.longitude
        : 76.2489919289233;
    let address = await getAddress(lat, lng);
    const loc = {
      coordinates: address.coordinates,
      name: address.address,
      countryCode: address.countryCode,
      city: address.city,
      state: address.state,
      country: address.country,
      type: "Point",
    };
    onCountryChange(loc.country, loc.countryCode, setFieldValue, values);
    setLocation({
      lat: lat,
      lng: lng,
    });
    let pl = { ...placeName };
    pl.placeName = address.address;
    setPlaceName({ ...pl });
    setSelectedLocation(loc);
    // setFieldValue("location", loc);
  };
  const onImageUpload = async (images, isDefaultImageUpload, setFieldValue) => {
    setLoading(true);
    for (let i = 0; i < images.length; i++) {
      let resp = await handleUpload(
        images[i],
        isDefaultImageUpload,
        setFieldValue
      );
      // console.log("url is",resp.url)
      setFieldValue("image", resp.url ? resp.url : "");
      setLoading(false);
    }
  };
  const onChangeLocationText = async (text) => {
    let resp = await Headless.sarchLocation(text);
    setSearchLocations(resp.data);
  };
  const handleUpload = async (item, isDefaultImageUpload, setFieldValue) => {
    if (!isDefaultImageUpload) {
      try {
        const resp = await uploadPopupBannerImage(item.file);
        if (resp && resp.data && resp.data.data) {
          let img = resp.data.data;
          setImageUploadError(false);
          return { url: img.location, id: item.id };
        }
      } catch (err) {
        setImageUploadError(true)
        return {};
      }
    }
  };
  const onRemoveImage = (id, setFieldValue) => {
    setFieldValue("image", "");
  };

  const onScreenSelect = (id, setFieldValue) => {
    switch (id) {
      // case 1: {
      //   setFieldValue('tabName', 'HomeTabs');
      //   setFieldValue('screenName', 'HomeTabs');
      //   setFieldValue('id', '');
      //   setFieldValue('chosenValue', '');
      //   break;
      // }
      case 0: {
        setFieldValue('tabName', 'HomeTabs');
        setFieldValue('screenName', 'HomeTabs');
        setFieldValue('id', '');
        setFieldValue('chosenValue', '');
        break;
      }
      case 1: {
        setFieldValue('tabName', 'Workouts');
        setFieldValue('screenName', 'Workouts');
        setFieldValue('id', '');
        setFieldValue('chosenValue', '');
        break;
      }
      case 2: {
        setFieldValue('tabName', 'Profile');
        setFieldValue('screenName', 'Profile');
        setFieldValue('id', '');
        setFieldValue('chosenValue', '');
        break;
      }
      case 3: {
        setFieldValue('tabName', 'Events');
        setFieldValue('screenName', 'Events');
        setFieldValue('id', '');
        setFieldValue('chosenValue', '');
        break;
      }
      case 4: {
        setFieldValue('tabName', 'Offers');
        setFieldValue('screenName', 'Offers');
        setFieldValue('id', '');
        setFieldValue('chosenValue', '');
        break;
      }
      case 5: {
        setFieldValue('tabName', 'Studios');
        setFieldValue('screenName', 'Studios');
        setFieldValue('id', '');
        setFieldValue('chosenValue', '');
        break;
      }
      case 6: {
        setFieldValue('tabName', 'StudioOfferDetails');
        setFieldValue('screenName', 'StudioOfferDetails');
        setFieldValue('id', '');
        setFieldValue('chosenValue', '');
        break;
      }
      case 7: {
        setFieldValue('tabName', 'StudioDetails');
        setFieldValue('screenName', 'StudioDetails');
        setFieldValue('id', '');
        setFieldValue('chosenValue', '');
        break;
      }
      case 8: {
        setFieldValue('tabName', 'EventDetails');
        setFieldValue('screenName', 'EventDetails');
        setFieldValue('id', '');
        setFieldValue('chosenValue', '');
        break;
      }
      case 9: {
        setFieldValue('tabName', 'OfferDetails');
        setFieldValue('screenName', 'OfferDetails');
        setFieldValue('id', '');
        setFieldValue('chosenValue', '');
        break;
      }
      case 10: {
        setFieldValue('tabName', 'WorkoutDetails');
        setFieldValue('screenName', 'WorkoutDetails');
        setFieldValue('id', '');
        setFieldValue('chosenValue', '');
        break;
      }
    }
  }

  const onChangeVenueText = async (text) => {
    try {
      let resp = await getVenueList(10, 1, text, {});
      setSearchVenues(resp.data.data.data)
    }
    catch (err) {
      setSearchVenues([])
    }
  }

  return (
    <div>
      {loading && (
        <div>
          <Loader
            type="TailSpin"
            color="#000"
            height={30}
            width={30}
            className="loader-parent"
          />
        </div>
      )}
      <div className="card-body">
        <Formik
          initialValues={data}
          validationSchema={popupBannerSchema}
          onSubmit={onSubmit}
          validateOnBlur={false}
          validateOnChange={false}
          onReset={onCancel}
        >
          {({
            errors,
            touched,
            isSubmitting,
            values,
            setFieldValue,
            handleSubmit,
          }) => {
            return (
              <Form className="form">
                <div className="row">
                <div className="mb-1 mt-1 col-md-6">
                  <label className="form-label" htmlFor="first-name-column">
                    Title
                  </label>
                  <Field
                    type="text"
                    id="first-name-column"
                    className="form-control"
                    placeholder="Enter banner name"
                    name="name"
                  />
                  {errors.name && touched.name && (
                    <span class="error text-danger">{errors.name}</span>
                  )}
                </div>
                <div className="mb-1 mt-1 col-md-6">
                  <label className="form-label" htmlFor="first-name-column">
                    Banner duration (In Seconds)
                  </label>
                  <Field
                    type="number"
                    id="first-name-column"
                    className="form-control no-spinners"
                    placeholder="Enter banner duration"
                    name="duration"
                  />
                  {errors.duration && touched.duration && (
                    <span class="error text-danger">{errors.duration}</span>
                  )}
                </div>
                </div>
                
                
                <div className="col-xl-12 col-12 px-0">
                  <CustomDateRangePicker
                    startDate={startDate}
                    endDate={endDate}
                    setStartDate={(d) => {
                      setStartDate(d);
                      setFieldValue("startDate", format(d, "YYYY-MM-DD"));
                    }}
                    setEndDate={(d) => {
                      setEndDate(d);
                      setFieldValue("endDate", format(d, "YYYY-MM-DD"));
                    }}
                    label="Date Range"
                    showStartError={
                      errors.startDate && touched.startDate ? true : false
                    }
                    startError={
                      errors.startDate && touched.startDate
                        ? errors.startDate
                        : ""
                    }
                    showEndError={
                      errors.endDate && touched.endDate ? true : false
                    }
                    endError={
                      errors.endDate && touched.endDate ? errors.endDate : ""
                    }
                  />
                </div>
                <div class="col-md-12 col-sm-12 col-xs-12 pl-0">
                  <label
                    className="form-label"
                    htmlFor="first-name-column"
                  >
                    Url type
                  </label>
                  <div role="group" aria-labelledby="checkbox-group" name="urlType" className="radio-group">
                    <RadioButton value="0" setFieldValue={() => { setFieldValue('urlType', '0') }} label='Public' checked={values.urlType == '0' ? true : false} name="urlType" />
                    <RadioButton value="1" setFieldValue={() => { setFieldValue('urlType', '1') }} label='In App' checked={values.urlType == '1' ? true : false} name="urlType" />
                  </div>
                </div>
                {values.urlType == 0 &&
                  <div className="mb-1 mt-1 col-md-12 col-sm-12 col-xs-12 pl-0">
                    <label className="form-label" htmlFor="first-name-column">
                      Url
                    </label>
                    <Field
                      type="text"
                      id="first-name-column"
                      className="form-control"
                      placeholder="Enter Url here"
                      name="url"
                    />
                    {errors.url && touched.url && (
                      <span class="error text-danger">{errors.url}</span>
                    )}
                  </div>
                }
                <div className="row">
                  {
                    values.urlType == 1 &&
                    <div className="col-md-6 col-12">
                      <div className="mb-3">
                        <SingleSelect label='Choose Screen' options={screenList} selectedValues={selectedScreen} placeholder='Select Screen' setFieldValue={(val) => {
                          onScreenSelect(val.id, setFieldValue);
                          setSelectedScreen([val])
                        }} fieldName="name" />
                        {errors.screenName && touched.screenName && (
                          <span className="error text-danger">{errors.screenName}</span>
                        )}
                      </div>
                      {errors.screenName && touched.screenName && (
                        <span class="error text-danger">{errors.screenName}</span>
                      )}
                    </div>
                  }
                  {values.screenName === 'PitchDetails' && values.urlType == 1 &&
                    <div className="col-md-6 col-12">
                      <label className="form-label" htmlFor="first-name-column">
                        Choose Venue
                      </label>
                      <SearchableInput serachResults={searchVenues} placeholder='Search Venue' value={selectedVenue}
                        setFieldValue={(val) => { setSelectedVenue(val); setFieldValue('id', val._id); setFieldValue('chosenValue', val.name) }} onChange={(val) => { onChangeVenueText(val) }} />
                      {errors.id && touched.id && (
                        <span class="error text-danger">{errors.id}</span>
                      )}
                    </div>
                  }
                   {values.screenName === 'PitchOfferDetails' && values.urlType == 1 &&
                  <div className="col-md-6 col-12">
                    <label className="form-label" htmlFor="first-name-column">
                    Choose Offer
                  </label>
                    
                  <SingleSelect label='' options={offers} selectedValues={selectedOffer} placeholder="Select Offer" setFieldValue={(val) => { 
                        // onScreenSelect(val.id, setFieldValue);
                        // setFieldValue('chosenValue', val.name)
                        setFieldValue("id",val._id)
                        setFieldValue('chosenValue', val.name)
                        setSelectedOffer([val])
                      }} fieldName="name" />
                      {errors.screenName && touched.screenName && (
                        <span className="error text-danger">{errors.screenName}</span>
                      )}
                  </div>
                }
                </div>
                <div className="col-md-12 col-12 px-0">
                  {/* <div className="mb-3">
                  <label className="form-label" htmlFor="first-name-column">
                    Location
                  </label>
                  <Autocomplete
                    className="searchable-input"
                    name="Location"
                    options={searchLocations}
                    getOptionLabel={(option) => option.placeName}
                    value={placeName}
                    onChange={async (e, value) => {
                      if (value != null) {
                        let details = await getLocDetails(value.placeId);
                        setLocationItem(details, setFieldValue, values);
                      }
                    }}
                    noOptionsText="Search here"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="form-control"
                        name="city"
                        label="City"
                        variant="outlined"
                        fullWidth
                        onChange={(val) =>
                          onChangeLocationText(val.target.value)
                        }
                      />
                    )}
                  />
                  {touched.location &&
                    errors.location &&
                    errors.location.name ? (
                    <span className="error text-danger">
                      {errors.location.name}
                    </span>
                  ) : (
                    errors.country &&
                    touched.country && (
                      <span class="error text-danger">{errors.country}</span>
                    )
                  )}
                </div> */}
                  {/* <CountryDropdown/> */}
                  <div className="mb-1 mt-1">
                    <label
                      className="form-label"
                      htmlFor="first-name-column"
                    >
                      Country
                    </label>
                    <CountryDropdown onCountrySelect={(val) => { setFieldValue('country', val.value); setCountry(val) }} selectedCountry={country} disabled={false} />
                    {/* {errors.country && touched.country && (
                      <span class="error text-danger">{errors.country}</span>
                    )} */}
                  </div>
                </div>
                      
                {/* <div
                style={{
                  height: 400,
                  width: "100%",
                  borderRadius: "5px",
                  overflow: "hidden",
                }}
              >
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: "AIzaSyAnGkqtqlqppCUpIse5BXOW-1CUDr5NpAs",
                  }}
                  defaultCenter={{
                    lat: selectedLocation
                      ? selectedLocation.coordinates.lattitude
                      : 9.960120371862358,
                    lng: selectedLocation
                      ? selectedLocation.coordinates.longitude
                      : 76.2489919289233,
                  }}
                  center={{
                    lat: location
                      ? location.lat
                      : selectedLocation
                        ? selectedLocation.coordinates.lattitude
                        : 9.960120371862358,
                    lng: location
                      ? location.lng
                      : selectedLocation
                        ? selectedLocation.coordinates.longitude
                        : 76.2489919289233,
                  }}
                  defaultZoom={16}
                  onClick={(val) => {
                    setDraggable(true);
                  }}
                  draggable={draggable}
                  onChildMouseDown={() => {
                    setDraggable(false);
                  }}
                >
                  <div
                    onClick={() => {
                      setDraggable(false);
                    }}
                    className="selectedMarker"
                    lat={
                      location
                        ? location.lat
                        : selectedLocation
                          ? selectedLocation.coordinates.lattitude
                          : 9.960120371862358
                    }
                    lng={
                      location
                        ? location.lng
                        : selectedLocation
                          ? selectedLocation.coordinates.longitude
                          : 76.2489919289233
                    }
                  />
                </GoogleMapReact>
              </div> */}

                <div className="col-md-12 col-12 px-0">
                  <SingleImageUploader
                    uploadImage={(images) => {
                      onImageUpload(images, false, setFieldValue);
                    }}
                    removeImage={(id) => {
                      onRemoveImage(id, setFieldValue);
                    }}
                    images={
                      fromEdit
                        ? data.image === ""
                          ? []
                          : [{ path: data.image }]
                        : []
                    }
                    sizeLimit={204800}
                    reset={imageUploadError}
                  />
                  {imageUploadError 
                  ? <span className="error text-danger">Image uploading failed...Please try again!</span>
                  : errors.image && touched.image ? (
                    <span className="error text-danger">{errors.image}</span>
                  )
                  : <span />
                  }
                </div>
              
                <div className="row mt-3">
                  <div className="col-12">
                    <button
                      type="submit"
                      className="btn filter-btn mr-3 ml-0 waves-effect waves-float waves-light"
                      disabled={isSubmitting}
                    >
                      Submit
                    </button>  
                    <button
                      type="reset"
                      className="btn btn-outline-secondary waves-effect"
                      disabled={isSubmitting}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </Form>
            )
          }}
        </Formik>
      </div>
    </div>
  );
};

export default CreatePopupBanner;
