import React, { useState,useEffect } from 'react';
import Chart from "react-google-charts";
import {useNavigate, NavLink} from "react-router-dom";
import Loader from "react-loader-spinner";
import './Dashboard.scss';
import * as Headless from "./Dashboard.headless.js"
import GameIcon from '../../assets/svg/gamesPlayed';
import TeamIcon from '../../assets/svg/profileTeamIcon';
import DashbordUserIcon from '../../assets/svg/DashbordUserIcon';

export function Dashboard() {
    const [counts, setCounts] = useState(null);
    const [loading, setLoading] = useState(true);
    const [mapData, setMapData] = useState([['Country','Users']]);
    let history = useNavigate();

    const getCounts = async() =>{
        setLoading(true);
        try {
            const response = await Headless.getCounts();
            setCounts(response.data.data);
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    }

    const getMap = async() => {
        setLoading(true);
        try {
            const response = await Headless.getMapData();
            if (response.data.data) {
                let mapItems = response.data.data.map((item)=>{
                    return([item.country, item.total])
                });
                setMapData([['Country','Users'],...mapItems]);
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    }

    useEffect(async()=>{
        getCounts()
        getMap()
    },[]);

    const routeChange = (root)=> {
        history(root);
    }
    
    return (
        <>
            {loading ?  
                <div className="d-flex justify-content-center">
                    <Loader
                        type="TailSpin"
                        color="#000"
                        height={30}
                        width={30}
                    />
                </div> 
            :
                <div className="align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <div className="row">
                        <div className="col-md-8 mb-3">
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="mb-3">App Users</h5>
                                    <Chart
                                        width={'100%'}
                                        height={'424px'}
                                        chartType="GeoChart"
                                        data={mapData}
                                        mapsApiKey="AIzaSyAnGkqtqlqppCUpIse5BXOW-1CUDr5NpAs"
                                        rootProps={{ 'data-testid': '1' }}
                                        options={{
                                            colors: ['#A44b7b']
                                        }}
                                    />
                                    
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="row">
                                <div className="col-md-12 mb-3" onClick={()=> routeChange('users/')}>
                                    <div className="card">
                                        <div className="card-body">
                                            <DashbordUserIcon width={45} height={45} />
                                            <h3 className="mt-2"><b>{counts?.totalUsers ? counts.totalUsers : 0}</b></h3>
                                            <p className="mb-0">Total Users</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 mb-3" onClick={()=> routeChange('teams/')}>
                                    <div className="card">
                                        <div className="card-body">
                                            <TeamIcon width={45} height={45} />
                                            <h3 className="mt-2"><b>{counts?.totalOwners ? counts.totalOwners : 0}</b></h3>
                                            <p className="mb-0">Total Owners</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 mb-3" onClick={()=> routeChange('games/')}>
                                    <div className="card">
                                        <div className="card-body">
                                            <GameIcon width={45} height={45} />
                                            <h3 className="mt-2"><b>{counts?.totalAdmins ? counts.totalAdmins : 0}</b></h3>
                                            <p className="mb-0">Total Admins</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );

}