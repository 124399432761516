import React,{useEffect, useState} from 'react';
import './addStudio.scss';
import * as Yup from 'yup';
import { Formik, Field, Form } from 'formik';
import 'react-phone-number-input/style.css'
import PhoneInputField from '../../components/phoneInput';
import { SingleImageUploader } from '../../components/workout/addWorkout/singleImageUploader';
import {uploadAdminImage} from './AddStudio.headless'
import Loader from 'react-loader-spinner';
import axios from 'axios';
import SingleSelect from '../../components/singleSelect';

const subAdminSchema = Yup.object().shape({
  name: Yup.string().trim().required('Name Required'),
  ownerName: Yup.string().trim().required('Owner name is Required'),
  email: Yup.string().trim().email('Invalid email').required('Email Required'),
  mobile: Yup.string().trim().min(7).max(15).required('Mobile Number Required'),
  location: Yup.string().trim().required('Location Required'),
  pinCode: Yup.string().trim().required('Pincode is Required'),
});

export default function AddStudio({ createUser, data, fromEdit,onCancel }) {
// console.log("data is",data)
const statusess = [{ value: 1, status: 'Initialized' }, { value: 2, status: 'Connected' }, { value: 3, status: 'Joined' },{ value: 4, status: 'Rejected' },{ value: 5, status: 'Deleted' },];
const [selectedStatus, setSelectedStatus] = useState({ value: 1, status: 'Initialized' });
const [statuses,setStatuses] = useState(statusess);
const onStatusSelect = async (val) => {
  setSelectedStatus(val)
}
useEffect(() => {
  let stat = data.status;
  let selectedStat = statusess.find(status => status.value === stat);
  console.log("selected status", selectedStat);
  setSelectedStatus(selectedStat)

  
}, [fromEdit])



const [pinCode, setPinCode] = useState('');
const [locationData, setLocationData] = useState({
  area: '',
  district: '',
  state: '',
  country: ''
});
const handlePinCodeChange = (e) => {
  setPinCode(e.target.value);
};
const fetchLocationData = async () => {
  if (pinCode) {
    try {
      const countryCode = 'in'; // Adjust this based on your needs
      const response = await axios.get(`http://api.zippopotam.us/${countryCode}/${pinCode}`);
      const data = response.data;
      setLocationData({
        area: data.places[0]['place name'],
        district: data.places[0]['county'],
        state: data.places[0]['state'],
        country: data.country
      });
    } catch (error) {
      console.error('Error fetching location data:', error);
      setLocationData({
        area: '',
        district: '',
        state: '',
        country: ''
      });
    }
  }
};
// useEffect(() => {
//   console.log("LocationData",locationData);
// }, [locationData])

const [loading, setLoading] = useState(false);
  const onSubmit = (values) => {
    if(fromEdit) {
      let d = {};
      if(values.name !== data.name) {
        d.name = values.name
      }
      if(values.ownerName !== data.ownerName) {
        d.ownerName = values.ownerName
      }
      if(values.email !== data.email) {
        d.email = values.email
      }
      if(values.mobile !== data.mobile) {
        d.mobile = values.mobile
      }
      if(values.location !== data.location) {
        d.location = values.location
      }
      if(values.status !== data.status) {
        d.status = values.status
      }
      if(values.address !== data.address) {
        d.address = values.address
      }
      if(values.state !== data.state) {
        d.state = values.state
      }
      if(values.pinCode !== data.pinCode) {
        d.pinCode = values.pinCode
      }
      if(values.district !== data.district) {
        d.district = values.district
      }

      console.log("edit val is/",d)
      createUser(d)
    } else {
      let val = {...values};
      console.log("val is",val)
      createUser(val)
    }
  }
  


  
  return (
    <div>
       {loading &&
        <div>
          <Loader
            type="TailSpin"
            color="#000"
            height={30}
            width={30}
            className="loader-parent"
          />
        </div>
      }
      <div className='card-body'>
        <Formik
          initialValues={{
            name: data.name,
            email: data.email,
            mobile: data.mobile,
            location: data.location,
            status: data.status,
            ownerName : data.ownerName,
            pinCode:data.pinCode,
            district:data.district,
            state:data.state
          }}
          validationSchema={subAdminSchema}
          onSubmit={onSubmit}
          validateOnBlur={false}
          validateOnChange={false}
          onReset={onCancel}
        >
          {({ errors, touched, isSubmitting, values, setFieldValue, handleSubmit }) => (
            <Form className="form">

              <div className="mb-1 mt-1">
                <label
                  className="form-label"
                  htmlFor="first-name-column"
                >
                  Studio Name
                </label>
                <Field
                  type="text"
                  id="first-name-column"
                  className="form-control"
                  placeholder="Enter studio name"
                  name="name"
                />
                {errors.name && touched.name && (
                  <span class="error text-danger">{errors.name}</span>
                )}
              </div>
              <div className="mb-1 mt-1">
                <label
                  className="form-label"
                  htmlFor="first-name-column"
                >
                  Owner Name
                </label>
                <Field
                  type="text"
                  id="first-name-column"
                  className="form-control"
                  placeholder="Enter owner name"
                  name="ownerName"
                />
                {errors.ownerName && touched.ownerName && (
                  <span class="error text-danger">{errors.ownerName}</span>
                )}
              </div>
              <div className="mb-1 mt-1">
                <label
                  className="form-label"
                  htmlFor="first-name-column"
                >
                  Email
                </label>
                <Field
                  type="text"
                  id="first-name-column"
                  className="form-control"
                  placeholder="Ente mail id"
                  name="email"
                />
                {errors.email && touched.email && (
                  <span class="error text-danger">{errors.email}</span>
                )}
              </div>
              <div className="mb-1 mt-1">
                <label
                  className="form-label"
                  htmlFor="first-name-column"
                >
                  Mobile Number(with country code)
                </label>
                <Field
                  // type='tel'
                  name="mobile"
                  onChange={(val) => { setFieldValue('mobile', val); }}
                  component={PhoneInputField}
                />
                {errors.mobile && touched.mobile && (
                  <span class="error text-danger">{errors.mobile}</span>
                )}
              </div>
              <div className="mb-1 mt-1">
                <label
                  className="form-label"
                  htmlFor="first-name-column"
                >
                  Location
                </label>
                <Field
                  type="text"
                  id="first-name-column"
                  className="form-control"
                  placeholder="Enter location"
                  name="location"
                />
                {errors.location && touched.location && (
                  <span class="error text-danger">{errors.location}</span>
                )}
              </div>
              <div className="mb-1 mt-1">
                <label
                  className="form-label"
                  htmlFor="first-name-column"
                >
                  Address
                </label>
                <Field
                  type="text"
                  id="first-name-column"
                  className="form-control"
                  placeholder="Enter address"
                  name="address"
                />
              </div>
              <div className="mb-1 mt-1">
                <label
                  className="form-label"
                  htmlFor="first-name-column"
                >
                  Pin code
                </label>
                <Field
                  type="text"
                  id="first-name-column"
                  className="form-control"
                  placeholder="Enter pin code"
                  name="pinCode"
                  // onChange={handlePinCodeChange} 
                  // onBlur={fetchLocationData} 
                />
                 {errors.pinCode && touched.pinCode && (
                  <span class="error text-danger">{errors.pinCode}</span>
                )}
              </div>
              <div className="mb-1 mt-1">
                <label
                  className="form-label"
                  htmlFor="first-name-column"
                >
                  District
                </label>
                <Field
                  type="text"
                  id="first-name-column"
                  className="form-control"
                  placeholder="Enter district"
                  name="district"
                  // onChange={handlePinCodeChange} 
                  // onBlur={fetchLocationData} 
                />
              </div>
              <div className="mb-1 mt-1">
                <label
                  className="form-label"
                  htmlFor="first-name-column"
                >
                  State
                </label>
                <Field
                  type="text"
                  id="first-name-column"
                  className="form-control"
                  placeholder="Enter state"
                  name="state"
                  // onChange={handlePinCodeChange} 
                  // onBlur={fetchLocationData} 
                />
              </div>
              <div className="mb-1 mt-1">
              <SingleSelect label='Status' options={statuses} selectedValues={selectedStatus === "" ? [] : [selectedStatus]} placeholder='Choose Status' setFieldValue={(val) => { setFieldValue('status', val.value); onStatusSelect(val) }} fieldName="status"/>
                   
              </div>

            
            
              {/* <div className="mb-1 mt-1">
                <label>
                  <Field type="checkbox" name="status" value={values.status} checked={values.status === 0 ? false : true} onChange={() => { values.status === 0 ? setFieldValue('status', 1) : setFieldValue('status', 0) }} />
                  <span style={{marginLeft: 5}}>Enable this admin</span>
                </label>
              </div> */}
              <div className="row mt-3">
                <div className="col-12">
                  <button
                    type="submit"
                    className="btn filter-btn mr-3 ml-0 waves-effect waves-float waves-light"
                    disabled={isSubmitting}
                  >
                    Submit
                  </button>
                  <button
                    type="reset"
                    className="btn btn-outline-secondary waves-effect"
                    disabled={isSubmitting}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}