import React, { useState, useEffect } from 'react';
import './AdminLogs.scss';
import * as Headless from "./AdminLogs.headless";
import {NavLink, useNavigate,
} from "react-router-dom";
import { Dropdown, Button} from 'react-bootstrap';
import DatePicker from "react-datepicker";
import dayjs from 'dayjs';
import Loader from "react-loader-spinner";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import _ from 'lodash';
import Sidebar from "react-sidebar";
import DataTable from 'react-data-table-component';
import Pagination from '../../components/Pagination';
import { customTableStyles } from '../../utils/styles'
import actionIcon from '../../../assets/svg/actionIcon.svg';
import CustomToast from '../../components/CustomToast';
import ConfirmPopup from '../../components/confirmPopup';
import { useSelector } from 'react-redux';
import { user } from '../../slices/auth.slice';
import Swal from 'sweetalert2';
import {format} from '../../utils/dateFunctions';
import SearchableInput from '../../components/searchableInput';
export function AdminLogs() {
  const navigate = useNavigate();
  const me = useSelector(user);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [openFilter, setOpenFilter] = useState(false)
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [filters, setFilters] = useState({})
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState({variant:'success', message: 'Manager created successfully!'});
  const [chosenId, setChosenId] = useState('');
  const [data,setData] = useState([])
  const [searchAdmins, setSearchAdmins] = useState([]);
  const [selectedAdmin, setSelectedAdmin] = useState('');
  
  const [editPressed, setEditPressed] = useState(false);
  const columns = [
    {
      name: <th>#</th>,
      selector: (row, index) => index + (limit * (page - 1)) + 1,
      cell: (row, index) =>
        <td className="d-flex">{index + (limit * (page - 1)) + 1}</td>,
      grow: 0.05,
      width: '60px'
    },
    {
      name: <th>NAME</th>,
      selector: (row, index) => row.adminId.name.toLowerCase(),
      cell: (row, index) =>
        <td style={{ maxWidth: 150 }}>
          <NavLink exact to={row.adminId._id ? `/management/subAdmins/${row.adminId._id}` : '#'} className="active-link">
            {row.adminId.name}
          </NavLink>
        </td>,
      sortable: true,
    },
    {
      name: <th>ACTIVITY TYPE</th>,
      selector: row => row.activityType ? row.activityType : 'N/a',
      cell: (row) =>
        <td>{row.activityType}</td>,
      sortable: true,
      grow: 1.5,
    },
    {
      name: <th>ACTIVITY ACTION</th>,
      selector: (row, index) => row.action.toLowerCase(),
      cell: (row, index) =>
        <td style={{ maxWidth: 200 }}>
          {row.action ?
            <OverlayTrigger trigger="hover" placement="bottom" overlay={<Popover id="popover-basic">
              <Popover.Content>
                {row.action}
              </Popover.Content>
            </Popover>}>
              <Button className="popOverButton">{row.action}</Button>
            </OverlayTrigger>
            : 'NA'
          }
        </td>,
      sortable: true,
    },
    {
      name: <th>ACTIVITY DATE</th>,
      selector: (row, index) => dayjs(row.timestamp),
      cell: (row, index) =>
        <td>{dayjs(row.timestamp).format('DD MMM YYYY')} <br/>{dayjs(row.timestamp).format('hh:mm A')} </td>,
      sortable: true,
      // hide: Media.SM
    }
  ];
  const getLogs = async (l, p, s, filter) => {
    setLoading(true);
    try {
      const response = await Headless.getLogs(l, p, s, filter);
      console.log("logs are",response.data.data)
      setPages(response.data.data.pages);
      setTotal(response.data.data.total);
      setData(response.data.data.data);
      setLoading(false);
    } catch (err) {
      setPages(1);
      setTotal(0);
      setData([]);
      setLoading(false);
    }
  }


  const navigateToOwners = (ownerId, ownerName) => {
    navigate('../../owners/registeredOwners', { state: { id: ownerId, name: ownerName } })
  }


  const onSearch = _.debounce(setSearch, 500);

  useEffect(() => {
    getLogs(limit, page, search, filters);
  }, [page]);

  useEffect(() => {
    getLogs(limit, 1, search, filters);
  }, [limit, search, filters]) 

  const onToggleLimit = (e, value) => {
    e.preventDefault();
    setPage(1)
    setLimit(value);
  };

  const onPressFilter = () => {
    let filter = {}
    if (startDate != '' && endDate != '') {
      filter['startDate'] = format(startDate, 'YYYY-MM-DD');
      filter['endDate'] = format(endDate, 'YYYY-MM-DD')
    }

    if(selectedAdmin){
      console.log("selecdted admin is",selectedAdmin)
      filter['adminId'] = selectedAdmin._id;
    }

    setFilters(filter)
    setOpenFilter(false)
  }

  const onPressClear = () => {
    setStartDate('')
    setEndDate('')
    setSelectedAdmin("")
    setSelectedStatus('')
    setFilters({});
  }
  const filterClicked = () => {
    console.log("filter clicked")
      setOpenFilter(true) 
  }

  const handleRadio = (value) => {
    if (value != selectedStatus) {
      setSelectedStatus(value)
    } else {
      setSelectedStatus('')
    }
  }
  const onChangeAdminText = async (text) => {
    try {
      let resp = await Headless.getAdminsListItems(10, 1, text, {});
      setSearchAdmins(resp.data.data.data)
    }
    catch (err) {
      setSearchAdmins([])
    }
  }
  function filter() {
    return (
      <div style={{ width: 260, height: '100%' }}>
        {openFilter &&
          <div
            className="filter-close"
            onClick={() => setOpenFilter(false)}
          />
        }
        <div className="ml-3 slider-container">
          <p style={{ fontSize: 20 }} className="mb-0 mt-3">Filter</p>
          <div style={{ width: 100, height: 3, overflow: 'hidden', backgroundColor: 'gray' }}></div>
          <p className="mb-0 mt-3">Owner Name</p>
          <div className='mt-3'>
            <SearchableInput serachResults={searchAdmins} placeholder='Search Owner' value={selectedAdmin}
              setFieldValue={(val) => { setSelectedAdmin(val); }} onChange={(val) => { onChangeAdminText(val) }} />
          </div>
          <p className="mb-0 mt-3">Date Range</p>
          <div className='row ml-0'>
            <div style={{ width: '45%', overflow: 'hidden' }}>
              <DatePicker
                selected={startDate != '' ? startDate : new Date().setMonth(new Date().getMonth() - 1)}
                onChange={(date) => setStartDate(date)}
                dateFormat="d-MMM-yyyy"
                className="date-picker"
                maxDate={endDate ? endDate : new Date()}
              />
            </div>
            <div style={{ width: '45%', overflow: 'hidden', marginLeft: 10 }}>
              <DatePicker
                selected={endDate != '' ? endDate : new Date()}
                onChange={(date) => setEndDate(date)}
                dateFormat="d-MMM-yyyy"
                className="date-picker"
                minDate={startDate ? startDate : new Date().setMonth(new Date().getMonth() - 1)}
              />
            </div>
          </div>
          <div className=" ml-1 row">
            <div
              className="filter-submit mt-3"
              onClick={onPressFilter}
            >
              <p className="ml-3 mr-3 mt-3 mb-3">Filter</p>
            </div>
            <div
              className="filter-clear mt-3"
              onClick={onPressClear}
            >
              <p className="ml-3 mr-3 mt-3 mb-3">Clear</p>
            </div>
          </div>
        </div>
      </div>
    )
  }
  // function filter() {
  //   return (
  //     <div style={{ width: 260, height: '100%' }}>
  //       {openFilter &&
  //         <div
  //           className="filter-close"
  //           onClick={() => setOpenFilter(false)}
  //         />
  //       }
  //       <div className="ml-3 slider-container">
  //         <p style={{ fontSize: 20 }} className="mb-0 mt-3">Filter</p>
  //         <div style={{ width: 100, height: 3, overflow: 'hidden', backgroundColor: 'gray' }}></div>
  //         <p className="mb-0 mt-3">Date Range</p>
  //         <div className='row ml-0'>
  //           <div style={{ width: '45%', overflow: 'hidden' }}>
  //             <DatePicker
  //               selected={startDate != '' ? startDate : new Date().setMonth(new Date().getMonth() - 1)}
  //               onChange={(date) => setStartDate(date)}
  //               dateFormat="d-MMM-yyyy"
  //               className="date-picker"
  //               maxDate={endDate ? endDate : new Date()}
  //             />
  //           </div>
  //           <div style={{ width: '45%', overflow: 'hidden', marginLeft: 10 }}>
  //             <DatePicker
  //               selected={endDate != '' ? endDate : new Date()}
  //               onChange={(date) => setEndDate(date)}
  //               dateFormat="d-MMM-yyyy"
  //               className="date-picker"
  //               minDate={startDate ? startDate : new Date().setMonth(new Date().getMonth() - 1)}
  //             />
  //           </div>
  //         </div>
  //         <p className="mb-0 mt-3">Status</p>
  //         <div className="radiobtn" onClick={(val) => handleRadio(val.target.value)}>
  //           {[0, 1].map((item) => (
  //             <span>
  //               <input
  //                 type="radio" className='mr-1' value={item} name='status' checked={selectedStatus != '' && selectedStatus == item} />
  //               {item === 0 ? 'Disabled' : 'Enabled'}
  //             </span>
  //           ))}
  //         </div>
  //         <div className=" ml-1 row">
  //           <div
  //             className="filter-submit mt-3"
  //             onClick={onPressFilter}
  //           >
  //             <p className="ml-3 mr-3 mt-3 mb-3">Filter</p>
  //           </div>
  //           <div
  //             className="filter-clear mt-3"
  //             onClick={onPressClear}
  //           >
  //             <p className="ml-3 mr-3 mt-3 mb-3">Clear</p>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   )
  // }


  return (
    <>
    <div className="card h-100">
      <Sidebar
        pullRight
        sidebar={filter()}
        open={openFilter}
        styles={{
          sidebar:
          {
            background: "white",
            overflow: "visible",
            zIndex: 20
          },
          overlay:
          {
            backgroundColor: "transparent"
          }
        }}
      >
        <div className="card-body">
         
          <div className="d-flex align-items-center table-header-menu justify-content-between">
            <div className="d-flex align-items-center">
              <p className="mr-3">Show</p>
              <p className="mr-3">{limit}</p>
              <Dropdown>
                <Dropdown.Toggle variant="default" id="dropdown-basic">
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="#" onClick={(e) => onToggleLimit(e, 10)}>10</Dropdown.Item>
                  <Dropdown.Item href="#" onClick={(e) => onToggleLimit(e, 25)}>25</Dropdown.Item>
                  <Dropdown.Item href="#" onClick={(e) => onToggleLimit(e, 50)}>50</Dropdown.Item>
                  <Dropdown.Item href="#" onClick={(e) => onToggleLimit(e, 100)}>100</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="d-flex align-items-center">
              <p className="mr-3">Search</p>
              <input className="form-control" placeholder="Search" onChange={(e) => onSearch(e.target.value.trim())} />
              <span className="btn filter-btn" onClick={() =>filterClicked()}>
                Filter
                {Object.keys(filters).length > 0 ?
                  <span>
                    &#9733;
                  </span>
                  : null}
              </span>
            </div>
          </div>
          {!loading && data.length > 0 ?
            <div className="dataHeader">
              <p className="mr-3">
                Showing {page > 1 ? (page - 1) * limit : 1} to {page * limit < total ? page * limit : total} of {total} entries {Object.keys(filters).length > 0 ? `(filtered)` : ''}
              </p>
            </div>
            : null}
          <div className="table-responsive">
            {loading ? <div className="d-flex justify-content-center">
              <Loader
                type="TailSpin"
                color="#000"
                height={30}
                width={30}
              />
            </div> :
              <DataTable
                columns={columns}
                data={data}
                customStyles={customTableStyles}
              />
            }
          </div>
        </div>
        {!loading && data.length > 0 ?
          <div className="d-flex card-footer align-items-center justify-content-between">
            <p className="page-data-count">
              Showing {page > 1 ? (page - 1) * limit : 1} to {page * limit < total ? page * limit : total} of {total} entries
            </p>
            <div>
              <Pagination pages={pages} current={page} onChange={setPage} />
            </div>
          </div>
          : null}
      </Sidebar>
      <CustomToast variant={toastMessage.variant} message={toastMessage.message} show={showToast} onClose={() => { setShowToast(false) }} />
    </div>
    </>
  );

}