import React from "react";
import Multiselect from 'multiselect-react-dropdown';
import './components.scss';
import crossIconWhite from '../../assets/img/close-white.png';

export const DropdownAndInput = ({ value, onTitleChange, onContentChange, onRemoveItem, titlePlaceholder, contentPlaceholder, showRemove, showTitleError, titleError, showContentError, contentError, options, selectedOptions, contentField, titleField }) => {
  return (
    <div className="row">
      {/* <div className={"col-md-5 col-12 pr-0"}>
        <div className="mb-1">
          <div className="selct-min-height-days">
            <Multiselect
              options={options}
              selectedValues={selectedOptions}
              onSelect={(val) => { onTitleChange(val) }}
              showCheckbox={false}
              placeholder={titlePlaceholder}
              hidePlaceholder={true}
              showArrow={true}
              isObject={true}
              displayValue='label'
              singleSelect={true}
              avoidHighlightFirstOption={true}
            />
          </div>
          <input
            type="number"
            max="999"
            min="0"
            maxlength="3"
            id="first-name-column"
            className="form-control"
            placeholder={titlePlaceholder}
            onChange={(event) => {
              if (event.target.value === '' || (event.target.value >= 0 && event.target.value <= 999)) {
                onTitleChange(event.target.value)
              }
            }}
            value={value[titleField]}
          />
        </div>
        {showTitleError &&
          <span className="error text-danger">{titleError}</span>
        }
      </div> */}
      <div className="col-md-12 col-12 pr-3">
        <div className="mb-1">
          <textarea
          rows="2"
          cols="50"
            type="text"
            id="first-name-column"
            className="form-control"
            placeholder={contentPlaceholder}
            onChange={(event) => { onContentChange(event.target.value) }}
            value={value[contentField]}
          />
        </div>
        {showContentError &&
          <span className="error text-danger">{contentError}</span>
        }
      </div>
      {showRemove &&
        <div className="col-md-1 col-12 pr-0">
          <div className="mb-1">
            <div className="remove-policy">
              <img src={crossIconWhite} width="13" height="13" onClick={onRemoveItem} />
            </div>
          </div>
        </div>
      }
    </div>
  );
}
