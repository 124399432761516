import React from 'react';
import Multiselect from 'multiselect-react-dropdown';
const SingleSelect = ({options, label, selectedValues, setFieldValue, placeholder, fieldName, disable }) => {

  return (
    <>
      <label
        className="form-label"
        htmlFor="first-name-column"
      >
        {label}
      </label>
      <Multiselect
        options={options}
        selectedValues={selectedValues}
        onSelect={(value) => { setFieldValue(value[0]) }}
        showCheckbox={false}
        placeholder={placeholder}
        hidePlaceholder={true}
        showArrow={true}
        isObject={true}
        singleSelect={true}
        avoidHighlightFirstOption={true}
        displayValue={fieldName}
        disable={disable}
      />
    </>

  );
};

export default SingleSelect;