import { send, setHeader } from "../../utils/api";

export async function getPopupBannerList(limit, page, search, filter) {
    let body = {
        filter: filter
    }
  
    const token = localStorage.getItem('token');
    setHeader('Authorization', `Bearer ${token}`);
    let url = `bannerList?size=${limit}&page=${page}`;
    if (search) {
        url += `&search=${search}`;
    }
    let data = await send(url, "post", body);
    return data;
}
export async function sarchLocation(term) {
    const token = localStorage.getItem('token');
    setHeader('Authorization', `Bearer ${token}`);
    let url = `locations?input=${term}`;
    let data = await send(url, "get");
    return data;
  }
  export async function getLocationDetails(id) {
    const token = localStorage.getItem('token');
    setHeader('Authorization', `Bearer ${token}`);
    let url = `locations/${id}`;
    try {
      let data = await send(url, "get");
      return data;
    } catch (err) {
      throw err;
    }
  };
  export async function getOfferDetails() {
    const token = localStorage.getItem('token');
    setHeader('Authorization', `Bearer ${token}`);
    let url = `offerDetails`;
    try {
      let data = await send(url, "get");
      return data;
    } catch (err) {
      throw err;
    }
  };
export async function createPopupBanner(body) {
    try {
        const token = localStorage.getItem('token');
        setHeader('Authorization', `Bearer ${token}`);
        let url = `createBanner`;
        let data = await send(url, "post", body);
        return data;
    }
    catch (err) {
        throw err
    }
}

export async function onEditPopupBanner(id, body) {
    try {
        const token = localStorage.getItem('token');
        setHeader('Authorization', `Bearer ${token}`);
        let url = `editBanner`;
        let data = await send(url, "post", {...body,bannerId:id});
        return data;
    }
    catch (err) {
        throw err
    }
}

export async function deletePopupBanner(id) {
    const token = localStorage.getItem('token');
    const body = {bannerId: id}
    setHeader('Authorization', `Bearer ${token}`);
    let url = `deleteBanner`
    let data = await send(url, "post", body);
    return data;
}

export async function uploadPopupBannerImage(file) {
    const token = localStorage.getItem('token');
    setHeader('Authorization', `Bearer ${token}`);
    let url = `uploadBannerImage`;
    var formdata = new FormData();
    formdata.append("file", file);
    try {
        let data = await send(url, "post", formdata);
        return data;
    } catch (err) {
        throw err;
    }
};



