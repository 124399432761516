//let api = require("../utils/api");
import {send,setHeader} from "../utils/api";
export async function login(username, password){
    let response= await send("adminLogin", "post", {userName: username, password});
    const {user, menus} = response.data;

    if (response.data.user.firstLogin) {
        return response.data;
    } else {
        localStorage.setItem('token', user.token);
        delete user.token;
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('menus', JSON.stringify(menus));
        return response.data;
    }
   
}
//export default login;

export async function resetPassword(ownerId, password, token){
    console.log("token ",token)
    setHeader('Authorization', `Bearer ${token}`);
    let response= await send("updateAdminPassword", "post", {adminId: ownerId, password});
    return response;  
}

export async function forgotPassword(email){
    let response= await send("forgotPassword", "post", {email: email});
    return response;  
}