import {send, setHeader} from "../utils/api";

export async function getCounts() {
    const token = localStorage.getItem('token');
    setHeader('Authorization', `Bearer ${token}`);
    let url = `dashboard/totalcount`;
    let data= await send(url,"get");
    return data
}

export async function getMapData() {
    const token = localStorage.getItem('token');
    setHeader('Authorization', `Bearer ${token}`);
    let url = `dashboard/countrycount`;
    let data= await send(url,"get");
    return data
}
