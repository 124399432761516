import React from 'react';
import {
  NavLink,
  useLocation,
} from "react-router-dom";
import RadioChecked from '../../assets/svg/radio.svg';
import RadioUnchecked from '../../assets/svg/radio-unchecked.svg';

export function SubMenu({ index, item }) {
  const location = useLocation();
  return (
    <li key={`navlink-submenu-${index}`} className="nav-item">
      <NavLink className="nav-link sub-menu" activeClassName="active" aria-current="page" to={`/${item.link}`} exact>
        <img src={location.pathname.includes(item.link) ? RadioChecked : RadioUnchecked} width={20} height={30} />
        <span className="menu-title">{item.text}</span>
      </NavLink>
    </li>
  );
}