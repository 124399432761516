import { send, setHeader } from "../../utils/api";

export async function getStudioTypesList(limit, page, search, filter) {
    let body = {
        filter: filter
    }
    const token = localStorage.getItem('token');
    setHeader('Authorization', `Bearer ${token}`);
    let url = `getStudioTypes?size=${limit}&page=${page}`;
    if (search) {
        url += `&search=${search}`;
    }
    let data = await send(url, "post", body);
    return data;
}

export async function createStudioType(body) {
    try {
        const token = localStorage.getItem('token');
        setHeader('Authorization', `Bearer ${token}`);
        let url = `createStudioType`;
        let data = await send(url, "post", body);
        return data;
    }
    catch (err) {
        throw err
    }
}

export async function editStudioType(id, body) {
    try {
        const token = localStorage.getItem('token');
        setHeader('Authorization', `Bearer ${token}`);
        let url = `editStudioType/${id}`;
        let data = await send(url, "post", body);
        return data;
    }
    catch (err) {
        throw err
    }
}

export async function changeStudioTypeStatus(id, body) {
    const token = localStorage.getItem('token');
    setHeader('Authorization', `Bearer ${token}`);
    let url = `changeStudioTypeStatus/${id}`
    let data = await send(url, "post", body);
    return data;
}

export async function deleteStudioType(id) {
    const token = localStorage.getItem('token');
    setHeader('Authorization', `Bearer ${token}`);
    let url = `deleteStudioType/${id}`
    let data = await send(url, "delete");
    return data;
}

export async function uploadStudiotypeImage(file) {
    const token = localStorage.getItem('token');
    setHeader('Authorization', `Bearer ${token}`);
    let url = `uploadStudiotypeImage`;
    var formdata = new FormData();
    formdata.append("file", file);
    try {
        let data = await send(url, "post", formdata);
        return data;
    } catch (err) {
        throw err;
    }
};



