import React from 'react';
import ToastBody from 'react-bootstrap/ToastBody'
import Toast from 'react-bootstrap/Toast';

function CustomToast({ variant, message, show, onClose }) {

  const getColor = (variant) => {
    switch (variant) {
      case 'success': return 'green';
      case 'danger': return 'red';
      case 'warning': return 'blue'
    }
  }
  return (
    <Toast show={show} autohide={true} onClose={onClose} className="toast_fixed_bottom" style={{ backgroundColor: getColor(variant) }}>
      <ToastBody>
        <button type="button" class="close toast-close" aria-label="Close" onClick={onClose}>
          <span aria-hidden="true" className='toast-text'>&times;</span>
        </button>
        <span className='toast-text'>{message}</span>
      </ToastBody>
    </Toast>
  )
}
export default CustomToast;

