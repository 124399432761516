import * as React from "react"

function DashbordUserIcon(props) {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="48" height="48" rx="24" fill="#28C76F" fill-opacity="0.12"/>
      <path d="M24 12C20.5107 12 17.6719 14.8388 17.6719 18.3281C17.6719 21.8175 20.5107 24.6562 24 24.6562C27.4893 24.6562 30.3281 21.8175 30.3281 18.3281C30.3281 14.8388 27.4893 12 24 12ZM24 23.25C21.2861 23.25 19.0781 21.042 19.0781 18.3281C19.0781 15.6142 21.2861 13.4062 24 13.4062C26.7139 13.4062 28.9219 15.6142 28.9219 18.3281C28.9219 21.042 26.7139 23.25 24 23.25Z" fill="#28C76F"/>
      <path d="M25.9609 26.1094H22.039C18.7952 26.1094 15.5939 27.6071 13.4531 30.8183V36H34.5469V30.8183C32.4092 27.6117 29.2088 26.1094 25.9609 26.1094ZM33.1407 34.5938H33.1406H14.8594V31.2486C16.5015 28.907 19.1696 27.5156 22.0391 27.5156H25.961C28.8304 27.5156 31.4985 28.907 33.1407 31.2486V34.5938Z" fill="#28C76F"/>
    </svg>
  )
}

export default DashbordUserIcon