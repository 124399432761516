import React, { useState, useEffect } from "react";
import * as Yup from 'yup';
// import * as Headless from '../../../workout/workouts/Workouts.headless'
import { uploadWorkoutImage,getBodyParts,getEquipments } from "../../../workout/workouts/Workouts.headless";
import "react-datepicker/dist/react-datepicker.css";
import { Formik, Form, Field } from 'formik';
import Loader from "react-loader-spinner";
import SearchableInput from "../../searchableInput";
import RadioButton from "../../radioButton";
import SingleSelect from "../../singleSelect";
import CustomInput from "../../customInput";
import { TitleAndContentSeparate } from "../../titleAndContentSeparate";
import MultiSelectDropdown from "../../multiSelect";
import * as ownerApis from '../../../pitchBooking/owners/Owners.headless';
import * as venueApis from '../../../pitchBooking/venue/venue.headless';
import { format, formatToUTC24Time } from "../../../utils/dateFunctions";
import { SingleImageUploader } from "./singleImageUploader";
import { getTargets,getIntensity } from "../../../slices/auth.slice";
import { useSelector } from "react-redux";
import CloaseButton from '../../../components/closeButton';
import { WorkoutDropInput } from "../../WorkoutDropInput";
import { DropdownAndInput } from "../../dropdownAndInput";

export const AddWorkout = ({ onAddWorkout, onCancel, data, fromEdit }) => {
  const targets = useSelector(getTargets);
  const intensity = useSelector(getIntensity);
  const [bodyPartList, setBodyPartList] = useState([]);
  const [equipmentList, setEquipmentList] = useState([]);
  const [workoutList, setWorkoutList] = useState([]);
  // const [secondaryMuscles,setSecondaryMuscles] = useState(targets)

  const [selectedBodyPart, setSelectedBodyPart] = useState('');
  const [selectedTarget,setSelectedTarget] = useState('')
  const [selectedTargets,setSelectedTargets] = useState([])
  const [selectedSecondaryMuscle,setSelectedSecondaryMuscle] = useState('');
  const [selectedWorkout, setSelectedWorkout] = useState('');
  const [selectedEquipment, setSelectedEquipment] = useState('');

  const [loading, setLoading] = useState(false);
  const [remoteUrl, setRemoteUrl] = useState('');
  const [hours, setHours] = useState([]);

  const offerSchema = Yup.object().shape({
    name: Yup.string().trim().required('Enter workout name'),
    image: Yup.string().required('Upload an image'),
    bodyPart: Yup.string().required('Choose Bodypart'),
    equipment: Yup.string().required('Choose Equipment'),
    target: Yup.string().required('Choose Target'),
    beginnerSets: Yup.object({
      sets: Yup.number().required('Sets required').positive('Sets must be positive').integer('Sets must be an integer'),
      minReps: Yup.number().required('Min reps required').positive('Min reps must be positive').integer('Min reps must be an integer'),
      maxReps: Yup.number().required('Max reps required').positive('Max reps must be positive').integer('Max reps must be an integer'),
    }),
    intermediateSets: Yup.object({
      sets: Yup.number().required('Sets required').positive('Sets must be positive').integer('Sets must be an integer'),
      minReps: Yup.number().required('Min reps required').positive('Min reps must be positive').integer('Min reps must be an integer'),
      maxReps: Yup.number().required('Max reps required').positive('Max reps must be positive').integer('Max reps must be an integer'),
    }),
    expertSets: Yup.object({
      sets: Yup.number().required('Sets required').positive('Sets must be positive').integer('Sets must be an integer'),
      minReps: Yup.number().required('Min reps required').positive('Must be positive').integer('Min reps must be an integer'),
      maxReps: Yup.number().required('Max reps required').positive('Max reps must be positive').integer('Max reps must be an integer'),
    })
  });

  useEffect(() => {
    if (fromEdit) {
      data.instructions = data.instructions.map(item=>({instruction:item}));
      console.log("data is",data)
      setSelectedBodyPart({label:data.bodyPart});
      setSelectedEquipment({label:data.equipment});
      setSelectedTarget({name:data.target});
      setSelectedTargets(data.secondaryMuscles)
    }
  }, [fromEdit,data]);

  const fetchData = async () => {
    try {
      const [bodyPartsResponse, equipmentsResponse] = await Promise.all([
        getBodyParts(),
        getEquipments()
      ]);

      const formatData = (data) => data.map(item => ({ label: item.title, value: item._id,image:item.image.uri}));

      setBodyPartList(formatData(bodyPartsResponse.data.data));
      setEquipmentList(formatData(equipmentsResponse.data.data));
      // setLoading(false);
    } catch (err) {
      // setError('Failed to fetch data');
      // setLoading(false);
      setBodyPartList([]);
      setEquipmentList([]);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onSubmit = async (values) => {
    if (fromEdit) {
      let val = {};
      if (values.name !== data.name) {
        val.name = values.name
      }
      if (values.image !== data.image) {
        val.image = values.image
      }
      if (values.bodyPart !== data.bodyPart) {
        val.bodyPart = values.bodyPart
      }
      if (values.equipment !== data.equipment) {
        val.equipment = values.equipment
      }
      if (values.target !== data.target) {
        val.target = values.target
      }
      if (values.secondaryMuscles !== data.secondaryMuscles) {
        val.secondaryMuscles = values.secondaryMuscles
      }
      values.instructions = values.instructions.map(item=>item.instruction);
      if (values.instructions !== data.instructions) {
        val.instructions = values.instructions
      }
      if (values.beginnerSets !== data.beginnerSets) {
        val.beginnerSets = values.beginnerSets
      }
      if (values.intermediateSets !== data.intermediateSets) {
        val.intermediateSets = values.intermediateSets
      }
      if (values.expertSets !== data.expertSets) {
        val.expertSets = values.expertSets
      }
// console.log("edit values are",val);
      onAddWorkout(val)
    } else {
     
      values.instructions = values.instructions.map(item=>item.instruction);
      // console.log("selected values are",values)
      onAddWorkout(values)
    }
  }

  const onBodyPartSelect = async (val) => {
    setSelectedBodyPart(val)
  }
  const onEquipmentSelect = async (val) => {
    setSelectedEquipment(val)
  }
  const onTargetSelect = async (val) => {
    setSelectedTarget(val)
  }
  const onSecondaryTargetSelect = (team , setFieldValue) => {
    // console.log("teams are", team)
    // setSelectedTarget(team)
    let n = team.name;
    const isDuplicate = selectedTargets.some((item) => item == n);
    if (!isDuplicate) {
      let temp = [...selectedTargets,n];
      setFieldValue('secondaryMuscles', temp);
      setSelectedTargets((prevItems) => [...prevItems, n]);
    }
  }
  const removeItem = (id, setFieldValue) => {
    // Use the filter function to create a new array without the item with the specified ID
    const updatedItems = selectedTargets.filter((item) => item !== id);
    // const updatedTargets = teams.filter((item) => item != id._id);
    setSelectedTargets(updatedItems);
    setFieldValue("secondaryMuscles", updatedItems)
  };
  // function groupArrayOfObjects(list, key) {
  //   return list.reduce(function (rv, x) {
  //     (rv[x[key]] = rv[x[key]] || []).push(x);
  //     return rv;
  //   }, {});
  // };

  const onImageUpload = async (images, isDefaultImageUpload, setFieldValue) => {
    setLoading(true);
    for (let i = 0; i < images.length; i++) {
      let resp = await handleUpload(images[i], isDefaultImageUpload, setFieldValue);
      setFieldValue('image', resp.url ? resp.url : '');
      setRemoteUrl(resp.url ? resp.url : '');
      setLoading(false);
    }
  }

  const handleUpload = async (item, isDefaultImageUpload, setFieldValue) => {
    if (!isDefaultImageUpload) {
      try {
        const resp = await uploadWorkoutImage(item.file);
        if (resp && resp.data && resp.data.data) {
          let img = resp.data.data;
          return { 'url': img.location, 'id': item.id }
        }
      }
      catch (err) {
        return {}
      }
    }
  }

  useEffect(() => {
    const arr = [];
    for (let i = 24; i > 0; i--) {
      arr.push({ label: i, value: i })
    }
    setHours(arr);
  }, [])
  const renderPolicyInput = (policies, setFieldValue, errors, touched) => {
    // console.log("policies",policies);
    const p = policies.map((item, index) => {
      return <DropdownAndInput
        // options={hours}
        // selectedOptions={item.hours ? [{value:item.hours, label: item.hours}]: []}
        contentField='instruction'
        titleField='hours'
        value={item}
       
        onContentChange={(val) => {
          let data = [...policies];
          data[index].instruction = val;
          setFieldValue('instructions', data)
        }}
        onRemoveItem={() => {
          let data = [...policies];
          data.splice(index, 1);
          setFieldValue('instructions', data)
        }}
        titlePlaceholder="Choose hour"
        contentPlaceholder="Enter instructions"
        showRemove={index === 0 ? false : true}
        showTitleError={showPolicyTitleError(errors, touched, index)}
        titleError={showPolicyTitleError(errors, touched, index) ? errors.policies[index].hours : ''}
        showContentError={showPolicyContentError(errors, touched, index)}
        contentError={showPolicyContentError(errors, touched, index) ? errors.policies[index].refundPersentage : ''}
      />
    })
    return p;
  }
  const showPolicyTitleError = (errors, touched, index) => {
    if (errors && errors.policies && errors.policies.length > index && touched.policies) {
      if (errors.policies[index] && errors.policies[index].hours) {
        return true
      }
      return false
    }
    return false
  }
  const showPolicyContentError = (errors, touched, index) => {
    if (errors && errors.policies && errors.policies.length > index && touched.policies) {
      if (errors.policies[index] && errors.policies[index].refundPersentage) {
        return true
      }
      return false
    }
    return false
  }
  const onPolicyAdd = (policies, setFieldValue) => {
    let p = [...policies];
    p.push({ instruction: '' })
    setFieldValue('instructions', p)
  }
  const onRemoveImage = (id, setFieldValue) => {
    setRemoteUrl('');
    setFieldValue('image', '');
  }
  
  return (
    <>
      {loading &&
        <div>
          <Loader
            type="TailSpin"
            color="#000"
            height={30}
            width={30}
            className="loader-parent"
          />
        </div>
      }
      <Formik
        initialValues={data}
        onSubmit={onSubmit}
        onReset={onCancel}
        validationSchema={offerSchema}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({ errors, touched, isSubmitting, values, setFieldValue }) => {
          console.log("errorsd",errors);
          console.log("touched",touched);
          // console.log("errorsd",values);
          return (
            <Form className="form">

              <div class="col-md-12 col-sm-12 col-xs-12 underline" />
              <div className="row">
                <div className="col-md-6 col-12">
                  <div className="mb-3">
                    <label
                      className="form-label"
                      htmlFor="first-name-column"
                    >
                      Workout Name
                    </label>
                    <Field
                      type="text"
                      id="first-name-column"
                      className="form-control"
                      placeholder="Enter workout name"
                      name="name"
                    />
                    {errors.name && touched.name && (
                      <span class="error text-danger">{errors.name}</span>
                    )}
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="mb-3">
                    <SingleSelect label='Bodypart' options={bodyPartList} selectedValues={selectedBodyPart === "" ? [] : [selectedBodyPart]} placeholder='Choose Bodypart' setFieldValue={(val) => {
                      setFieldValue('bodyPart', val.value);
                      onBodyPartSelect(val)
                    }} fieldName="label" />
                    {errors.bodyPart && touched.bodyPart && (
                      <span className="error text-danger">{errors.bodyPart}</span>
                    )}
                    
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 col-12">
                <div className="mb-3">
                    <SingleSelect label='Equipment' options={equipmentList} selectedValues={selectedEquipment === "" ? [] : [selectedEquipment]} placeholder='Choose Equipment' setFieldValue={(val) => {
                      setFieldValue('equipment', val.value);
                      onEquipmentSelect(val)
                    }} fieldName="label" />
                    {errors.equipment && touched.equipment && (
                      <span className="error text-danger">{errors.equipment}</span>
                    )}
                    
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="mb-3">
                    <SingleSelect label='Target' options={targets} selectedValues={selectedTarget === "" ? [] : [selectedTarget]} placeholder='Choose Target' setFieldValue={(val) => {
                      setFieldValue('target', val.name);
                      onTargetSelect(val)
                    }} fieldName="name" />
                    {errors.target && touched.target && (
                      <span className="error text-danger">{errors.target}</span>
                    )}
                    
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 col-12">
                  <div className="mb-3">
                    <SingleSelect label='Secondary muscles' options={targets} selectedValues={selectedSecondaryMuscle === "" ? [] : [selectedSecondaryMuscle]} placeholder='Choose secondary muscle' setFieldValue={(val) => {
                      // setFieldValue('target', val.name);
                      // onTargetSelect(val)
                      onSecondaryTargetSelect(val, setFieldValue);
                    }} fieldName="name" />
                    {/* {errors.target && touched.target && (
                      <span className="error text-danger">{errors.target}</span>
                    )} */}
                  </div>
                </div>
                <div className="col-md-6 col-12">
                <div className="mb-2">
                      <label
                        className="form-label"
                        htmlFor="first-name-column"
                      >
                        {selectedTargets.length > 0 ? "Selected Targets" : ""}

                      </label>
                 </div>

                    {selectedTargets.map((item, index) => (
                      <>
                        {/* <div className=''> */}
                        <CloaseButton text={item} item={item} onClick={(item) => removeItem(item, setFieldValue)} />
                        {/* </div> */}
                      </>
                    ))}
                </div>
              </div>
                <div className='row'>
                  <div className="col-md-12 col-12">
                    <label
                      className="form-label"
                      htmlFor="first-name-column"
                    >
                      Instructions
                    </label>
                    {renderPolicyInput(values.instructions, setFieldValue, errors, touched)}
                    <div className='row'>
                      <div className='col-md-11 text-right'>
                        <span className="addMoreBtn">+</span><span className='add-more-policy' onClick={() => onPolicyAdd(values.instructions, setFieldValue)}>Add More </span>
                      </div>
                    </div>
                  </div>
                </div>
                
              
              <div className="row">
                <div className="col-md-4 col-12">
                  <div className="mb-3">

                    <label className="form-label" htmlFor="beginnerSets">Beginner Sets</label>
                    <div className="d-flex">
                      <Field className="form-control smallPlaceHolder me-2" id="beginnerSets" name="beginnerSets.sets" placeholder="Sets" style={{ width: '20%' }} />
                      <span className="mt-2">Sets with</span>
                      <Field className="form-control smallPlaceHolder me-2" id="beginnerMinReps" name="beginnerSets.minReps" placeholder="MinReps" style={{ width: '20%' }} />
                      <span className="mt-2">to</span>
                      <Field className="form-control smallPlaceHolder" id="beginnerMaxReps" name="beginnerSets.maxReps" placeholder="MaxReps" style={{ width: '20%' }} />
                      <span className="mt-2">reps</span>
                    </div>
                    <div className="d-flex">
                    {errors.beginnerSets?.sets && touched.beginnerSets && (
                      <span className="error text-danger smallPlaceHolder">| {errors.beginnerSets?.sets}</span>
                  )}
                    {errors.beginnerSets?.minReps && touched.beginnerSets && (
                      <span className="error text-danger smallPlaceHolder">| {errors.beginnerSets?.minReps}</span>
                  )}
                    {errors.beginnerSets?.maxReps && touched.beginnerSets && (
                      <span className="error text-danger smallPlaceHolder">| {errors.beginnerSets?.maxReps}</span>
                  )}
                    </div>
                    
                  </div>
                
                </div>
                <div className="col-md-4 col-12">
                  <div className="mb-3">

                    <label className="form-label" htmlFor="intermediateSets">Intermediate Sets</label>
                    <div className="d-flex">
                      <Field className="form-control smallPlaceHolder me-2" id="intermediateSets" name="intermediateSets.sets" placeholder="Sets" style={{ width: '20%' }} />
                      <span className="mt-2">Sets with</span>
                      <Field className="form-control smallPlaceHolder me-2" id="intermediateMinReps" name="intermediateSets.minReps" placeholder="Min Reps" style={{ width: '20%' }} />
                      <span className="mt-2">to</span>
                      <Field className="form-control smallPlaceHolder" id="intermediateMaxReps" name="intermediateSets.maxReps" placeholder="Max Reps" style={{ width: '20%' }} />
                      <span className="mt-2">reps</span>
                    </div>
                    {errors.intermediateSets?.sets && touched.intermediateSets && (
                      <span className="error text-danger smallPlaceHolder">| {errors.intermediateSets?.sets}</span>
                  )}
                    {errors.intermediateSets?.minReps && touched.intermediateSets && (
                      <span className="error text-danger smallPlaceHolder">| {errors.intermediateSets?.minReps}</span>
                  )}
                    {errors.intermediateSets?.maxReps && touched.intermediateSets && (
                      <span className="error text-danger smallPlaceHolder">| {errors.intermediateSets?.maxReps}</span>
                  )}
                  </div>
                </div>
                <div className="col-md-4 col-12">
                  <div className="mb-3">
                    <label className="form-label" htmlFor="expertSets">Expert Sets</label>
                    <div className="d-flex">
                      <Field className="form-control smallPlaceHolder me-2" id="expertSets" name="expertSets.sets" placeholder="Sets" style={{ width: '20%' }} />
                      <span className="mt-2">Sets with</span>
                      <Field className="form-control smallPlaceHolder me-2" id="expertMinReps" name="expertSets.minReps" placeholder="Min Reps" style={{ width: '20%' }} />
                      <span className="mt-2">to</span>
                      <Field className="form-control smallPlaceHolder" id="expertMaxReps" name="expertSets.maxReps" placeholder="Max Reps" style={{ width: '20%' }} />
                      <span className="mt-2">reps</span>
                    </div>
                    {errors.expertSets?.sets && touched.expertSets && (
                      <span className="error text-danger smallPlaceHolder">| {errors.expertSets?.sets}</span>
                  )}
                    {errors.expertSets?.minReps && touched.expertSets && (
                      <span className="error text-danger smallPlaceHolder">| {errors.expertSets?.minReps}</span>
                  )}
                    {errors.expertSets?.maxReps && touched.expertSets && (
                      <span className="error text-danger smallPlaceHolder">| {errors.expertSets?.maxReps}</span>
                  )}
                  </div>
                </div>
              </div>

              
              <div className='col-md-12 col-12 px-0'>
                <SingleImageUploader uploadImage={(images) => { onImageUpload(images, false, setFieldValue) }}
                  removeImage={(id) => { onRemoveImage(id, setFieldValue) }} images={fromEdit ? data?.image === '' ? [] : [{ path: data?.image }] : []} sizeLimit={512000} />
               
              </div>
              {/* {errors.image && touched.image && (
                  <span className="error text-danger">{errors.image}</span>
                )} */}

              <div className="row mt-3">
                <div className="col-12">
                  <button
                    type="submit"
                    className="btn filter-btn mr-3 ml-0 waves-effect waves-float waves-light"
                  >
                    Save
                  </button>
                  <button
                    type="reset"
                    className="btn btn-outline-secondary waves-effect"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Form>
          )
        }
        }
      </Formik>
    </>
  );
}