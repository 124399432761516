import * as React from "react"

function ReportsIcon(props) {
    return (
            <svg width={16}
                height={16}
                viewBox="0 0 16 16"
                xmlns="http://www.w3.org/2000/svg"
                fill="black"
                {...props}>
                <g clip-path="url(#clip0)">
                    <path d="M10.1762 11.9212H7.05108C6.78956 11.9212 6.57758 12.1332 6.57758 12.3947V18.5265C6.57758 18.788 6.78956 19 7.05108 19H10.1762C10.4377 19 10.6497 18.788 10.6497 18.5265V12.3947C10.6497 12.1332 10.4377 11.9212 10.1762 11.9212ZM9.70267 18.053H7.52458V12.8682H9.70267V18.053Z" />
                    <path d="M16.3791 8.20419H13.2303C12.9688 8.20419 12.7568 8.41618 12.7568 8.67769V18.5265C12.7568 18.788 12.9688 19 13.2303 19H16.3791C16.6406 19 16.8526 18.788 16.8526 18.5265V8.67769C16.8526 8.41622 16.6406 8.20419 16.3791 8.20419ZM15.9056 18.053H13.7038V9.15119H15.9056V18.053Z" />
                    <path d="M3.99705 14.2176H0.848257C0.586742 14.2176 0.374756 14.4296 0.374756 14.6911V18.5265C0.374756 18.788 0.586742 19 0.848257 19H3.99705C4.25856 19 4.47055 18.788 4.47055 18.5265V14.6911C4.47055 14.4297 4.25856 14.2176 3.99705 14.2176ZM3.52355 18.053H1.32176V15.1646H3.52355V18.053Z" />
                    <path d="M16.8999 2.47486C16.8279 2.37562 16.7146 2.31463 16.5921 2.30914L12.2833 2.00136C12.0218 1.98174 11.7939 2.17786 11.7743 2.43933C11.7546 2.70085 11.9507 2.92874 12.2122 2.94836L15.2618 3.15545L9.65535 7.54135L5.37019 4.17949C5.1872 4.03457 4.92596 4.04463 4.75462 4.20315L0.351092 8.41725C0.163894 8.59484 0.153381 8.88966 0.327437 9.08017C0.411905 9.18979 0.544317 9.2516 0.682574 9.24589C0.807464 9.24412 0.926598 9.19305 1.01401 9.10382L5.1098 5.17376L9.34761 8.51191C9.52058 8.6504 9.76651 8.6504 9.93948 8.51191L16.0004 3.79558L15.7635 6.73637C15.7623 6.99349 15.9574 7.20905 16.2134 7.23352H16.237C16.4811 7.23475 16.6861 7.05022 16.7105 6.80738L17.0183 2.8063C17.0357 2.68309 16.9914 2.55919 16.8999 2.47486Z" />
                </g>
                <defs>
                    <clipPath id="clip0">
                        <rect width="17" height="17" fill="white" transform="translate(0.112732 2)" />
                    </clipPath>
                </defs>
            </svg>

    )
}

export default ReportsIcon