import * as React from "react"

function UserIcon(props) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8 0a4.224 4.224 0 00-4.219 4.219A4.224 4.224 0 008 8.437a4.224 4.224 0 004.219-4.218A4.224 4.224 0 008 0zm0 7.5a3.285 3.285 0 01-3.281-3.281A3.285 3.285 0 018 .937a3.285 3.285 0 013.281 3.282A3.285 3.285 0 018 7.5zM9.307 9.406H6.693c-2.163 0-4.297.999-5.724 3.14V16H15.03v-3.454c-1.425-2.138-3.559-3.14-5.724-3.14zm4.787 5.656H1.906v-2.23a5.835 5.835 0 014.787-2.488h2.614c1.913 0 3.692.927 4.787 2.488v2.23z"
      />
    </svg>
  )
}

export default UserIcon
