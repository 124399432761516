import * as React from "react"

function TeamIcon(props) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.656 8.38H12.09c-.213 0-.42.029-.616.082a2.346 2.346 0 00-2.09-1.285H6.616c-.912 0-1.703.524-2.09 1.285a2.338 2.338 0 00-.616-.082H2.344A2.346 2.346 0 000 10.723v2.504c0 .776.63 1.406 1.406 1.406h13.188c.775 0 1.406-.63 1.406-1.406v-2.504a2.346 2.346 0 00-2.344-2.343zM4.272 9.52v4.175H1.406a.47.47 0 01-.468-.469v-2.504c0-.775.63-1.406 1.406-1.406H3.91c.127 0 .25.018.368.05a2.352 2.352 0 00-.006.154zm6.518 4.175H5.21V9.521c0-.775.63-1.406 1.406-1.406h2.768c.776 0 1.406.63 1.406 1.406v4.175zm4.273-.469a.47.47 0 01-.47.469h-2.865V9.521c0-.052-.002-.103-.006-.155.118-.031.24-.049.368-.049h1.566c.776 0 1.406.631 1.406 1.406v2.504z"
      />
      <path
        d="M3.127 3.953a2.085 2.085 0 00-2.083 2.083c0 1.148.934 2.083 2.083 2.083A2.085 2.085 0 005.21 6.036a2.085 2.085 0 00-2.083-2.083zm0 3.228a1.146 1.146 0 01-1.145-1.145c0-.631.513-1.145 1.145-1.145.631 0 1.145.514 1.145 1.145 0 .632-.514 1.145-1.145 1.145zM8 1.366A2.786 2.786 0 005.218 4.15 2.786 2.786 0 008 6.93a2.786 2.786 0 002.782-2.782A2.786 2.786 0 008 1.366zm0 4.628a1.847 1.847 0 01-1.845-1.845c0-1.017.828-1.845 1.845-1.845s1.845.828 1.845 1.845A1.847 1.847 0 018 5.994zM12.873 3.953a2.085 2.085 0 00-2.082 2.083c0 1.148.934 2.083 2.082 2.083a2.085 2.085 0 002.083-2.083 2.085 2.085 0 00-2.083-2.083zm0 3.228a1.147 1.147 0 01-1.145-1.145c0-.631.514-1.145 1.145-1.145.632 0 1.145.514 1.145 1.145 0 .632-.513 1.145-1.145 1.145z"
      />
    </svg>
  )
}

export default TeamIcon