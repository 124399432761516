import { send, sendServer, setHeader } from "../../utils/api";
const axios = require('axios');
axios.defaults.withCredentials = true;
export async function getStudioList(limit, page, search, filter, ownerId) {
  let body = {};
  if (Object.keys(filter).length !== 0) {
    body.filter = filter;
  }
  if (ownerId) {
    let f = { ...filter }
    f.owner = ownerId
    body.filter = f;
  }
  const token = localStorage.getItem('token');
  setHeader('Authorization', `Bearer ${token}`);
  let url = `getStudios?size=${limit}&page=${page}`;
  if (search) {
    url += `&search=${search}`;
  }
  console.log("getStudios call",body)
  let data = await send(url, "post", body);
  return data;
}

export async function updatePublishStastus(id, body) {
  const token = localStorage.getItem('token');
  setHeader('Authorization', `Bearer ${token}`);
  let url = `updateStudioPublishStatus/${id}`;
  try {
    let data = await send(url, "post", body);
    return data;
  } catch (err) {
    throw err;
  }
};

export async function updateBookableStatus(id, body) {
  const token = localStorage.getItem('token');
  setHeader('Authorization', `Bearer ${token}`);
  let url = `updateStudioBookingStatus/${id}`
  try {
    let data = await send(url, "post", body);
    return data;
  } catch (err) {
    throw err;
  }
};
export async function deleteStudio(id) {
  const token = localStorage.getItem('token');
  setHeader('Authorization', `Bearer ${token}`);
  let url = `deleteStudio/${id}`;
  try {
    let data = await send(url, "delete");
    return data;
  } catch (err) {
    throw err;
  }
};
export async function getHighlights() {
  const token = localStorage.getItem('token');
  setHeader('Authorization', `Bearer ${token}`);
  let url = `highlightsList`;
  let data = await send(url, "get");
  return data;
}
export async function uploadDefaultImage(file) {
  const token = localStorage.getItem('token');
  setHeader('Authorization', `Bearer ${token}`);
  let url = `uploadDefaultImage`;
  var formdata = new FormData();
  formdata.append("file", file);
  try {
    let data = await send(url, "post", formdata);
    return data;
  } catch (err) {
    throw err;
  }
};
export async function uploadStudioImages(file) {
  const token = localStorage.getItem('token');
  setHeader('Authorization', `Bearer ${token}`);
  let url = `uploadStudioImage`;
  var formdata = new FormData();
  formdata.append("files", file);
  try {
    let data = await send(url, "post", formdata);
    return data;
  } catch (err) {
    throw err;
  }
};
export async function getTaxList(countryId) {
  let body = {};
  if(countryId) {
    body.filter = {
      country: countryId
    }
  }
  const token = localStorage.getItem('token');
  setHeader('Authorization', `Bearer ${token}`);
  let url = `taxList`;
  let data = await send(url, "post", body);
  return data;
}
export async function createBasicStudio(body) {

  const token = localStorage.getItem('token');
  setHeader('Authorization', `Bearer ${token}`);
  let url = `createStudio`;
  try {
    let data = await send(url, "post", body);
    return data;
  } catch (err) {
    throw err;
  }
};
export async function sarchLocation(term) {
  const token = localStorage.getItem('token');
  setHeader('Authorization', `Bearer ${token}`);
  let url = `locations?input=${term}`;
  let data = await send(url, "get");
  return data;
}
export async function getLocationDetails(id) {
  const token = localStorage.getItem('token');
  setHeader('Authorization', `Bearer ${token}`);
  let url = `locations/${id}`;
  try {
    let data = await send(url, "get");
    return data;
  } catch (err) {
    throw err;
  }
};
export async function editStudio(body, id) {
  const token = localStorage.getItem('token');
  setHeader('Authorization', `Bearer ${token}`);
  let url = `editStudio/${id}`;
  try {
    let data = await send(url, "post", body);
    return data;
  } catch (err) {
    throw err;
  }
};
export async function getStudioDetails(id) {
  const token = localStorage.getItem('token');
  setHeader('Authorization', `Bearer ${token}`);
  let url = `studioDetails/${id}`;
  let data = await send(url, "get");
  return data;
}








export async function createGround(body) {
  const token = localStorage.getItem('token');
  setHeader('Authorization', `Bearer ${token}`);
  let url = `createGround`;
  try {
    let data = await send(url, "post", body);
    return data;
  } catch (err) {
    throw err;
  }
};

export async function createPitch(id, body) {
  
  const token = localStorage.getItem('token');
  setHeader('Authorization', `Bearer ${token}`);
  let url = `createPitch/${id}`;
  try {
    let data = await send(url, "post", body);
   
    return data;
  } catch (err) {
    throw err;
  }
};
export async function getGrounds(body) {
  const token = localStorage.getItem('token');
  setHeader('Authorization', `Bearer ${token}`);
  let url = `groundList`;
  try {
    let data = await send(url, "post", body);
    return data;
  } catch (err) {
    throw err;
  }
};



export async function getAvailabilitySlots(body) {
  const token = localStorage.getItem('token');
  setHeader('Authorization', `Bearer ${token}`);
  let url = `getAvailability`;
  try {
    let data = await send(url, "post", body);
    return data;
  } catch (err) {
    throw err;
  }
}
export async function getTransactions(limit, page, search, filter, body) {
  const token = localStorage.getItem('token');
  setHeader('Authorization', `Bearer ${token}`);
  let url = `transactionList?size=${limit}&page=${page}`;
  if (search) {
    url += `&search=${search}`;
  }
  try {
    let data = await send(url, "post", body);
    return data;
  } catch (err) {
    throw err;
  }
};
export async function uploadImages(body) {
  const token = localStorage.getItem('token');
  setHeader('Authorization', `Bearer ${token}`);
  let url = `http://ec2-54-246-64-61.eu-west-1.compute.amazonaws.com:3007/upload`;
  let data = await sendServer(url, "post", body);
  return data;
}
export async function getGroundDetails(id) {
  const token = localStorage.getItem('token');
  setHeader('Authorization', `Bearer ${token}`);
  let url = `groundDetails/${id}`;
  let data = await send(url, "get");
  return data;
}
export async function addPrice(values) {
  const token = localStorage.getItem('token');
  setHeader('Authorization', `Bearer ${token}`);
  let url = `addPitchTimeSlots`
  let data = await send(url, "post", values);
  return data;
}
export async function addAvailability(values) {
  const token = localStorage.getItem('token');
  setHeader('Authorization', `Bearer ${token}`);
  let url = `addUnavailability`
  let data = await send(url, "post", values);
  return data;
}
export async function addSpecialPrice(values) {
  const token = localStorage.getItem('token');
  setHeader('Authorization', `Bearer ${token}`);
  let url = `createSpecialPrice`
  let data = await send(url, "post", values);
  return data;
}
export async function addSpecialAvailability(values) {
  const token = localStorage.getItem('token');
  setHeader('Authorization', `Bearer ${token}`);
  let url = `createSpecialAvailability`
  let data = await send(url, "post", values);
  return data;
}
export async function getReviewList(limit, page, search, filter, body) {
  const token = localStorage.getItem('token');
  setHeader('Authorization', `Bearer ${token}`);
  let url = `getReviews?size=${limit}&page=${page}`;
  if (search) {
    url += `&search=${search}`;
  }
  let data = await send(url, "post", body);
  return data;
}
export async function deleteReview(id, venueId) {
  let body = {
    "reviewId": id,
    "vid": venueId,
  }
  const token = localStorage.getItem('token');
  setHeader('Authorization', `Bearer ${token}`);
  let url = `deleteReviews`
  let data = await send(url, "post", body);
  return data;
}
export async function getRefundPolicies() {
  const token = localStorage.getItem('token');
  setHeader('Authorization', `Bearer ${token}`);
  let url = `refundPolicyList`;
  let data = await send(url, "get");
  return data;
}
export async function getSpecialAvailability(groundId) {
  const token = localStorage.getItem('token');
  setHeader('Authorization', `Bearer ${token}`);
  let url = `specialAvailabilityList`;
  let data = await send(url, "post", { groundId: groundId });
  return data;
}
export async function getSpecialPrice(groundId) {
  const token = localStorage.getItem('token');
  setHeader('Authorization', `Bearer ${token}`);
  let url = `specialPriceList`;
  let data = await send(url, "post", { groundId: groundId });
  return data;
}
export async function deleteSpecialAvailability(id) {
  const token = localStorage.getItem('token');
  setHeader('Authorization', `Bearer ${token}`);
  let url = `deletespecialAvailability/${id}`;
  let data = await send(url, "delete");
  return data;
}
export async function deleteSpecialPrice(id) {
  const token = localStorage.getItem('token');
  setHeader('Authorization', `Bearer ${token}`);
  let url = `deletespecialPrice/${id}`;
  let data = await send(url, "delete");
  return data;
}
export async function deletePrice(body) {
  
  const token = localStorage.getItem('token');
  setHeader('Authorization', `Bearer ${token}`);
  let url = `deletePrice`;
  let data = await send(url, "post", body);
  return data;
}
export async function deleteAvailability(body) {
 
  const token = localStorage.getItem('token');
  setHeader('Authorization', `Bearer ${token}`);
  let url = `deleteAvailability`;
  let data = await send(url, "post", body);
  return data;
}
export async function editSpecialAvailability(id, body) {
  const token = localStorage.getItem('token');
  setHeader('Authorization', `Bearer ${token}`);
  let url = `editSpecialAvailability/${id}`;
  let data = await send(url, "post", body);
  return data;
}
export async function editSpecialPrice(id, body) {
  const token = localStorage.getItem('token');
  setHeader('Authorization', `Bearer ${token}`);
  let url = `editSpecialPrice/${id}`;
  let data = await send(url, "post", body);
  return data;
}
export async function editAvailability(body) {
  const token = localStorage.getItem('token');
  setHeader('Authorization', `Bearer ${token}`);
  let url = `editAvailability`;
  let data = await send(url, "post", body);
  return data;
}
export async function editPrice(body) {
  const token = localStorage.getItem('token');
  setHeader('Authorization', `Bearer ${token}`);
  let url = `editPrice`;
  let data = await send(url, "post", body);
  return data;
}
export async function editGround(id, body) {
  const token = localStorage.getItem('token');
  setHeader('Authorization', `Bearer ${token}`);
  let url = `editGround/${id}`;
  let data = await send(url, "post", body);
  return data;
}
export async function deleteGround(id) {
  const token = localStorage.getItem('token');
  setHeader('Authorization', `Bearer ${token}`);
  let url = `deleteGround/${id}`;
  let data = await send(url, "post", {});
  return data;
}

export async function deletePitch(id, body) {
  const token = localStorage.getItem('token');
  setHeader('Authorization', `Bearer ${token}`);
  let url = `deletePitch/${id}`;
  let data = await send(url, "post", body);
  return data;
}
export async function editPitch(id, body) {

  const token = localStorage.getItem('token');
  setHeader('Authorization', `Bearer ${token}`);
  let url = `editPitch/${id}`;
  let data = await send(url, "post", body);
  return data;
}







