import React,{useState} from 'react';
import './createSubAdmin.scss';
import * as Yup from 'yup';
import { Formik, Field, Form } from 'formik';
import 'react-phone-number-input/style.css'
import PhoneInputField from '../../components/phoneInput';
import { SingleImageUploader } from '../../components/workout/addWorkout/singleImageUploader';
import {uploadAdminImage} from './SubAdmins.headless'
import Loader from 'react-loader-spinner';


const subAdminSchema = Yup.object().shape({
  name: Yup.string().trim().required('Name Required'),
  email: Yup.string().trim().email('Invalid email').required('Email Required'),
  mobile: Yup.string().trim().min(7).max(15).required('Mobile Number Required'),
  location: Yup.string().trim().required('Location Required'),
});

export default function CreateSubAdmin({ createUser, data, fromEdit,onCancel }) {
// console.log("data is",data)
const [loading, setLoading] = useState(false);
  const onSubmit = (values) => {
    if(fromEdit) {
      let d = {};
      if(values.name !== data.name) {
        d.name = values.name
      }
      if(values.email !== data.email) {
        d.email = values.email
      }
      if(values.mobile !== data.mobile) {
        d.mobile = values.mobile
      }
      if(values.location !== data.location) {
        d.location = values.location
      }
      if(values.status !== data.status) {
        d.status = values.status
      }
      if (values.image !== data.image) {
        d.image = {uri: values.image}
      }
      console.log("edit val is/",d)
      createUser(d)
    } else {
      let val = {...values};
      val.image = {uri: values.image}
      console.log("val is",val)
      createUser(val)
    }
  }
  const onImageUpload = async (images, isDefaultImageUpload, setFieldValue) => {
    setLoading(true);
    for (let i = 0; i < images.length; i++) {
      let resp = await handleUpload(images[i], isDefaultImageUpload, setFieldValue);
      setFieldValue('image', resp.url ? resp.url : '');
      setLoading(false);
    }
  }

  const handleUpload = async (item, isDefaultImageUpload, setFieldValue) => {
    if (!isDefaultImageUpload) {
      try {
        const resp = await uploadAdminImage(item.file);
        if (resp && resp.data && resp.data.data) {
          let img = resp.data.data;
          return { 'url': img.location, 'id': item.id }
        }
      }
      catch (err) {
        return {}
      }
    }
  }

  const onRemoveImage = (id, setFieldValue) => {
    setFieldValue('image', '');
  }
  
  return (
    <div>
       {loading &&
        <div>
          <Loader
            type="TailSpin"
            color="#000"
            height={30}
            width={30}
            className="loader-parent"
          />
        </div>
      }
      <div className='card-body'>
        <Formik
          initialValues={{
            name: data.name,
            email: data.email,
            mobile: data.mobile,
            location: data.location,
            status: data.status,
            position : data.position,
            userName:"Sub Admin"
          }}
          validationSchema={subAdminSchema}
          onSubmit={onSubmit}
          validateOnBlur={false}
          validateOnChange={false}
          onReset={onCancel}
        >
          {({ errors, touched, isSubmitting, values, setFieldValue, handleSubmit }) => (
            <Form className="form">

              <div className="mb-1 mt-1">
                <label
                  className="form-label"
                  htmlFor="first-name-column"
                >
                  Admin Name
                </label>
                <Field
                  type="text"
                  id="first-name-column"
                  className="form-control"
                  placeholder="Enter admin name"
                  name="name"
                />
                {errors.name && touched.name && (
                  <span class="error text-danger">{errors.name}</span>
                )}
              </div>
              <div className="mb-1 mt-1">
                <label
                  className="form-label"
                  htmlFor="first-name-column"
                >
                  Email
                </label>
                <Field
                  type="text"
                  id="first-name-column"
                  className="form-control"
                  placeholder="Ente mail id"
                  name="email"
                />
                {errors.email && touched.email && (
                  <span class="error text-danger">{errors.email}</span>
                )}
              </div>
              <div className="mb-1 mt-1">
                <label
                  className="form-label"
                  htmlFor="first-name-column"
                >
                  Mobile Number(with country code)
                </label>
                <Field
                  // type='tel'
                  name="mobile"
                  onChange={(val) => { setFieldValue('mobile', val); }}
                  component={PhoneInputField}
                />
                {errors.mobile && touched.mobile && (
                  <span class="error text-danger">{errors.mobile}</span>
                )}
              </div>
              <div className="mb-1 mt-1">
                <label
                  className="form-label"
                  htmlFor="first-name-column"
                >
                  Location
                </label>
                <Field
                  type="text"
                  id="first-name-column"
                  className="form-control"
                  placeholder="Enter location"
                  name="location"
                />
                {errors.location && touched.location && (
                  <span class="error text-danger">{errors.location}</span>
                )}
              </div>
              <div className='col-md-12 col-12 px-0'>
                <SingleImageUploader uploadImage={(images) => { onImageUpload(images, false, setFieldValue) }}
                  removeImage={(id) => { onRemoveImage(id, setFieldValue) }} images={fromEdit ? data.image === '' ? [] : [{ path: data.image }] : []} sizeLimit={204800} />
                {errors.image && touched.image && (
                  <span className="error text-danger">{errors.image}</span>
                )}
              </div>
            
              <div className="mb-1 mt-1">
                <label>
                  <Field type="checkbox" name="status" value={values.status} checked={values.status === 0 ? false : true} onChange={() => { values.status === 0 ? setFieldValue('status', 1) : setFieldValue('status', 0) }} />
                  <span style={{marginLeft: 5}}>Enable this admin</span>
                </label>
              </div>
              <div className="row mt-3">
                <div className="col-12">
                  <button
                    type="submit"
                    className="btn filter-btn mr-3 ml-0 waves-effect waves-float waves-light"
                    disabled={isSubmitting}
                  >
                    Submit
                  </button>
                  <button
                    type="reset"
                    className="btn btn-outline-secondary waves-effect"
                    disabled={isSubmitting}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}