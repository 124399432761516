import { send, setHeader } from "../../utils/api";
export async function getWorkouts(limit, page, search, filter, venueId) {
    let body = {};
    if(venueId) {
        let f = {...filter}
        f.venue = venueId
        body.filter =  f;
    }
    const token = localStorage.getItem('token');
    setHeader('Authorization', `Bearer ${token}`);
    let url = `getWorkouts?size=${limit}&page=${page}`;
    if (search) {
        url += `&search=${search}`;
    }
    let data= await send(url,"post", body);
    return data;
}
export async function addWorkout(body) {
    try {
        const token = localStorage.getItem('token');
        setHeader('Authorization', `Bearer ${token}`);
        let url = `createWorkout`;
        let data = await send(url, "post", body);
        return data;
    }
    catch (err) {
        throw err
    }
}
export async function editWorkout(id, body) {
    let b = {...body};
    b.workoutId = id;
    try {
        const token = localStorage.getItem('token');
        setHeader('Authorization', `Bearer ${token}`);
        let url = `editWorkout/${id}`;
        let data = await send(url, "post", b);
        return data;
    }
    catch (err) {
        throw err
    }
}

export async function deleteWorkout(id) {
    const token = localStorage.getItem('token');
    setHeader('Authorization', `Bearer ${token}`);
    let url = `deleteWorkout/${id}`
    let data= await send(url,"delete");
    return data;
}
export async function uploadWorkoutImage(file) {
    const token = localStorage.getItem('token');
    setHeader('Authorization', `Bearer ${token}`);
    let url = `uploadWorkoutImage`;
    var formdata = new FormData();
    formdata.append("file", file);
    try {
      let data = await send(url, "post", formdata);
      return data;
    } catch (err) {
      throw err;
    }
  };

  export async function getBodyParts() {
    const token = localStorage.getItem('token');
    setHeader('Authorization', `Bearer ${token}`);
    let url = `getBodypartsList`;
    let data = await send(url, "get");
    return data;
  }
  export async function getEquipments() {
    const token = localStorage.getItem('token');
    setHeader('Authorization', `Bearer ${token}`);
    let url = `getEquipmentsList`;
    let data = await send(url, "get");
    return data;
  }





