import React, { useState, useEffect } from "react";
import Resizer from "react-image-file-resizer";
import { Dropdown } from 'react-bootstrap';
import './imageUploader.scss';
import actionIcon from '../../../../assets/svg/actionIcon.svg';

export const ImageEditor = ({ uploadImage, removeImage, changeDefaultImage, images, defaultImageIndex, sizeLimit, reset }) => {
  const [imageList, setImageList] = useState([]);
  const [defaultIndex, setDefaultIndex] = useState(-1);
  const [imageUrls, setImageUrls] = useState(images);

  useEffect(() => {
    if (imageList.length > 0) {
      uploadImage(imageList);
      setImageList([]);
    }
  }, [imageList])

  useEffect(() => {
    if (reset) {
      setImageList([]);
      setDefaultIndex(-1);
      setImageUrls(images);
    }
  }, [reset])

  useEffect(() => {
    if (imageUrls.length > 0 && defaultIndex === -1) {
      changeDefaultImage(imageUrls[defaultImageIndex]);
      setDefaultIndex(defaultImageIndex);
    }
  }, [imageUrls])

  const fileChangedHandler = async (event) => {
    var fileInput = false;
    if (event.target.files.length > 0) {
      fileInput = true;
    }
    if (fileInput) {
      if ((imageUrls.length + event.target.files.length) > 10) {
        alert('You can upload maximum 10 images')
      }
      else {
        let files = { ...event.target.files }
        let list = [...imageList]
        let list1 = [...imageUrls];
        let lastIndex = imageUrls.length;
        for (var key of Object.keys(files)) {
          const file = files[key];
          if (file && file.size > sizeLimit) {
            let reSizedFile = await resizeFile(file);
            if (reSizedFile.size > sizeLimit) {
              alert('File size exceeds limit')
            } else {
              list.push({ 'file': reSizedFile, 'id': lastIndex });
              list1.push({ 'url': URL.createObjectURL(file), 'id': lastIndex, 'file': reSizedFile })
              lastIndex++;
            }
          } else {
            list.push({ 'file': file, 'id': lastIndex });
            list1.push({ 'url': URL.createObjectURL(file), 'id': lastIndex, 'file': file })
            lastIndex++;
          }
        }
        setImageList([...list]);
        setImageUrls([...list1]);
      }
    }
  }

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        300,
        300,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file",
        200,
        200
      );
    });

  const onRemoveImage = (index) => {
    let list = [...imageUrls];
    let item = list[index];
    list.splice(index, 1);
    setImageUrls(list);
    removeImage(item.id);
    if (index < defaultIndex) {
      setDefaultIndex(dIndex => dIndex - 1);
    } else if (index === defaultIndex) {
      if (index === 0) {
        changeDefaultImage(list[0])
      } else {
        changeDefaultImage(list[index - 1]);
        setDefaultIndex(dIndex => dIndex - 1);
      }
    }
  }

  const actionButton = (index) => {
    return (
      <Dropdown className="close image-uploader-close">
        <Dropdown.Toggle variant="default" id="dropdown-basic" className='actionDropdown img-action'>
          <img src={actionIcon} width={30} height={30} />
        </Dropdown.Toggle>
        <Dropdown.Menu className='custom-dropdown'>
          <Dropdown.Item href="#" onClick={(e) => { onRemoveImage(index) }}>
            Delete
          </Dropdown.Item>
          {index !== defaultIndex &&
            <Dropdown.Item href="#" onClick={(e) => { changeDefaultImage(imageUrls[index]); setDefaultIndex(index) }}>Mark as Default</Dropdown.Item>
          }
        </Dropdown.Menu>
      </Dropdown>
    )
  }

  return (
    <>
      <div className="mb-1">
        <label
          className="form-label"
          htmlFor="first-name-column"
        >
          Images
        </label>
        <div class="upload-btn-wrapper">
          <button class="image-upload-btn">Upload Images</button>
          <input type="file" name="myfile" accept='image/*' multiple="multiple" onChange={fileChangedHandler} value="" />
        </div>
      </div>
      <div class="topContainer">
        {imageUrls.map((item, index) => (
          <div class={`img_wrp ${index === defaultIndex ? 'default-image' : ''}`} key={index}>
            <img height="100px" width="100px" src={item.url} class="imgResponsiveMax" alt="" />
            {actionButton(index)}
          </div>
        ))}
      </div>
    </>
  );
}