import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './Feedbacks.scss';
import * as Headless from "./Feedbacks.headless.js";
import {
    useParams,
    NavLink,
    useNavigate
} from "react-router-dom";
import { Dropdown, Image } from 'react-bootstrap';
import Loader from "react-loader-spinner";
import Avatar from 'react-avatar';
import Chart from "react-apexcharts";
import _ from 'lodash';
import dayjs from 'dayjs';
import { getClubName, getCountryName, getLevelName, getPositionName } from '../utils/functions';
import GameIcon from '../../assets/svg/gamesPlayed';
import OrganizedIcon from '../../assets/svg/gamesOrganized';
import TeamIcon from '../../assets/svg/profileTeamIcon';
import AdminIcon from '../../assets/svg/profileTeamAdmin';
import { userProfileChart } from '../utils/chartConfigs';
import { Typography } from '@material-ui/core';
import { Breadcrumbs } from '@material-ui/core';
import { Link } from '@material-ui/core';
import backIcon from '../../assets/img/backicon.png';

export function Feedback(props) {
    const navigate = useNavigate();
    let { id } = useParams();
    const [feedback, setFeedback] = useState(null);
    const [loading, setLoading] = useState(true);

    const getFeedback = async () => {
        setLoading(true);
        try {
            const response = await Headless.getFeedback(id);
            setFeedback(response.data.data.feedback);
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    }


    useEffect(() => {
        getFeedback();
    }, []);

    return (
        <>
            {loading ? <div className="d-flex justify-content-center">
                <Loader
                    type="TailSpin"
                    color="#000"
                    height={30}
                    width={30}
                />
            </div> :
                <>
                    <div role="presentation" className='card p-3 my-2 custom-breadcrumbs'>
                        <div className='row align-items-center'>
                            <div className='p-2' onClick={() => { navigate(-1) }}>
                                <img src={backIcon} width={30} height={30} />
                            </div>
                            <div className='nav-container p-2'>
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Link color="red" onClick={() => { navigate('../../') }} className='custom-breadcrumb-link'>
                                        Home
                                    </Link>
                                    <Link color="inherit" onClick={() => { navigate('../../feedbacks') }}>
                                        Feedbacks
                                    </Link>
                                    <Typography color="text.primary" className='custom-breadcrumb-p'>Feedback Information</Typography>
                                </Breadcrumbs>
                                {/* <div className='mt-1'>
                                    {data.referenceId ? `${data.referenceId} : Details` : ''}
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <div className="card">
                                <div className="card-body">
                                    {/* <div className="text-center mb-3">
                                    <h5 className="mb-3">Feedback Information</h5>
                                </div> */}
                                    <table className="table table-responsive">
                                        <tbody className="no-border">
                                            <tr>
                                                <td><b>Created By</b></td>
                                                {feedback.userId ?
                                                    <td>
                                                        <NavLink to={`../users/${feedback.userId._id}`} className="d-flex">
                                                            {feedback.userId?.name ? feedback.userId.name : 'N/a'}
                                                        </NavLink>
                                                    </td> :
                                                    <td>
                                                        N/a
                                                    </td>
                                                }
                                            </tr>
                                            <tr>
                                                <td>
                                                    <b>Status</b></td>
                                                <td>
                                                    {feedback.active ?
                                                        <span className="badge badge-primary">Active</span> :
                                                        <span className="badge badge-secondary">Inactive</span>
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><b>Email</b></td>
                                                <td>{feedback.email}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Created on</b></td>
                                                <td>{dayjs(feedback.createdAt).format('DD MMM YYYY hh:mm A')}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Device</b></td>
                                                <td>{feedback.device ? feedback.device : 'N/a'}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Operating System</b></td>
                                                <td>{feedback.os ? feedback.os : 'N/a'}</td>
                                            </tr>
                                            <tr>
                                                <td><b>App Version</b></td>
                                                <td>{feedback.version ? feedback.version : 'N/a'}</td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="row mb-3">
                                <div className="col">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex align-items-center">
                                                <h5>Feedback</h5>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <p>{feedback.feedback}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>}
        </>
    );

}