import { send, setHeader } from "../../utils/api";

export async function getPoliciesList(limit, page, search, filter) {
    let body = {
        filter: filter
    }
    const token = localStorage.getItem('token');
    setHeader('Authorization', `Bearer ${token}`);
    let url = `getRefundPolicy?size=${limit}&page=${page}`;
    if (search) {
        url += `&search=${search}`;
    }
    let data = await send(url, "post", body);
    return data;
}

export async function createPolicy(body) {
    try {
        const token = localStorage.getItem('token');
        setHeader('Authorization', `Bearer ${token}`);
        let url = `createRefundPolicy`;
        let data = await send(url, "post", body);
        return data;
    }
    catch (err) {
        throw err
    }
}

export async function editPolicy(id, body) {
    try {
        const token = localStorage.getItem('token');
        setHeader('Authorization', `Bearer ${token}`);
        let url = `editRefundPolicy/${id}`;
        let data = await send(url, "post", body);
        return data;
    }
    catch (err) {
        throw err
    }
}

export async function changePolicyStatus(id, body) {
    const token = localStorage.getItem('token');
    setHeader('Authorization', `Bearer ${token}`);
    let url = `updateRefundPolicyStatus/${id}`
    let data = await send(url, "post", body);
    return data;
}

export async function deletePolicy(id) {
    const token = localStorage.getItem('token');
    setHeader('Authorization', `Bearer ${token}`);
    let url = `deleteRefundPolicy/${id}`
    let data = await send(url, "delete");
    return data;
}




