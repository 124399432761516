
import React from 'react';
import DatePicker from "react-datepicker";
import './components.scss'
const CustomDateRangePicker = ({ startDate, endDate, setStartDate, setEndDate, label, showStartError, showEndError, startError, endError, minDate }) => {
  return (
    <div>
      <label
        className="form-label"
        htmlFor="first-name-column"
      >
        {label}
      </label>
      <div className='row ml-0 date-container'>
        <div className='react-datepicker-wrappers' style={{}}>
          <DatePicker
            selected={startDate != '' ? startDate : ''}
            onChange={(date) => setStartDate(date)}
            dateFormat="d-MMM-yyyy"
            className="date-picker range-picker-min-height"
            minDate={minDate ? minDate : new Date()}
            maxDate={endDate != '' ? endDate : null}
            placeholderText="Choose start date"
          />
          {showStartError &&
            <span className="error text-danger">{startError}</span>
          }
        </div>
        <div className='react-datepicker-wrappers-1' style={{}}>
          <DatePicker
            selected={endDate != '' ? endDate : ''}
            onChange={(date) => setEndDate(date)}
            dateFormat="d-MMM-yyyy"
            className="date-picker range-picker-min-height"
            minDate={startDate != '' ? startDate : minDate ? minDate: new Date()}
            placeholderText="Choose end date"
          />
          {showEndError &&
            <span className="error text-danger">{endError}</span>
          }
        </div>
      </div>
    </div>
  );
};

export default CustomDateRangePicker;