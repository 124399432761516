import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { Formik, Field, Form } from 'formik';
import { DropdownAndInput } from '../../components/dropdownAndInput';

const pitchSchema = Yup.object().shape({
  policies: Yup.array().of(
    Yup.object().shape({
      hours: Yup.string().trim().required('Enter Hour'),
      refundPersentage: Yup.string().trim().required('Enter Percentage'),
    })
  ).min(1, 'Add atleast one policy').required('Add atleast one policy'),
  policyName: Yup.string().trim().required('Policy name Required'),
  status: Yup.boolean().required('Status required')
});

export default function CreatePitch({ createPolicy, data, fromEdit, selectedPitches,onCancel }) {

  const [hours, setHours] = useState([]);
  const [selectedHour, setSelectedHour] = useState([]);
  
  useEffect(() => {
    const arr = [];
    for (let i = 24; i > 0; i--) {
      arr.push({ label: i, value: i })
    }
    setHours(arr);
  }, [])

  const onSubmit = (values) => {
    if (fromEdit) {
      let d = {policies: values.policies};
      if (values.policyName !== data.policyName) {
        d.policyName = values.policyName
      }
      if (values.status !== data.status) {
        d.status = values.status
      }
      createPolicy(d)
    } else {
      createPolicy(values)
    }
  }

  
  const renderPolicyInput = (policies, setFieldValue, errors, touched) => {
    const p = policies.map((item, index) => {
      return <DropdownAndInput
        options={hours}
        selectedOptions={item.hours ? [{value:item.hours, label: item.hours}]: []}
        contentField='refundPersentage'
        titleField='hours'
        value={item}
        onTitleChange={(val) => {
          let data = [...policies];
          data[index].hours = val
          setFieldValue('policies', data);
        }}
        onContentChange={(val) => {
          let data = [...policies];
          data[index].refundPersentage = val;
          setFieldValue('policies', data)
        }}
        onRemoveItem={() => {
          let data = [...policies];
          data.splice(index, 1);
          setFieldValue('policies', data)
        }}
        titlePlaceholder="Choose hour"
        contentPlaceholder="Refund percentage"
        showRemove={index === 0 ? false : true}
        showTitleError={showPolicyTitleError(errors, touched, index)}
        titleError={showPolicyTitleError(errors, touched, index) ? errors.policies[index].hours : ''}
        showContentError={showPolicyContentError(errors, touched, index)}
        contentError={showPolicyContentError(errors, touched, index) ? errors.policies[index].refundPersentage : ''}
      />
    })
    return p;
  }
  const showPolicyTitleError = (errors, touched, index) => {
    if (errors && errors.policies && errors.policies.length > index && touched.policies) {
      if (errors.policies[index] && errors.policies[index].hours) {
        return true
      }
      return false
    }
    return false
  }
  const showPolicyContentError = (errors, touched, index) => {
    if (errors && errors.policies && errors.policies.length > index && touched.policies) {
      if (errors.policies[index] && errors.policies[index].refundPersentage) {
        return true
      }
      return false
    }
    return false
  }
  const onPolicyAdd = (policies, setFieldValue) => {
    let p = [...policies];
    p.push({ hours: '', refundPersentage: '' })
    setFieldValue('policies', p)
  }


  return (
    <div>
      <div className='card-body'>
        <Formik
          initialValues={data}
          validationSchema={pitchSchema}
          onSubmit={onSubmit}
          validateOnBlur={false}
          validateOnChange={false}
          onReset={onCancel}
        >
          {({ errors, touched, isSubmitting, values, setFieldValue, handleSubmit }) => (
            <Form className="form">
              <div className="mb-1 mt-1">
                <label
                  className="form-label"
                  htmlFor="first-name-column"
                >
                  Policy Name
                </label>
                <Field
                  type="text"
                  id="first-name-column"
                  className="form-control"
                  placeholder="Enter policy name"
                  name="policyName"
                />
                {errors.policyName && touched.policyName && (
                  <span class="error text-danger">{errors.policyName}</span>
                )}
              </div>
              <div className="col-md-12 col-12">
                <div className='row'>
                  <div className="col-md-12 col-12 pl-0">
                    <label
                      className="form-label"
                      htmlFor="first-name-column"
                    >
                      Policies
                    </label>
                    {renderPolicyInput(values.policies, setFieldValue, errors, touched)}
                    <div className='row'>
                      <div className='col-md-11 text-right'>
                        <span className='add-more-policy' onClick={() => onPolicyAdd(values.policies, setFieldValue)}>Add More Policies</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-1 mt-1">
                <label>
                  <Field type="checkbox" name="status" value={values.status} checked={!values.status ? false : true} onChange={() => { values.status ? setFieldValue('status', false) : setFieldValue('status', true) }} />
                  <span style={{ marginLeft: 5 }}>Enable this policy</span>
                </label>
              </div>
              <div className="row mt-3">
                <div className="col-12">
                  <button
                    type="submit"
                    className="btn filter-btn mr-3 ml-0 waves-effect waves-float waves-light"
                    disabled={isSubmitting}
                  >
                    Submit
                  </button>
                  <button
                    type="reset"
                    className="btn btn-outline-secondary waves-effect"
                    disabled={isSubmitting}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}