import * as React from "react"

function DashboardIcon(props) {
  return (
    <svg
      width={20}
      height={21}
      viewBox="0 0 20 21"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#prefix__clip0)">
        <path d="M16.98 7.548a.688.688 0 00-.881-.084c-1.039.717-6.249 4.323-6.809 4.89a2.131 2.131 0 000 2.983 2.063 2.063 0 002.946 0c.56-.567 4.12-5.844 4.828-6.896a.71.71 0 00-.083-.893zm-5.726 6.795a.689.689 0 01-.982 0 .71.71 0 010-.994c.218-.218 1.697-1.287 3.517-2.568-1.265 1.843-2.32 3.342-2.535 3.562z" />
        <path d="M5.079 17.116l-.857.424a7.643 7.643 0 01-.468-6.39l.982.477a.693.693 0 00.926-.33.707.707 0 00-.326-.938l-.97-.471c1.206-1.99 3.29-3.378 5.703-3.604v1.058c0 .388.31.703.694.703a.699.699 0 00.694-.703V6.284c.827.076 1.627.29 2.387.637.35.16.76.002.918-.352a.707.707 0 00-.347-.93 8.723 8.723 0 00-3.652-.793A8.771 8.771 0 004.48 7.482a9 9 0 00-2.603 6.364c0 1.776.521 3.504 1.506 4.996a.692.692 0 00.881.241l1.423-.703a.707.707 0 00.32-.94.69.69 0 00-.928-.324zM18.865 10.146a.69.69 0 00-.918-.351.707.707 0 00-.347.93c.438.983.66 2.033.66 3.12 0 1.298-.33 2.565-.959 3.7l-.894-.432a.69.69 0 00-.926.333.707.707 0 00.329.937l1.457.703a.692.692 0 00.876-.244 9.047 9.047 0 001.505-4.996 9.028 9.028 0 00-.783-3.7z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path
            transform="translate(1.877 3)"
            d="M0 0h17.771v18H0z"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default DashboardIcon
