import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import './Home.scss';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    NavLink,
    useLocation,
} from "react-router-dom";
import { Dashboard } from "../dashboard/Dashboard";
import { Feedbacks } from "../feedbacks/Feedbacks"
import { Feedback } from "../feedbacks/Feedback"



import { menus } from '../slices/auth.slice';
import logo from '../../assets/img/logo-black.png';
import logoSmall from '../../assets/svg/logo_bare.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import ProfileDropDown from '../components/ProfileDropDown';
import DashboardIcon from '../../assets/svg/DashboardIcon';
import UserIcon from '../../assets/svg/UserIcon';
import WorkoutIcon from '../../assets/svg/WorkoutIcon';
import TeamIcon from '../../assets/svg/TeamIcon';
import FeedsIcon from '../../assets/svg/FeedsIcon';
import FeedIcon from '../../assets/svg/feedIcon.png'
import FeedbackIcon from '../../assets/svg/FeedbackIcon';
import DropdownIcon from '../../assets/svg/DropdownIcon';
import EllipseIcon from '../../assets/svg/EllipseIcon';
import ReportsIcon from '../../assets/svg/reportsIcon';
import PitchBookingIcon from '../../assets/svg/pitchBookingIcon';
import ManagementIcon from '../../assets/svg/managementIcon';

import { SubMenu } from './subMenu';
import { PopupBanner } from '../management/popupBanner/popupBanner';
import { Highlights } from '../management/highlights/highlights';
import { StudioTypes } from '../management/studioTypes/studioTypes';
import {Policies} from '../management/refundPolicy/policy';
import {Taxes} from '../management/tax/taxes';
import { SubAdmins } from '../management/subAdmins/SubAdmins';
import { SubAdminDetails } from '../management/subAdmins/SubAdminDetails';
import { AdminLogs } from '../management/adminLogs/AdminLogs';
import { StudioList } from '../management/studioList/StudioList';
import { Owners } from '../studioManagement/owners/Owners';
import { OwnerDetails } from '../studioManagement/owners/OwnerDetails';
import { Users } from '../users/Users';
import { BodyParts } from '../workout/bodyParts/bodyParts';
import { Equipments } from '../workout/equipments/equipments';
import { Workouts } from '../workout/workouts/Workouts';
import { Studios } from '../studioManagement/studio/studios';
import { CreateStudio } from '../studioManagement/studio/createStudio';
import { EditStudio } from '../studioManagement/studio/editStudio';
export function Home(props) {
    const [isReportsActive, setIsReportsActive] = useState(false);
    const [isWorkoutsActive,setIsWorkoutsActive] = useState(false);
    const [isOwnerManagerActive, setIsOwnerManagerActive] = useState(false);
    const [isUserManagerActive, setIsUserManagerActive] = useState(false);
    const [isAdminManagerActive, setIsAdminManagerActive] = useState(false);
    const [isStudioManagerActive, setIsStudioManagerActive] = useState(false);
    const [hideCanvas, setHideCanvas] = useState(true);
    const menuItems = useSelector(menus);
    useEffect(() => {
        if (window.location.pathname.includes("/reports/")) {
            setIsReportsActive(true);
        }
        if (window.location.pathname.includes("/ownerManager/")) {
            setIsOwnerManagerActive(true);
        }
        if (window.location.pathname.includes("/adminManager/")) {
            setIsAdminManagerActive(true);
        }
        if (window.location.pathname.includes("/userManager/")) {
            setIsUserManagerActive(true);
        }
        if (window.location.pathname.includes("/studioManager/")) {
            setIsStudioManagerActive(true);
        }
        setHideCanvas(!hideCanvas)
    }, [])

    const menuComponents = {
        "dashboard": <Dashboard/>,
        "Highlights": <Highlights />,
        "StudioTypes":<StudioTypes/>,
        "workout":<Feedbacks/>,
        "feed":<Feedbacks/>,
        "feedbacks":<Feedbacks/>,
        "feedbackDetails": <Feedback />,
        "OwnerReports": <Feedbacks />,
        "AdminsReports": <Feedbacks />,
        "StudioReports": <Feedbacks />,
        "Owners": <Feedbacks />,
        "OwnerManagement": <Feedbacks />,
        "Admins": <Feedbacks />,
        "Studios": <Feedbacks />,
        "Users": <Feedbacks />,
        "refundPolicy":<Policies/>,
        "tax" : <Taxes/>,
        "SubAdmins":<SubAdmins/>,
        "SubAdminDetails":<SubAdminDetails/>,
        "ownerLogs" : <AdminLogs/>,
        "studioOwners" : <Owners/>,
        "studioOwnerDetails" : <OwnerDetails/>,
        "popupBanner" : <PopupBanner/>,
        "users":<Users/>,
        "studioList":<StudioList/>,
        "Workouts":<Workouts/>,
        "BodyParts":<BodyParts/>,
        "Equipments":<Equipments/>,
        "registeredStudios":<Studios/>,
        "newStudio":<CreateStudio/>,
        "editStudio": <EditStudio/>

        
        // "ownerLogs" : <OwnerLists/>

    };

    const renderIcon = (val) => {
        switch (val) {
            case 'Workouts':
                return <WorkoutIcon width={20} height={20} />;
            case 'Feedback':
                return <FeedbackIcon width={20} height={20} />;
            case 'Feeds':
                return <FeedsIcon width={20} height={20} />;
            case 'Reports':
                return <ReportsIcon width={20} height={20} />;
            case 'Trainers':
                return <TeamIcon width={20} height={20} />;
            case 'Users':
                return <UserIcon width={20} height={20} />;
            case 'Admin':
                return <ManagementIcon width={20} height={20} />;
            case 'Owners':
                return <PitchBookingIcon width={20} height={20} />;
            case 'Management':
                return <ManagementIcon width={20} height={20} />;
            case 'ReportDropdown':
                return <DropdownIcon isReportsActive={isReportsActive} />
            case 'AdminManagerDropdown':
                return <DropdownIcon isReportsActive={isAdminManagerActive} />
            case 'OwnerManagerDropdown':
                return <DropdownIcon isReportsActive={isOwnerManagerActive} />
            case 'UserManagerDropdown':
                return <DropdownIcon isReportsActive={isUserManagerActive} />
            case 'StudioManagerDropdown':
                return <DropdownIcon isReportsActive={isStudioManagerActive} />
            case 'WorkoutsDropdown' : 
                return <DropdownIcon isReportsActive={isWorkoutsActive} />
            case 'SubMenu':
                return <EllipseIcon width={23} height={23} />;
            case 'SubMenuActive':
                return <EllipseIcon width={23} height={23} />;
            
            default:
                return <DashboardIcon width={20} height={20} />;
        }
    }

    function renderMenus() {
        const menus = [];
        menuItems.forEach((item, index) => {
            if (!item.detail)
                if (item.link === '') {
                    menus.push(<li key={`navlink-${index}`} className="nav-item" onClick={() => {
                        setIsReportsActive(false)
                        setIsAdminManagerActive(false);
                        setIsOwnerManagerActive(false);
                        setIsStudioManagerActive(false);
                        setIsUserManagerActive(false);
                        setIsWorkoutsActive(false)
                    }}>
                        <NavLink className="nav-link" activeClassName="active" aria-current="page" exact to={`/${item.link}`}>
                            {renderIcon(item.text)}
                            <span className="menu-title">{item.text}</span>
                        </NavLink>
                    </li>);
                } else {
                    menus.push(<li key={`navlink-${index}`} className="nav-item">
                        {item.submenu && item.submenu.length > 0 ?


                            item.text === 'Reports' ?
                                <>
                                    <NavLink className={isReportsActive ? "nav-link active-grey" : "nav-link"} activeClassName="active" aria-current="step" exact
                                        to={`/${item.link}`}
                                        onClick={(e) => {
                                            setIsReportsActive(!isReportsActive);
                                            setIsAdminManagerActive(false);
                                            setIsOwnerManagerActive(false);
                                            setIsStudioManagerActive(false);
                                            setIsUserManagerActive(false);
                                            setIsWorkoutsActive(false)
                                            if (item.text === 'Reports') {
                                                e.preventDefault()
                                            }
                                        }}
                                    >
                                        {renderIcon(item.text)}
                                        <span className="menu-title">{item.text}</span>
                                        {item.submenu && item.submenu.length > 0 ?
                                            renderIcon('ReportDropdown') : null
                                        }

                                    </NavLink>
                                    {isReportsActive ? renderSubMenu(item.submenu)
                                        : null}
                                </>
                                :
                                item.text === 'Studios' ?
                                    <>
                                        <NavLink className={isStudioManagerActive ? "nav-link active-grey" : "nav-link"} activeClassName="active" aria-current="step"
                                            to={`/${item.link}`} exact
                                            onClick={(e) => {
                                                setIsOwnerManagerActive(false);
                                                setIsReportsActive(false)
                                                setIsAdminManagerActive(false);
                                                setIsStudioManagerActive(!isStudioManagerActive);
                                                setIsUserManagerActive(false);
                                                setIsWorkoutsActive(false)
                                                if (item.text === 'Studios') {
                                                    e.preventDefault()
                                                }
                                            }}
                                        >
                                            {renderIcon(item.text)}
                                            <span className="menu-title">{item.text}</span>
                                            {item.submenu && item.submenu.length > 0 ?
                                                renderIcon('StudioManagerDropdown') : null
                                            }
                                        </NavLink>
                                        {isStudioManagerActive ? renderSubMenu(item.submenu)
                                            : null}
                                    </>
                                    :item.text === "Workouts" ?
                                    <>
                                    <NavLink className={isWorkoutsActive ? "nav-link active-grey" : "nav-link"} activeClassName="active" aria-current="step"
                                        to={`/${item.link}`} exact
                                        onClick={(e) => {
                                            setIsAdminManagerActive(false)
                                            setIsOwnerManagerActive(false);
                                            setIsReportsActive(false)
                                            setIsStudioManagerActive(false);
                                            setIsUserManagerActive(false);
                                            setIsWorkoutsActive(!isWorkoutsActive)
                                            if (item.text === 'Workouts') {
                                                e.preventDefault()
                                            }
                                        }}
                                    >
                                        {renderIcon(item.text)}
                                        <span className="menu-title">{item.text}</span>
                                        {item.submenu && item.submenu.length > 0 ?
                                            renderIcon('AdminManagerDropdown') : null
                                        }
                                    </NavLink>
                                    {isWorkoutsActive ? renderSubMenu(item.submenu)
                                        : null}
                                </>
                                    : item.text === 'Management' ?
                                        <>
                                            <NavLink className={isAdminManagerActive ? "nav-link active-grey" : "nav-link"} activeClassName="active" aria-current="step"
                                                to={`/${item.link}`} exact
                                                onClick={(e) => {
                                                    setIsAdminManagerActive(!isAdminManagerActive)
                                                    setIsOwnerManagerActive(false);
                                                    setIsReportsActive(false)
                                                    setIsStudioManagerActive(false);
                                                    setIsUserManagerActive(false);
                                                    setIsWorkoutsActive(false)
                                                    if (item.text === 'Management') {
                                                        e.preventDefault()
                                                    }
                                                }}
                                            >
                                                {renderIcon(item.text)}
                                                <span className="menu-title">{item.text}</span>
                                                {item.submenu && item.submenu.length > 0 ?
                                                    renderIcon('AdminManagerDropdown') : null
                                                }
                                            </NavLink>
                                            {isAdminManagerActive ? renderSubMenu(item.submenu)
                                                : null}
                                        </>
                                        : <></>
                            : <NavLink className="nav-link" activeClassName="active" aria-current="step"
                                to={`/${item.link}`} exact
                                onClick={(e) => {
                                    setIsAdminManagerActive(false)
                                    setIsOwnerManagerActive(false);
                                    setIsReportsActive(false)
                                    setIsStudioManagerActive(false);
                                    setIsUserManagerActive(false);
                                }}
                            >
                                {renderIcon(item.text)}
                                <span className="menu-title">{item.text}</span>
                            </NavLink>
                        }
                    </li >);
                }
        });
        return menus;
    }
    function renderSubMenu(subMenuItems) {
        const subMenus = [];
        subMenuItems.forEach((item, index) => {
            if (!item.detail) {
                subMenus.push(
                // <li key={`navlink-submenu-${index}`} className="nav-item">
                //     <NavLink className="nav-link sub-menu" activeClassName="active" aria-current="page" to={`/${item.link}`} exact>
                //         <img src={window.location.pathname.includes(item.link) ? RadioChecked : RadioUnchecked} width={20} height={30} />
                //         <span className="menu-title">{item.text}</span>
                //     </NavLink>
                // </li>
                <SubMenu index={index} item={item}/>
                );
            }
        })
        return subMenus
    }

    function renderMenuContents() {
        let menuContents = [];
        menuItems.forEach((item, index) => {
            if (item.submenu && item.submenu.length > 0) {
                item.submenu.forEach((i, ind) => {
                    menuContents.push(
                        <Route key={'sub-menu' + ind} exact path={`/${i.link}`} element={menuComponents[i.component]} />
                    )
                })
                menuContents.push(
                    <Route key={index} exact path={`/${item.link}`} element={menuComponents[item.component]} />
                )
            } else {
                menuContents.push(
                    <Route key={index} exact path={`/${item.link}`} element={menuComponents[item.component]} />
                )
            }
        });
        return menuContents;
    }
    return (
        <>
            <nav className="navbar theme-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
                <div className="text-left navbar-brand-wrapper d-flex align-items-center justify-content-between">
                    <a className="navbar-brand brand-logo" href="index.html"><img src={logo} alt="logo" /></a>
                    <a className="navbar-brand brand-logo-mini" href="index.html"><img src={logoSmall} alt="logo" /></a>
                    <button className="navbar-toggler align-self-center" type="button" data-toggle="minimize">
                        <span className="mdi mdi-menu"></span>
                    </button>
                </div>
                <div className="navbar-menu-wrapper d-flex align-items-center justify-content-between">

                    <ul className="navbar-nav d-flex icon-bar">
                        <span style={{ alignSelf: 'center' }} className="menu-title">{"Customize, Commit, Conquer"}</span>
                    </ul>
                    <div className='d-flex align-items-center justify-content-end'>
                    <ul className="navbar-nav navbar-nav-right icon-bar">
                        <ProfileDropDown />
                    </ul>
                    <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="offcanvas" onClick={() => { setHideCanvas(!hideCanvas) }}>
                        <FontAwesomeIcon icon={faBars} />
                    </button>
                    </div>
                </div>
            </nav>
 
            <div className="container-fluid page-body-wrapper">
                <Router>
                    <nav className={`sidebar sidebar-offcanvas ${!hideCanvas ? 'sidebar-hidecanvas' : ''}`} id="sidebar">
                        <p className="sidebar-label">Menu</p>
                        <ul className="nav">
                            {renderMenus()}
                        </ul>
                    </nav>

                    <main className="main-panel">
                        <div className="content-wrapper">
                            <Routes>
                                {
                                    renderMenuContents()
                                }
                            </Routes>
                        </div>
                    </main>
                </Router>
            </div>
        </>
    );
};

