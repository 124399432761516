import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './Users.scss';
import * as Headless from "./Users.headless.js";
import {
  NavLink,
  useParams
} from "react-router-dom";
import { Dropdown, Image } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import Loader from "react-loader-spinner";
import Avatar from 'react-avatar';
import _ from 'lodash';
import dayjs from 'dayjs';
import Sidebar from "react-sidebar";
import { MultiSelect } from "react-multi-select-component";
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Pagination from '../components/Pagination';
import {
  getLevelName, 
  getPositionName, 
  getPositions,
  getLevels,
  getCountries,
  getDialcodes,
  validateNumber,
} from '../utils/functions';
import { customTableStyles } from '../utils/styles'

export function Users(props) {
  let { id } = useParams();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [total, setTotal] = useState(0);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [openFilter, setOpenFilter] = useState(false)
  const [selectedPositions, setSelectedPositions] = useState([]);
  const [selectedLevels, setSelectedLevels] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [locationText, setLocationText] = useState('');
  const [NameText, setNameText] = useState('');
  const [MobText, setMobText] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [filters, setFilters] = useState({})
  const [clearclub, setClearClub] = useState(false);

  const levels = getLevels();
  const countries = getCountries()
  const positions = getPositions();
  const dialcodes = getDialcodes()
  // const pitchTypes = getPitchTypes()


  const columns = [
    {
      name: <th>#</th>,
      selector: (row, index) => index + (limit * (page - 1)) + 1,
      cell: (row, index) =>
        <td>{index + (limit * (page - 1)) + 1}</td>,
      grow: 0.05,
      width: '60px'
    },
    {
      name: <th>{`NAME & POSITION`}</th>,
      selector: row => row.name ? row.name : 'Incomplete Profile',
      sortable: true,
      cell: (row) =>
        <td>
          <NavLink to={`${row._id}`} className="d-flex" style={{ paddingTop: 10, paddingBottom: 10 }}>
            {row.profileImage ?
              <Image src={row.profileImage} width={40} height={40} roundedCircle />
              : <Avatar color={row.name ? '' : '#CECCCB'} name={row.name ? row.name : 'N / a'} round size={40} />}
            <div className="username">
              <p style={row.name ? {} : { color: '#CECCCB' }}>{row.name ? row.name : 'Incomplete Profile'}</p>
              {row.name ?
                <div className='row align-items-center mt-1'>
                  {/* <div className="position"/> */}
                  <span className='ml-3'>{getPositionName(row.position)}</span> 
                </div>: null

              }
            </div>
          </NavLink>
        </td>,
      grow: 2,
    },
    {
      name: <th>LOCATION</th>,
      selector: row => row.location ? row.location : 'N/a',
      cell: (row) =>
        <td style={row.name ? {} : { color: '#CECCCB' }}>
          {row.location ? row.location : 'N/a'}
        </td>,
      sortable: true,
      grow: 1.5
    },
    {
      name: <th>LEVEL</th>,
      selector: row => getLevelName(row.level),
      sortable: true,
      cell: (row) =>
        <td className='row' style={row.name ? {} : { color: '#CECCCB' }}>
          {getLevelName(row.level)}
        </td>,
    },
   
    {
      name: <th>REG. DATE</th>,
      selector: row => row.createdAt,
      sortable: true,
      cell: (row) =>
        <td style={row.name ? {} : { color: '#CECCCB' }}>
          {dayjs(row.createdAt).format('DD MMM YYYY hh:mm A')}
        </td>,
      grow: 2
    },

  ];

  const getUsers = async (l, p, s, filter) => {
    setLoading(true);
    try {
      const response = await Headless.getUsers(l, p, s, filter);
      setPages(response.data.data.pages);
      setTotal(response.data.data.total);
      setUsers(response.data.data.users);
      setPage(response.data.data.page)
      setLoading(false);
    } catch (err) {
      setPages(1);
      setTotal(0);
      setUsers([]);
      setLoading(false);
    }
  }

  const onSearch = _.debounce(setSearch, 500);

  useEffect(() => {
    getUsers(limit, 1, search, filters);
  }, [limit])

  useEffect(() => {
    getUsers(limit, 1, search, filters);
  }, [search])

  useEffect(() => {
    getUsers(limit, 1, search, filters);
  }, [filters])

  const onToggleLimit = (e, value) => {
    e.preventDefault();
    setLimit(value);
  };

  const changePage = (num) => {
    getUsers(limit, num, search, filters);
  }

  const setMobile = async(value) => {
    let valid = await validateNumber(value)
    if (valid) {
      setMobText(value)
    }
  }

  const onPressFilter = () => {
    let filter = {}
    if (startDate != '' && endDate != '') {
      filter['startDate'] = startDate.toISOString()
      filter['endDate'] = endDate.toISOString()
    }
    
    if (selectedPositions.length > 0) {
      let pos = selectedPositions.map((item) => {
        return item.value;
      })
      filter['positions'] = pos;
    }

    if (selectedLevels.length > 0) {
      let lev = selectedLevels.map((item) => {
        return item.value;
      })
      filter['levels'] = lev;
    }

    if (locationText != '') {
      filter['location'] = locationText
    }

    if (NameText != '') {
      filter['name'] = NameText
    }

    if (MobText != '') {
      filter['mobile'] = MobText
    }

    if (selectedStatus != '') {
      filter['status'] = selectedStatus
    }

    if (selectedCountry.length > 0) {
      let cntry = selectedCountry.map((item) => {
        return item.value;
      })
      filter['dialCode'] = cntry;
    }
    setFilters(filter)
    setOpenFilter(false)
  };

  const onPressClear = () => {
    setStartDate('')
    setEndDate('')
    setSelectedPositions([])
    setSelectedLevels([])
    setSelectedCountry([]);
    setSelectedStatus('');
    setLocationText('');
    setNameText('');
    setMobText('');
    setClearClub(!clearclub)
    setFilters({});
  }


  const handleRadio = (value) => {
   
    if (value != selectedStatus) {
      setSelectedStatus(value)
    } else {
      setSelectedStatus('')
    }
  }

  function filter() {
    return (
      <div style={{ width: 260, height: '100%' }}>
        {openFilter &&
          <div
            className="filter-close"
            onClick={() => setOpenFilter(false)}
          />
        }
        <div className="ml-3 slider-container">
          <p style={{ fontSize: 20 }} className="mb-0 mt-3">Filter</p>
          <div style={{ width: 100, height: 3, overflow: 'hidden', backgroundColor: 'gray' }}></div>
          <p className="mb-0 mt-3">Date Range</p>
          <div className='row ml-0'>
            <div style={{ width: '45%', overflow: 'hidden' }}>
              <DatePicker
                selected={startDate != '' ? startDate: new Date().setMonth(new Date().getMonth() - 1)}
                onChange={(date) => setStartDate(date)}
                dateFormat="d-MMM-yyyy"
                className="date-picker"
                maxDate={endDate ? endDate : new Date()}
              />
            </div>
            <div style={{ width: '45%', overflow: 'hidden', marginLeft: 10 }}>
              <DatePicker
                selected={endDate != '' ? endDate: new Date()}
                onChange={(date) => setEndDate(date)}
                dateFormat="d-MMM-yyyy"
                className="date-picker"
                minDate={startDate ? startDate : new Date().setMonth(new Date().getMonth() - 1)}
              />
            </div>
          </div>

          <p className="mb-0 mt-3">Location</p>
          <input
            className="form-control inputArea"
            placeholder="Location"
            onChange={(e) => setLocationText(e.target.value)}
            value={locationText}
          />

          <p className="mb-0 mt-3">Name</p>
          <input
            className="inputArea form-control"
            placeholder="Name"
            onChange={(e) => setNameText(e.target.value)}
            value={NameText}
          />

          <p className="mb-0 mt-3">Mobile Number</p>
          <input
            className="form-control inputArea"
            placeholder="Mobile Number"
            onChange={(e) => setMobile(e.target.value)}
            value={MobText}
          />

          <p className="mb-0 mt-3">Position</p>
          <MultiSelect
            options={positions}
            value={selectedPositions}
            hasSelectAll={false}
            onChange={setSelectedPositions}
            labelledBy="Select position"
          />

          <p className="mb-0 mt-3">Level</p>
          <MultiSelect
            options={levels}
            value={selectedLevels}
            hasSelectAll={false}
            onChange={setSelectedLevels}
            labelledBy="Select level"
          />
        
          <p className="mb-0 mt-3">Country</p>
          <MultiSelect
            options={dialcodes}
            value={selectedCountry}
            hasSelectAll={false}
            onChange={setSelectedCountry}
            labelledBy="Select Country"
          />
          <p className="mb-0 mt-3">User Status</p>
          <div className="radiobtn" onClick={(val)=> handleRadio(val.target.value)}>
            <span>
              <input 
                type="radio" className='mr-1' value='0' name= 'status' checked={selectedStatus != '' && selectedStatus == '0'} />
                Active
            </span>
            <span>
              <input type="radio" className='mr-1' value="1" name= 'status' checked={selectedStatus != '' && selectedStatus == '1'} /> 
              Not Completed
            </span>
            <span>
              <input type="radio" className='mr-1' value="2" name= 'status' checked={selectedStatus != '' && selectedStatus == '2'} /> 
              Deleted
            </span>
          </div>
          <div className=" ml-1 row">
            <div
              className="filter-submit mt-3"
              onClick={onPressFilter}
            >
              <p className="ml-3 mr-3 mt-3 mb-3">Filter</p>
            </div>
            <div
              className="filter-clear mt-3"
              onClick={onPressClear}
            >
              <p className="ml-3 mr-3 mt-3 mb-3">Clear</p>
            </div>
          </div>
        </div>
      </div>
    )
  }


  return (
    <div className="card h-100">
      <Sidebar
        pullRight
        sidebar={filter()}
        open={openFilter}
        styles={{
          sidebar:
          {
            background: "white",
            overflow: "visible",
            zIndex: 20
          },
          overlay:
          {
            backgroundColor: "transparent"
          }
        }}
      >
        <div className="card-body">
          <div className="d-flex align-items-center table-header-menu justify-content-between">
            <div className="d-flex align-items-center">
              <p className="mr-3">Show</p>
              <p className="mr-3">{limit}</p>
              <Dropdown>
                <Dropdown.Toggle variant="default" id="dropdown-basic">
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href="#" onClick={(e) => onToggleLimit(e, 10)}>10</Dropdown.Item>
                  <Dropdown.Item href="#" onClick={(e) => onToggleLimit(e, 25)}>25</Dropdown.Item>
                  <Dropdown.Item href="#" onClick={(e) => onToggleLimit(e, 50)}>50</Dropdown.Item>
                  <Dropdown.Item href="#" onClick={(e) => onToggleLimit(e, 100)}>100</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="d-flex align-items-center">
              <p className="mr-3">Search</p>
              <input className="form-control" placeholder="Search" onChange={(e) => onSearch(e.target.value.trim())} />
              <span className="btn filter-btn" onClick={() => setOpenFilter(true)}>
                Filter
                {Object.keys(filters).length > 0 ? 
                  <span>
                    &#9733;
                  </span>
                : null}
              </span>
            </div>
          </div>
          {!loading && users.length > 0?
            <div className="dataHeader">
                <p className="mr-3">
                  Showing {page > 1 ? (page - 1) * limit : 1} to {page * limit < total ? page * limit : total} of {total} entries { Object.keys(filters).length > 0 ? `(filtered)`: ''}
                </p>
            </div>
          :null}
    
          <div className="table-responsive">
            {loading ?
              <div className="d-flex justify-content-center">
                <Loader
                  type="TailSpin"
                  color="#000"
                  height={30}
                  width={30}
                />
              </div> :
              <DataTable
                columns={columns}
                data={users}
                customStyles={customTableStyles}
              />
            }
          </div>
        </div>
        {!loading && users.length > 0?
          <div className="d-flex card-footer align-items-center justify-content-between">
            <p className="page-data-count">
              Showing {page > 1 ? (page - 1) * limit : 1} to {page * limit < total ? page * limit : total} of {total} entries
            </p>
            <div>
              <Pagination pages={pages} current={page} onChange={changePage} />
            </div>
          </div>
        :null}
      </Sidebar>
    </div>
  );

}