import React from 'react';
import { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import './components.scss'
const AlertDisplay = (props) => {
   
  return (
    <>
   { props.alert&&props.show&&<Alert variant={props.alert.type} onClose={() => props.setShow(false)} dismissible>
      <p className="alert-para">
        {props.alert.message}
      </p>
    </Alert>}
    </>
  );
};

export default AlertDisplay;
