import { send, setHeader } from "../../utils/api";

export async function getTaxList(limit, page, search, filter) {
    let body = {
        filter: filter
    }
    const token = localStorage.getItem('token');
    setHeader('Authorization', `Bearer ${token}`);
    let url = `getTaxes?size=${limit}&page=${page}`;
    if (search) {
        url += `&search=${search}`;
    }
    let data = await send(url, "post", {});
    return data;
}

export async function createTax(body) {
  
    try {
        const token = localStorage.getItem('token');
        setHeader('Authorization', `Bearer ${token}`);
        let url = `createTax`;
        let data = await send(url, "post", body);
        return data;
    }
    catch (err) {
        throw err
    }
}

export async function editTax(id, body) {

    try {
        const token = localStorage.getItem('token');
        setHeader('Authorization', `Bearer ${token}`);
        let url = `editTax/${id}`;
        let data = await send(url, "post", body);
        return data;
    }
    catch (err) {
        throw err
    }
}

export async function deleteTax(id) {
    const token = localStorage.getItem('token');
    setHeader('Authorization', `Bearer ${token}`);
    let url = `deleteTax/${id}`
    let data= await send(url,"delete");
    return data;
}



