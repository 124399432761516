import React from 'react';
import {Modal, Button} from 'react-bootstrap';

function ConfirmPopup({show, message, submitButtonText, cancelButtonText, handleConfirm, handleDismiss}) {
    return (
      <Modal show={show} onHide={handleDismiss} centered>
        <Modal.Body>{message}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleDismiss}>
           {cancelButtonText}
          </Button>
          <Button variant="primary" className='modalConfirm' onClick={handleConfirm}>
            {submitButtonText}
          </Button>
        </Modal.Footer>
      </Modal>
    )
}
export default ConfirmPopup;

