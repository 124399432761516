import * as React from "react"

function FeedbackIcon(props) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M 2.5 2 C 1.6774686 2 1 2.6774686 1 3.5 L 1 11.5 C 1 12.322531 1.6774686 13 2.5 13 L 4 13 L 4 15.433594 L 7.6523438 13 L 12.5 13 C 13.322531 13 14 12.322531 14 11.5 L 14 3.5 C 14 2.6774686 13.322531 2 12.5 2 L 2.5 2 z M 2.5 3 L 12.5 3 C 12.781469 3 13 3.2185314 13 3.5 L 13 11.5 C 13 11.781469 12.781469 12 12.5 12 L 7.3476562 12 L 5 13.566406 L 5 12 L 2.5 12 C 2.2185314 12 2 11.781469 2 11.5 L 2 3.5 C 2 3.2185314 2.2185314 3 2.5 3 z M 4 7 L 4 8 L 5 8 L 5 7 L 4 7 z M 7 7 L 7 8 L 8 8 L 8 7 L 7 7 z M 10 7 L 10 8 L 11 8 L 11 7 L 10 7 z" 
      />
    </svg>
  )
}

export default FeedbackIcon