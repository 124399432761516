import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Avatar from 'react-avatar';
import userImg from '../../assets/img/user.png';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { logout, user } from '../slices/auth.slice';
import { logout as logoutApi } from '../home/Home.headless';

function ProfileDropDown(props) {
    const dispatch = useDispatch();
    const me = useSelector(user);
    const [open, setOpen] = useState(false);
    const onOpen = (e) => {
        e.preventDefault();
        setOpen(!open);
    }

    const onLogout = (e) => {
        logoutApi().then(() => {
            dispatch(logout());
            window.location = '/';
        }).catch((err) => {
        })
    }

    return (
        <li className="nav-item nav-user-icon ml-4">
            <div>
                <p className="dash-name mb-0">{me?.name}</p>
                <h6 className="dash-role">{me.userName}</h6>
            </div>
            <a className="nav-link ml-2" href="#" onClick={onOpen}>
                <Avatar src={me?.image?.uri} name={me?.name} round size={38} />
            </a>
            <div aria-labelledby="profileDropdown" className={open ? "dropdown-menu nav-profile-dropdown-menu show" : "dropdown-menu nav-profile-dropdown-menu"}>
                <div className="dropdown-header d-flex flex-column align-items-center">
                    <div className="figure mb-3">
                        <Avatar src={me?.image?.uri} name={me?.name} round size={38} />
                    </div>
                    <div className="info text-center">
                        <p className="name font-weight-bold mb-0">{me?.name}</p>
                        <p className="email text-muted mb-3">Admin</p>
                    </div>
                </div>
                <div className="dropdown-body">
                    <ul className="profile-nav p-0 pt-3">
                        <li className="nav-item">
                            <a href="" className="nav-link" onClick={onLogout}>
                                <FontAwesomeIcon icon={faLock} />
                                <span>Log Out</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </li>
    )
}

ProfileDropDown.propTypes = {

}

export default ProfileDropDown

