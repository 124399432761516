import * as React from "react"

function DropdownIcon(props) {
    const style = {
        transform: !props.isReportsActive ? 'rotate(-90deg)' : '', 
        transition: 'transform 150ms ease',
    }
    return (
        // <div class="submenu-dropdown">
            <svg width="16" height="15" viewBox="0 0 16 15" xmlns="http://www.w3.org/2000/svg" style={style} >
            <path d="M12.4666 5.99751L8.46665 9.70732C8.33331 9.83098 8.19998 9.89281 7.99998 9.89281C7.79998 9.89281 7.66665 9.83098 7.53331 9.70732L3.53331 5.99751C3.26665 5.75019 3.26665 5.37921 3.53331 5.13189C3.79998 4.88457 4.19998 4.88457 4.46665 5.13189L7.99998 8.40889L11.5333 5.13189C11.8 4.88457 12.2 4.88457 12.4666 5.13189C12.7333 5.37921 12.7333 5.75019 12.4666 5.99751Z" />
            <mask id="mask0" style={{ "mask-type": "alpha" }} maskUnits="userSpaceOnUse" x="3" y="4" width="10" height="6">
                <path d="M12.4666 5.99751L8.46665 9.70732C8.33331 9.83098 8.19998 9.89281 7.99998 9.89281C7.79998 9.89281 7.66665 9.83098 7.53331 9.70732L3.53331 5.99751C3.26665 5.75019 3.26665 5.37921 3.53331 5.13189C3.79998 4.88457 4.19998 4.88457 4.46665 5.13189L7.99998 8.40889L11.5333 5.13189C11.8 4.88457 12.2 4.88457 12.4666 5.13189C12.7333 5.37921 12.7333 5.75019 12.4666 5.99751Z" />
            </mask>
            <g mask="url(#mask0)">
                <rect width="16" height="14.8392" transform='rotate(90)'/>
            </g>
            </svg>
        // </div>

    )
}

export default DropdownIcon