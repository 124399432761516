import React, {useState} from 'react';
import * as Yup from 'yup';
import { Formik, Field, Form } from 'formik';
import Loader from "react-loader-spinner";
import { SingleImageUploader } from '../../components/workout/addWorkout/singleImageUploader';
import { uploadHighlightImage } from './equipments.headless';

const highlightSchema = Yup.object().shape({
  title: Yup.string().trim().required('Name Required'),
  description: Yup.string().trim(),
  image: Yup.string().required('Image Required'),
});

export default function CreateEquipments({ createHighlight, data, fromEdit,onCancel }) {
  const [loading, setLoading] = useState(false);

  const onSubmit = (values) => {
    if (fromEdit) {
      let d = {};
      if (values.title !== data.title) {
        d.title = values.title
      }
      if (values.description !== data.description) {
        d.description = values.description
      }
      if (values.image !== data.image) {
        d.image = {uri: values.image}
      }
      console.log("d is",d);
      createHighlight(d)
    } else {
      let val = {...values};
      val.image = {uri: values.image}
      createHighlight(val)
    }
  }

  const onImageUpload = async (images, isDefaultImageUpload, setFieldValue) => {
    setLoading(true);
    for (let i = 0; i < images.length; i++) {
      let resp = await handleUpload(images[i], isDefaultImageUpload, setFieldValue);
      setFieldValue('image', resp.url ? resp.url : '');
      setLoading(false);
    }
  }

  const handleUpload = async (item, isDefaultImageUpload, setFieldValue) => {
    if (!isDefaultImageUpload) {
      try {
        const resp = await uploadHighlightImage(item.file);
        if (resp && resp.data && resp.data.data) {
          let img = resp.data.data;
          return { 'url': img.location, 'id': item.id }
        }
      }
      catch (err) {
        return {}
      }
    }
  }

  const onRemoveImage = (id, setFieldValue) => {
    setFieldValue('image', '');
  }

  return (
    <div>
      {loading &&
        <div>
          <Loader
            type="TailSpin"
            color="#000"
            height={30}
            width={30}
            className="loader-parent"
          />
        </div>
      }
      <div className='card-body'>
        <Formik
          initialValues={data}
          validationSchema={highlightSchema}
          onSubmit={onSubmit}
          validateOnBlur={false}
          validateOnChange={false}
          onReset={onCancel}
        >
          {({ errors, touched, isSubmitting, values, setFieldValue, handleSubmit }) => (
            <Form className="form">

              <div className="mb-1 mt-1">
                <label
                  className="form-label"
                  htmlFor="first-name-column"
                >
                  Title
                </label>
                <Field
                  type="text"
                  id="first-name-column"
                  className="form-control"
                  placeholder="Enter highlight name"
                  name="title"
                />
                {errors.title && touched.title && (
                  <span class="error text-danger">{errors.title}</span>
                )}
              </div>
              <div className="mb-1 mt-1">
                <label
                  className="form-label"
                  htmlFor="first-name-column"
                >
                  Description
                </label>
                <textarea
                  className="form-control"
                  id="first-name-column"
                  type="text"
                  rows={6}
                  placeholder="Enter description"
                  onChange={(event) => { setFieldValue('description', event.target.value) }}
                  value={values.description}
                ></textarea>
                {errors.description && touched.description && (
                  <span class="error text-danger">{errors.description}</span>
                )}
              </div>
              <div className='col-md-12 col-12 px-0'>
                <SingleImageUploader uploadImage={(images) => { onImageUpload(images, false, setFieldValue) }}
                  removeImage={(id) => { onRemoveImage(id, setFieldValue) }} images={fromEdit ? data.image === '' ? [] : [{ path: data.image }] : []} sizeLimit={204800} />
                {errors.image && touched.image && (
                  <span className="error text-danger">{errors.image}</span>
                )}
              </div>
              <div className="row mt-3">
                <div className="col-12">
                  <button
                    type="submit"
                    className="btn filter-btn mr-3 ml-0 waves-effect waves-float waves-light"
                    disabled={isSubmitting}
                  >
                    Submit
                  </button>
                  <button
                    type="reset"
                    className="btn btn-outline-secondary waves-effect"
                    disabled={isSubmitting}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}