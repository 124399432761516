import { send, setHeader } from "./app/utils/api";

export async function checkLogin() {
    const token = localStorage.getItem('token');
    const menus = localStorage.getItem('menus');
    const user = localStorage.getItem('user');
    return { loggedIn: token ? true : false, token, menus: JSON.parse(menus), user: JSON.parse(user) };
}

export async function loadData() {
    let response = await send("configs", "get", "");
    return response.data
}
export async function loadTimeSlots() {
    const token = localStorage.getItem('token');
    setHeader('Authorization', `Bearer ${token}`);
    let url = `getPitchTimeSlots`;
    let data = await send(url, "get");
    return data;
}
export async function loadCountries() {
    const token = localStorage.getItem('token');
    setHeader('Authorization', `Bearer ${token}`);
    let url = `countryList`;    
    let data = await send(url, "get");
    return data;
}
export async function getSurfaceList(typeVal) {
    const token = localStorage.getItem('token');
    setHeader('Authorization', `Bearer ${token}`);
    let body = {};
    if(typeVal) {
        body = {
            type: typeVal
        }
    }
    let url = `surfaceList`;
    let data = await send(url, "post", body);
    return data;
}
export async function getNewPitchList(typeVal) {
    const token = localStorage.getItem('token');
    setHeader('Authorization', `Bearer ${token}`);
    let body = {};
    if(typeVal) {
        body = {
            type: typeVal
        }
    }
    let url = `getpitchTypes`;
    let data = await send(url, "post", body);
    return data;
}

//module.exports={checkLogin}
//export default checkLogin;