import { send, setHeader } from "../../utils/api";

export async function getHighlightsList(limit, page, search, filter) {
    let body = {
        filter: filter
    }
    const token = localStorage.getItem('token');
    setHeader('Authorization', `Bearer ${token}`);
    let url = `getEquipments?size=${limit}&page=${page}`;
    if (search) {
        url += `&search=${search}`;
    }
    let data = await send(url, "post", body);
    return data;
}

export async function createHighlight(body) {
    try {
        const token = localStorage.getItem('token');
        setHeader('Authorization', `Bearer ${token}`);
        let url = `createEquipment`;
        let data = await send(url, "post", body);
        return data;
    }
    catch (err) {
        throw err
    }
}

export async function editHighlight(id, body) {
    try {
        const token = localStorage.getItem('token');
        setHeader('Authorization', `Bearer ${token}`);
        let url = `editEquipment/${id}`;
        let data = await send(url, "post", body);
        return data;
    }
    catch (err) {
        throw err
    }
}

export async function changeHighlightStatus(id, body) {
    const token = localStorage.getItem('token');
    setHeader('Authorization', `Bearer ${token}`);
    let url = `updateHighlightsStatus/${id}`
    let data = await send(url, "post", body);
    return data;
}

export async function deleteHighlight(id) {
    const token = localStorage.getItem('token');
    setHeader('Authorization', `Bearer ${token}`);
    let url = `deleteEquipment/${id}`
    let data = await send(url, "delete");
    return data;
}

export async function uploadHighlightImage(file) {
    const token = localStorage.getItem('token');
    setHeader('Authorization', `Bearer ${token}`);
    let url = `uploadEquipmentsImage`;
    var formdata = new FormData();
    formdata.append("file", file);
    try {
        let data = await send(url, "post", formdata);
        return data;
    } catch (err) {
        throw err;
    }
};



