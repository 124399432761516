import React from 'react';
import backIcon from '../../assets/img/backicon.png'
const Header = ({ title, value, onClick, onButtonClick, buttonText, showButton }) => {
  return (
    <div role="presentation" className='card p-3 my-2'>
      <div className='row align-items-center'>
        <div className='p-2' onClick={onClick}>
          <img src={backIcon} width={30} height={30} />
        </div>
        <div>
          <h5 className='mb-0'>{value}</h5>
          <p className='mb-0 opacity-4'>{title}</p>
        </div>
        {showButton &&
          <div className="float-right-button" onClick={() => { onButtonClick() }}>
            <span className="btn filter-btn">
              {buttonText}
            </span>
          </div>
        }
      </div>
    </div>
  );
};

export default Header;