import * as React from "react"

function GamesPlayed(props) {
  return (
    <svg
      width={46}
      height={46}
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={23} cy={23} r={23} fill="#7367F0" fillOpacity={0.12} />
      <g>
        <path
          d="M31.485 14.515A11.921 11.921 0 0023 11a11.921 11.921 0 00-8.485 3.515A11.921 11.921 0 0011 23c0 3.205 1.248 6.219 3.515 8.485A11.921 11.921 0 0023 35c3.205 0 6.219-1.248 8.485-3.515A11.921 11.921 0 0035 23c0-3.205-1.248-6.219-3.515-8.485zm.006 11.265l2.02-1.46a10.483 10.483 0 01-1.254 3.837l-.766-2.377zm-.948-1.051l-3.355-1.106-1.165-3.585 2.085-2.85 3.755.006a10.5 10.5 0 011.719 5.338l-3.039 2.197zM23 25.945l-2.801-2.035 1.07-3.293h3.462l1.07 3.293L23 25.945zm-4.187-2.326l-3.36 1.089-3.034-2.206a10.501 10.501 0 011.734-5.333l3.754.004 2.07 2.863-1.164 3.583zm-5.085 4.511a10.486 10.486 0 01-1.243-3.84l2.016 1.466-.773 2.374zm17.026-12.345l-2.49-.004-.768-2.378a10.6 10.6 0 013.258 2.382zm-4.938-3l1.155 3.573-2.086 2.851h-3.768l-2.07-2.861 1.166-3.571c.897-.243 1.832-.37 2.787-.37.965 0 1.91.129 2.816.377zm-7.284.606l-.775 2.375-2.49-.003c.08-.085.16-.17.243-.253.89-.89 1.913-1.603 3.022-2.12zm-3.807 16.223l1.162-3.567 3.36-1.088 3.05 2.215v3.532l-3.04 2.208a10.562 10.562 0 01-3.747-2.424c-.28-.28-.54-.572-.785-.876zm6.257 3.787L23 31.935l2.018 1.466a10.705 10.705 0 01-4.036 0zm5.761-.487l-3.04-2.208v-3.532l3.047-2.213 3.355 1.105 1.15 3.571a10.56 10.56 0 01-4.513 3.277z"
          fill="#7367F0"
        />
      </g>
    </svg>
  )
}

export default GamesPlayed
