import React, { useState, useEffect } from 'react';
import './Owners.scss';
import * as Headless from "./Owners.headless";
import {NavLink, useNavigate,
} from "react-router-dom";
import { Dropdown, Modal} from 'react-bootstrap';
import DatePicker from "react-datepicker";

import Loader from "react-loader-spinner";
import _ from 'lodash';
import Sidebar from "react-sidebar";
import DataTable from 'react-data-table-component';
import Pagination from '../../components/Pagination';
import { customTableStyles } from '../../utils/styles'
import actionIcon from '../../../assets/svg/actionIcon.svg';
import CreateOwner from '../../components/studioManagement/owners/createOwner';
import CustomToast from '../../components/CustomToast';
import ConfirmPopup from '../../components/confirmPopup';
import ConfirmPopupOwner from '../../components/confirmPopupOwner';
import {format} from '../../utils/dateFunctions';
import emailicon from '../../../assets/img/emailicon.png';
import mobileicon from '../../../assets/img/mobileicon.png';
import usericon from '../../../assets/img/owner.png';
export function Owners() {
  const navigate = useNavigate();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [total, setTotal] = useState(0);
  const [owners, setOwners] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [openFilter, setOpenFilter] = useState(false)
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [filters, setFilters] = useState({})
  const [show, setShow] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState({variant:'success', message: 'Manager created successfully!'});
  const [chosenId, setChosenId] = useState('');
  const [onDelOwner, setOnDelOwner] = useState({});
  const [ownerData, setOwnerData] = useState({
    name: '',
    email: '',
    mobile: '',
    location: '',
    ownerName: '',
    ownerEmail: '',
    status: 1
  });
  const [editPressed, setEditPressed] = useState(false);

  const columns = [
    {
      name: <th>#</th>,
      selector: (row, index) => index + (limit * (page - 1)) + 1,
      cell: (row, index) =>
        <td className="d-flex">{index + (limit * (page - 1)) + 1}</td>,
      grow: 0.05,
      width: '60px'
    },
    {
      name: <th>MANAGER NAME</th>,
      selector: (row, index) => row.name.toLowerCase(),
      cell: (row, index) =>
        <td style={{ maxWidth: 150 }}>
          <NavLink exact to={row._id ? `/studios/studioOwners/${row._id}` : '#'} className="active-link">
            {row.name}
          </NavLink>
        </td>,
      sortable: true,
    },
    {
      name: <th>CONTACT DETAILS</th>,
      selector: (row, index) => row.email.toLowerCase(),
      cell: (row, index) =>
        <td style={{ maxWidth: 200 }} className="d-flex">
          <div>
            <div className='mb-1 custom-row'><img src={mobileicon} width={12} height={12} className='mr-1' />{
              row.mobileNumber ? row.mobileNumber : 'NA'}</div>
            {row.email &&
              <div className='custom-row'><img src={emailicon} width={12} height={12} className='mr-1' />{' ' + row.email}</div>
            }
          </div>
        </td>,
      sortable: true,
    },
    // {
    //   name: <th>PHONE NUMBER</th>,
    //   selector: (row, index) => row.mobileNumber,
    //   cell: (row, index) =>
    //     <td>
    //       {`${row.mobileNumber}`}
    //     </td>,
    //   sortable: true,
    // },
    {
      name: <th>LOCATION</th>,
      selector: (row, index) => row.location.toLowerCase(),
      cell: (row, index) =>
        <td style={{ maxWidth: 150 }}>
          {row.location}
        </td>,
      sortable: true,
    },
    {
      name: <th>OWNER DETAILS</th>,
      selector: (row, index) => row.ownerName ? row.ownerName.toLowerCase() : 'NA',
      cell: (row, index) =>
        <td style={{ maxWidth: 200 }} className="d-flex">
          <div>
            <div className='mb-1 custom-row align-items-center'>
            <img src={usericon} width={12} height={12} className='mr-1' />
              {row.ownerName ? row.ownerName : 'NA'}
            </div>
            {row.ownerEmail &&
              <div className='custom-row'>
                <img src={emailicon} width={12} height={12} className='mr-1' />
                {' ' + row.ownerEmail ? row.ownerEmail : 'NA'}
              </div>
            }
          </div>
        </td>,
      sortable: true,

      // name: <th> OWNER NAME</th>,
      // selector: (row, index) => row.ownerName ? row.ownerName.toLowerCase() : 'NA',
      // cell: (row, index) =>
      //   <td style={{ maxWidth: 150 }}>
      //       {row.ownerName ? row.ownerName : 'NA'}
      //   </td>,
      // sortable: true,
    },
    // {
    //   name: <th>OWNER EMAIL</th>,
    //   selector: (row, index) => row.ownerEmail ? row.ownerEmail.toLowerCase() : 'NA',
    //   cell: (row, index) =>
    //     <td style={{ maxWidth: 150 }} className="d-flex">
    //       {row.ownerEmail ? row.ownerEmail : 'NA'}
    //     </td>,
    //   sortable: true,
    // },
    {
      name: <th>STUDIOS</th>,
      selector: (row, index) => row.studioCount ? row.studioCount : '0',
      cell: (row, index) =>
        <td style={{width:30}}>
          <span onClick={()=>{navigateToVenues(row._id, row.name)}}>{row.studioCount ? row.studioCount : '0'}</span>
        </td>,
      sortable: true,
    },
    {
      name: <th>STATUS</th>,
      selector: (row, index) => row.status,
      cell: (row, index) =>
        <td>
          {row.status === 0 ? 'DISABLED' : 'ENABLED'}
        </td>,
      sortable: true,
    },
    {
      name: <th>ACTIONS</th>,
      selector: (row, index) => 'actions',
      cell: (row, index) =>
        <td className='table-action-column'>
          {actionButton(row)}
        </td>,
    },
  ];
  
  const actionButton = (row) => {
  
    return (
      <Dropdown drop="up">
        <Dropdown.Toggle variant="default" id="dropdown-basic" className='actionDropdown'>
          <img alt="" src={actionIcon} width={30} height={30} />
        </Dropdown.Toggle>
        <Dropdown.Menu className='action-menu'>
          <Dropdown.Item href={`/pitchBooking/venueOwners/${row._id}`} onClick={(e) => {}}>View</Dropdown.Item>
          <Dropdown.Item href="#" onClick={(e) => {setChosenId(row._id); onUserUpdate('edit', row) }}>Edit</Dropdown.Item>
          <Dropdown.Item href="#" onClick={(e) => { setChosenId(row._id);
         
             row.studioCount==0?setShow(!show):setShowDelete(true)}}
             >Delete</Dropdown.Item>
          <Dropdown.Item href="#" onClick={() => { onChangeUserStatus(row.status, row._id)}}>{row.status && row.status === 1 ? 'Disable': 'Enable'}</Dropdown.Item>
          <Dropdown.Item href="#" onClick={(e) => { navigateToVenues(row._id, row.name)}}>Venue Management</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    )
  }

  const getOwners = async (l, p, s, filter) => {
    setLoading(true);
    try {
      const response = await Headless.getOwnersList(l, p, s, filter);
      if(response.data && response.data.data) {
        setPages(response.data.data.pages);
        setTotal(response.data.data.total);
        if(response.data.data.data) {
          console.log("Owners data",response.data.data.data)
          setOwners(response.data.data.data);
        }
      }
      setLoading(false);
    } catch (err) {
      setPages(1);
      setTotal(0);
      setOwners([]);
      setLoading(false);
    }
  }

  const navigateToVenues = (ownerId, ownerName) => {
    navigate('../../pitchBooking/registeredVenues', { state: { id: ownerId, name: ownerName } })
  }


  const onSearch = _.debounce(setSearch, 500);

  useEffect(() => {
    getOwners(limit, page, search, filters);
  }, [page]);

  useEffect(() => {
    getOwners(limit, 1, search, filters);
  }, [limit, search, filters])

  const onToggleLimit = (e, value) => {
    e.preventDefault();
    setPage(1)
    setLimit(value);
  };

  const onPressFilter = () => {
    let filter = {}
    if (startDate != '' && endDate != '') {
      filter['startDate'] = format(startDate, 'YYYY-MM-DD');
      filter['endDate'] = format(endDate, 'YYYY-MM-DD')
    }

    if (selectedStatus != '') {
      filter['status'] = selectedStatus;
    }

    setFilters(filter)
    setOpenFilter(false)
  }

  const onPressClear = () => {
    setStartDate('')
    setEndDate('')
    setSelectedStatus('')
    setFilters({});
  }

  const handleRadio = (value) => {
    if (value != selectedStatus) {
      setSelectedStatus(value)
    } else {
      setSelectedStatus('')
    }
  }


  const onCreateOwner = (values) => {
    console.log("create owner values are ",values)
    setShowModal(false);
    Headless.createOwner(values).then(()=>{
      setToastMessage({
        variant: 'success',
        message: 'Manager created successfully!'
      })
      setShowToast(true);
      getOwners(limit, page, search, filters);
    })
    .catch(err => {
      setToastMessage({
        variant: 'danger',
        message: err.response && err.response.data && err.response.data.status ? err.response.data.status : 'Manager creation failed!'
      })
      setShowToast(true);
    }) 
  }

  const onEditOwner = (values) => {
    console.log("edit owner values are",values)
    setShowModal(false);
    Headless.editOwner(chosenId, values).then(()=>{
      setToastMessage({
        variant: 'success',
        message: 'Manager details edited successfully!'
      })
      setShowToast(true);
      getOwners(limit, page, search, filters);
      setChosenId('');
      setEditPressed(false);
    })
    .catch(err => {
      setToastMessage({
        variant: 'danger',
        message: 'Editing Manager failed!'
      })
      setShowToast(true);
      setChosenId('');
      setEditPressed(false);
    }) 
  }

  const onChangeUserStatus = async (currentStatus, id) => {
    let status = currentStatus === 0 ? 1 : 0
    try {
      const response = await Headless.changeUserStatus(id, {status: status});
      setToastMessage({
        variant: 'success',
        message: currentStatus === 0 ? 'Manager enabled successfully!' : 'Manager disabled successfully!'
      })
      setShowToast(true);
      getOwners(limit, page, search, filters)
    } catch (err) {
      setToastMessage({
        variant: 'success',
        message: currentStatus === 0 ? 'Enabling Manager failed!' : 'Disabling Manager failed!'
      })
      setShowToast(true);
    }
  }

  const onDeleteConfirm = async (row) => {
    try {
      
      const response = await Headless.deleteOwner(chosenId);
      setChosenId('');
      setToastMessage({
        variant: 'success',
        message: 'Manager deleted successfully!'
      })
      setShowToast(true);
      getOwners(limit, page, search, filters)
    } catch (err) {
      setToastMessage({
        variant: 'danger',
        message: 'Deleting Manager failed!'
      })
      setShowToast(true);
      setChosenId('');
    }
  }

  const onUserUpdate = async (from, row) => {
    if(from === 'edit') {
      setEditPressed(true);
      setOwnerData({
        name: row.name,
        email: row.email,
        mobile: row.mobileNumber,
        location: row.location,
        ownerName: row.ownerName,
        ownerEmail: row.ownerEmail,
        status: row.status
      })
    } else  {
      setEditPressed(false);
      setOwnerData({
        name: '',
        email: '',
        mobile: '',
        location: '',
        ownerName: '',
        ownerEmail: '',
        status: 1
      })
    }
    setShowModal(!showModal);
  }

  function filter() {
    return (
      <div style={{ width: 260, height: '100%' }}>
        {openFilter &&
          <div
            className="filter-close"
            onClick={() => setOpenFilter(false)}
          />
        }
        <div className="ml-3 slider-container">
          <p style={{ fontSize: 20 }} className="mb-0 mt-3">Filter</p>
          <div style={{ width: 100, height: 3, overflow: 'hidden', backgroundColor: 'gray' }}></div>
          <p className="mb-0 mt-3">Date Range</p>
          <div className='row ml-0'>
            <div style={{ width: '45%', overflow: 'hidden' }}>
              <DatePicker
                selected={startDate != '' ? startDate : new Date().setMonth(new Date().getMonth() - 1)}
                onChange={(date) => setStartDate(date)}
                dateFormat="d-MMM-yyyy"
                className="date-picker"
                maxDate={endDate ? endDate : new Date()}
              />
            </div>
            <div style={{ width: '45%', overflow: 'hidden', marginLeft: 10 }}>
              <DatePicker
                selected={endDate != '' ? endDate : new Date()}
                onChange={(date) => setEndDate(date)}
                dateFormat="d-MMM-yyyy"
                className="date-picker"
                minDate={startDate ? startDate : new Date().setMonth(new Date().getMonth() - 1)}
              />
            </div>
          </div>
          <p className="mb-0 mt-3">Status</p>
          <div className="radiobtn" onClick={(val) => handleRadio(val.target.value)}>
            {[0, 1].map((item) => (
              <span>
                <input
                  type="radio" className='mr-1' value={item} name='status' checked={selectedStatus != '' && selectedStatus == item} />
                {item === 0 ? 'Disabled' : 'Enabled'}
              </span>
            ))}
          </div>
          <div className=" ml-1 row">
            <div
              className="filter-submit mt-3"
              onClick={onPressFilter}
            >
              <p className="ml-3 mr-3 mt-3 mb-3">Filter</p>
            </div>
            <div
              className="filter-clear mt-3"
              onClick={onPressClear}
            >
              <p className="ml-3 mr-3 mt-3 mb-3">Clear</p>
            </div>
          </div>
        </div>
      </div>
    )
  }


  return (
    <>
    <div className="card h-100">
      <Sidebar
        pullRight
        sidebar={filter()}
        open={openFilter}
        styles={{
          sidebar:
          {
            background: "white",
            overflow: "visible",
            zIndex: 20
          },
          overlay:
          {
            backgroundColor: "transparent"
          }
        }}
      >
        <div className="card-body">
          <div className="d-flex align-items-center table-header-menu justify-content-between">
            <div className="d-flex align-items-center" />
              <div className="d-flex align-items-center">
                <div className="d-flex" onClick={() => { onUserUpdate('create')}}>
                  <span className="btn filter-btn">
                    Create Manager
                  </span>
                </div>
            </div>
          </div>
          <div className="d-flex align-items-center table-header-menu justify-content-between">
            <div className="d-flex align-items-center">
              <p className="mr-3">Show</p>
              <p className="mr-3">{limit}</p>
              <Dropdown>
                <Dropdown.Toggle variant="default" id="dropdown-basic">
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="#" onClick={(e) => onToggleLimit(e, 10)}>10</Dropdown.Item>
                  <Dropdown.Item href="#" onClick={(e) => onToggleLimit(e, 25)}>25</Dropdown.Item>
                  <Dropdown.Item href="#" onClick={(e) => onToggleLimit(e, 50)}>50</Dropdown.Item>
                  <Dropdown.Item href="#" onClick={(e) => onToggleLimit(e, 100)}>100</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="d-flex align-items-center">
              <p className="mr-3">Search</p>
              <input className="form-control" placeholder="Search" onChange={(e) => onSearch(e.target.value.trim())} />
              <span className="btn filter-btn" onClick={() => setOpenFilter(true)}>
                Filter
                {Object.keys(filters).length > 0 ?
                  <span>
                    &#9733;
                  </span>
                  : null}
              </span>
            </div>
          </div>
          {!loading && owners.length > 0 ?
            <div className="dataHeader">
              <p className="mr-3">
                Showing {page > 1 ? (page - 1) * limit : 1} to {page * limit < total ? page * limit : total} of {total} entries {Object.keys(filters).length > 0 ? `(filtered)` : ''}
              </p>
            </div>
            : null}
          <div className="table-responsive">
            {loading ? <div className="d-flex justify-content-center">
              <Loader
                type="TailSpin"
                color="#000"
                height={30}
                width={30}
              />
            </div> :
              <DataTable
                columns={columns}
                data={owners}
                customStyles={customTableStyles}
              />
            }
          </div>
        </div>
        {!loading && owners.length > 0 ?
          <div className="d-flex card-footer align-items-center justify-content-between">
            <p className="page-data-count">
              Showing {page > 1 ? (page - 1) * limit : 1} to {page * limit < total ? page * limit : total} of {total} entries
            </p>
            <div>
              <Pagination pages={pages} current={page} onChange={setPage} />
            </div>
          </div>
          : null}
      </Sidebar>
      <ConfirmPopup show={show} message="Are you sure to delete this venue Manager?" submitButtonText='Confirm' cancelButtonText='Close' handleConfirm={() => { setShow(false); onDeleteConfirm()}} handleDismiss={() => { setShow(false) }}/>
      <ConfirmPopupOwner show={showDelete} message="Sorry! Unable to delete this owner because studio/s linked with this. Remove the studio first to delete this account"  cancelButtonText='Close'  handleDismiss={() => { setShowDelete(false) }}/>
      <Modal show={showModal} onHide={() => { setShowModal(false); if(editPressed){
        setEditPressed(false);
        setChosenId('');
      } }} centered>
        <Modal.Header closeButton>
          <Modal.Title>{editPressed ? 'Edit Manager' : 'Create Manager'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CreateOwner createUser={(values)=>{editPressed ? onEditOwner(values) : onCreateOwner(values)}} onCancel={() => { setShowModal(false); setChosenId('');
      setEditPressed(false); }} data={ownerData} fromEdit={editPressed ? true : false}/>
        </Modal.Body>
      </Modal>
      <CustomToast variant={toastMessage.variant} message={toastMessage.message} show={showToast} onClose={() => { setShowToast(false) }} />
    </div>
    </>
  );

}