import React from 'react';
import './components.scss'; // Import your CSS styles

function CloseButton({ text, onClick ,item}) {
  return (
    <button type='button' className="close-button inline-button" onClick={()=>onClick(item)}>
      {text}
      <span className="close-icon">×</span>
    </button>
  );
}

export default CloseButton;