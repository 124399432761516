import { send, setHeader } from "../../utils/api";

export async function getOwnersList(limit, page, search, filter) {
    let body = {
        filter: filter
    }
    const token = localStorage.getItem('token');
    setHeader('Authorization', `Bearer ${token}`);
    let url = `getOwners?size=${limit}&page=${page}`;
    if (search) {
        url += `&search=${search}`;
    }
    let data = await send(url, "post", body);
    return data;
}

export async function createOwner(body) {
    try {
        console.log("body is",body);
        const token = localStorage.getItem('token');
        setHeader('Authorization', `Bearer ${token}`);
        let url = `createOwner`;
        let data = await send(url, "post", body);
        return data;
    }
    catch (err) {
        throw err
    }
}

export async function editOwner(id, body) {
    try {
        const token = localStorage.getItem('token');
        setHeader('Authorization', `Bearer ${token}`);
        let url = `updateOwner/${id}`;
        let data = await send(url, "post", body);
        return data;
    }
    catch (err) {
        throw err
    }
}

export async function getOwnerDetails(id) {
    const token = localStorage.getItem('token');
    setHeader('Authorization', `Bearer ${token}`);
    let url = `getOwner/${id}`;
    let data= await send(url,"get");
    return data;
}

export async function changeUserStatus(id, body) {
    const token = localStorage.getItem('token');
    setHeader('Authorization', `Bearer ${token}`);
    let url = `updateOwnerStatus/${id}`
    let data= await send(url,"post",body);
    return data;
}

export async function deleteOwner(id) {
    const token = localStorage.getItem('token');
    setHeader('Authorization', `Bearer ${token}`);
    let url = `deleteOwner/${id}`
    let data= await send(url,"delete");
    return data;
}



