import React from 'react';
import PhoneInput from 'react-phone-number-input';
import PropTypes from 'prop-types';
import 'react-phone-number-input/style.css';
// import './createOwner.scss'
const PhoneInputField = ({onChange, field}) => {
    
  return (
    <div className='form-control'>
      <PhoneInput
        placeholder="Enter phone number"
        name='dialCode'
        value={field.value}
        onChange={(val)=>onChange(val)}
        withCountryCallingCode={false}
        international
      />
    </div>
  );
};

PhoneInputField.propTypes = {
  field: PropTypes.any.isRequired,
  onChange: PropTypes.func,
};

export default PhoneInputField;