import React, { useState, useEffect } from 'react';
import './equipments.scss';
import * as Headless from "./equipments.headless";
import {
  useNavigate,
} from "react-router-dom";
import { Dropdown, Modal, Button } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

import Loader from "react-loader-spinner";
import _ from 'lodash';
import Sidebar from "react-sidebar";
import DataTable from 'react-data-table-component';
import Pagination from '../../components/Pagination';
import { customTableStyles } from '../../utils/styles'
import actionIcon from '../../../assets/svg/actionIcon.svg';
import CustomToast from '../../components/CustomToast';
import ConfirmPopup from '../../components/confirmPopup';
import { format } from '../../utils/dateFunctions';
import CreateEquipments from './createEquipments';
export function Equipments() {
  const navigate = useNavigate();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [openFilter, setOpenFilter] = useState(false)
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState({ variant: 'success', message: '' });
  const [chosenId, setChosenId] = useState('');
  const [highlightsData, setHighlightsData] = useState({
    title: '',
    description: '',
    image: '',
  });
  const [editPressed, setEditPressed] = useState(false);

  const columns = [
    {
      name: <th>#</th>,
      selector: (row, index) => index + (limit * (page - 1)) + 1,
      cell: (row, index) =>
        <td className="d-flex">{index + (limit * (page - 1)) + 1}</td>,
      grow: 0.05,
      width: '60px'
    },
    {
      name: <th>NAME</th>,
      selector: (row, index) => row.title.toLowerCase(),
      cell: (row, index) =>
        <td style={{ maxWidth: 150 }}>
          {row.title}
        </td>,
      sortable: true,
    },
    {
      name: <th>DESCRIPTION</th>,
      selector: (row, index) => row.description.toLowerCase(),
      cell: (row, index) =>
        <td style={{ maxWidth: 200 }}>
          {/* {row.description} */}
          {row.description ?
            <OverlayTrigger trigger="hover" placement="bottom" overlay={<Popover id="popover-basic">
              <Popover.Content>
                {row.description}
              </Popover.Content>
            </Popover>}>
              <Button className="popOverButton">{row.description}</Button>
            </OverlayTrigger>
            : 'NA'
          }

        </td>,
      sortable: true,
    },
    {
      name: <th>IMAGE</th>,
      cell: (row, index) =>
        <td style={{ maxWidth: 150 }}>
          {row.image && row.image.uri ?
            <img src={row.image.uri} width={30} height={30} />
            : 'NA'
          }
        </td>,
      sortable: false,
    },
    {
      name: <th>ACTIONS</th>,
      selector: (row, index) => 'actions',
      cell: (row, index) =>
        <td className='table-action-column'>
          {actionButton(row)}
        </td>,
    },
  ]

  const actionButton = (row) => {
    return (
      <Dropdown drop="up">
        <Dropdown.Toggle variant="default" id="dropdown-basic" className='actionDropdown'>
          <img alt="" src={actionIcon} width={30} height={30} />
        </Dropdown.Toggle>
        <Dropdown.Menu className='custom-dropdown'>
          <Dropdown.Item href="#" onClick={(e) => { setChosenId(row._id); onButtonPress('edit', row) }}>Edit</Dropdown.Item>
          <Dropdown.Item href="#" onClick={(e) => { setChosenId(row._id); setShow(!show) }}>Delete</Dropdown.Item>
         </Dropdown.Menu>
      </Dropdown>
    )
  }


  const getHighlights = async (l, p, s, filter) => {
    setLoading(true);
    try {
      const response = await Headless.getHighlightsList(l, p, s, filter);
   
      if (response && response.data && response.data.pages) {
        setPages(response.data.pages);
      }
      if (response && response.data && response.data.total) {
        setTotal(response.data.total);
      }
      if (response && response.data && response.data.data) {
        setData(response.data.data);
      }
      setLoading(false);
    } catch (err) {
      setPages(1);
      setTotal(0);
      setData([]);
      setLoading(false);
    }
  }


  const onSearch = _.debounce(setSearch, 500);

  useEffect(() => {
    getHighlights(limit, page, search, filters);
  }, [page]);

  useEffect(() => {
    getHighlights(limit, 1, search, filters);
  }, [limit, search, filters])

  const onToggleLimit = (e, value) => {
    e.preventDefault();
    setPage(1)
    setLimit(value);
  };

  const onPressFilter = () => {
    let filter = {}
    setFilters(filter)
    setOpenFilter(false)
  }

  const onPressClear = () => {
    setFilters({});
  }

  const handleRadio = (value) => {
  }


  const onCreateHighlight = (values) => {
    setShowModal(false);
    Headless.createHighlight(values).then(() => {
      setToastMessage({
        variant: 'success',
        message: 'Equipments added successfully!'
      })
      setShowToast(true);
      getHighlights(limit, page, search, filters);
    })
      .catch(err => {
        setToastMessage({
          variant: 'danger',
          message: err.response && err.response.data && err.response.data.status ? err.response.data.status : 'Adding new highlight failed!'
        })
        setShowToast(true);
      })
  }

  const onEditHighlight = (values) => {
    setShowModal(false);
    Headless.editHighlight(chosenId, values).then(() => {
      setToastMessage({
        variant: 'success',
        message: 'Equipments details edited successfully!'
      })
      setShowToast(true);
      getHighlights(limit, page, search, filters);
      setChosenId('');
      setEditPressed(false);
    })
      .catch(err => {
        setToastMessage({
          variant: 'danger',
          message: 'Editing Equipment failed!'
        })
        setShowToast(true);
        setChosenId('');
        setEditPressed(false);
      })
  }


  const onDeleteConfirm = async () => {
    try {
      const response = await Headless.deleteHighlight(chosenId);
      setChosenId('');
      setToastMessage({
        variant: 'success',
        message: 'Equipments deleted successfully!'
      })
      setShowToast(true);
      getHighlights(limit, page, search, filters)
    } catch (err) {
      setToastMessage({
        variant: 'danger',
        message: 'Deleting Equipments failed!'
      })
      setShowToast(true);
      setChosenId('');
    }
  }

  const onButtonPress = async (from, row) => {
    if (from === 'edit') {
      setEditPressed(true);
      setHighlightsData({
        title: row.title,
        description: row.description,
        image: row.image && row.image.uri ? row.image.uri : '',
      })
    } else {
      setEditPressed(false);
      setHighlightsData({
        title: '',
        description: '',
        image: '',
      })
    }
    setShowModal(!showModal);
  }

  // function filter() {
  //   return (
  // <div style={{ width: 260, height: '100%' }}>
  //   {openFilter &&
  //     <div
  //       className="filter-close"
  //       onClick={() => setOpenFilter(false)}
  //     />
  //   }
  //   <div className="ml-3 slider-container">
  //     <p style={{ fontSize: 20 }} className="mb-0 mt-3">Filter</p>
  //     <div style={{ width: 100, height: 3, overflow: 'hidden', backgroundColor: 'gray' }}></div>
  //     <p className="mb-0 mt-3">Date Range</p>
  //     <div className='row ml-0'>
  //       <div style={{ width: '45%', overflow: 'hidden' }}>
  //         <DatePicker
  //           selected={startDate != '' ? startDate : new Date().setMonth(new Date().getMonth() - 1)}
  //           onChange={(date) => setStartDate(date)}
  //           dateFormat="d-MMM-yyyy"
  //           className="date-picker"
  //           maxDate={endDate ? endDate : new Date()}
  //         />
  //       </div>
  //       <div style={{ width: '45%', overflow: 'hidden', marginLeft: 10 }}>
  //         <DatePicker
  //           selected={endDate != '' ? endDate : new Date()}
  //           onChange={(date) => setEndDate(date)}
  //           dateFormat="d-MMM-yyyy"
  //           className="date-picker"
  //           minDate={startDate ? startDate : new Date().setMonth(new Date().getMonth() - 1)}
  //         />
  //       </div>
  //     </div>
  //     <p className="mb-0 mt-3">Status</p>
  //     <div className="radiobtn" onClick={(val) => handleRadio(val.target.value)}>
  //       {[0, 1].map((item) => (
  //         <span>
  //           <input
  //             type="radio" className='mr-1' value={item} name='status' checked={selectedStatus != '' && selectedStatus == item} />
  //           {item === 0 ? 'Disabled' : 'Enabled'}
  //         </span>
  //       ))}
  //     </div>
  //     <div className=" ml-1 row">
  //       <div
  //         className="filter-submit mt-3"
  //         onClick={onPressFilter}
  //       >
  //         <p className="ml-3 mr-3 mt-3 mb-3">Filter</p>
  //       </div>
  //       <div
  //         className="filter-clear mt-3"
  //         onClick={onPressClear}
  //       >
  //         <p className="ml-3 mr-3 mt-3 mb-3">Clear</p>
  //       </div>
  //     </div>
  //   </div>
  // </div>
  //   )
  // }


  return (
    <>
      <div className="card h-100">
        <Sidebar
          pullRight
          // sidebar={filter()}
          open={openFilter}
          styles={{
            sidebar:
            {
              background: "white",
              overflow: "visible",
              zIndex: 20
            },
            overlay:
            {
              backgroundColor: "transparent"
            }
          }}
        >
          <div className="card-body">
            <div className="d-flex align-items-center table-header-menu justify-content-between">
              <div className="d-flex align-items-center" />
              <div className="d-flex align-items-center">
                <div className="d-flex" onClick={() => { onButtonPress('create') }}>
                  <span className="btn filter-btn">
                    Add Equipment
                  </span>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center table-header-menu justify-content-between">
              <div className="d-flex align-items-center">
                <p className="mr-3">Show</p>
                <p className="mr-3">{limit}</p>
                <Dropdown>
                  <Dropdown.Toggle variant="default" id="dropdown-basic">
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item href="#" onClick={(e) => onToggleLimit(e, 10)}>10</Dropdown.Item>
                    <Dropdown.Item href="#" onClick={(e) => onToggleLimit(e, 25)}>25</Dropdown.Item>
                    <Dropdown.Item href="#" onClick={(e) => onToggleLimit(e, 50)}>50</Dropdown.Item>
                    <Dropdown.Item href="#" onClick={(e) => onToggleLimit(e, 100)}>100</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="d-flex align-items-center">
                <p className="mr-3">Search</p>
                <input className="form-control" placeholder="Search" onChange={(e) => onSearch(e.target.value.trim())} />
                {/* <span className="btn filter-btn" onClick={() => setOpenFilter(true)}>
                  Filter
                  {Object.keys(filters).length > 0 ?
                    <span>
                      &#9733;
                    </span>
                    : null}
                </span> */}
              </div>
            </div>
            {!loading && data.length > 0 ?
              <div className="dataHeader">
                <p className="mr-3">
                  Showing {page > 1 ? (page - 1) * limit : 1} to {page * limit < total ? page * limit : total} of {total} entries {Object.keys(filters).length > 0 ? `(filtered)` : ''}
                </p>
              </div>
              : null}
            <div className="table-responsive">
              {loading ? <div className="d-flex justify-content-center">
                <Loader
                  type="TailSpin"
                  color="#000"
                  height={30}
                  width={30}
                />
              </div> :
                <DataTable
                  columns={columns}
                  data={data}
                  customStyles={customTableStyles}
                />
              }
            </div>
          </div>
          {!loading && data.length > 0 ?
            <div className="d-flex card-footer align-items-center justify-content-between">
              <p className="page-data-count">
                Showing {page > 1 ? (page - 1) * limit : 1} to {page * limit < total ? page * limit : total} of {total} entries
              </p>
              <div>
                <Pagination pages={pages} current={page} onChange={setPage} />
              </div>
            </div>
            : null}
        </Sidebar>
        <ConfirmPopup show={show} message="Are you sure to delete this Equipments?" submitButtonText='Confirm' cancelButtonText='Close' handleConfirm={() => { setShow(false); onDeleteConfirm() }} handleDismiss={() => { setShow(false) }} />
        <Modal show={showModal} onHide={() => {
          setShowModal(false); if (editPressed) {
            setEditPressed(false);
            setChosenId('');
          }
        }} centered>
          <Modal.Header closeButton>
            <Modal.Title>{editPressed ? 'Edit Equipments' : 'Add Equipments'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <CreateEquipments onCancel={() => {
              setShowModal(false); setChosenId('');
              setEditPressed(false);
            }} createHighlight={(values) => { editPressed ? onEditHighlight(values) : onCreateHighlight(values) }} data={highlightsData} fromEdit={editPressed ? true : false} />
          </Modal.Body>
        </Modal>
        <CustomToast variant={toastMessage.variant} message={toastMessage.message} show={showToast} onClose={() => { setShowToast(false) }} />
      </div>
    </>
  );

}