import * as React from "react"

function ChevronLeft(props) {
  return (
    <svg
      width={17}
      height={16}
      viewBox="0 0 17 16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.516 11.533a.645.645 0 010 .934.606.606 0 01-.466.2.605.605 0 01-.467-.2l-4-4a.644.644 0 010-.934l4-4a.644.644 0 01.933 0 .645.645 0 010 .934L6.983 8l3.533 3.533z"
        fill="#000"
      />
      <mask
        id="prefix__a"
        maskUnits="userSpaceOnUse"
        x={5}
        y={3}
        width={6}
        height={10}
      >
        <path
          d="M10.516 11.533a.645.645 0 010 .934.606.606 0 01-.466.2.605.605 0 01-.467-.2l-4-4a.644.644 0 010-.934l4-4a.644.644 0 01.933 0 .645.645 0 010 .934L6.983 8l3.533 3.533z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#prefix__a)">
        <path d="M.05 0h16v16h-16z" />
      </g>
    </svg>
  )
}

export default ChevronLeft
